/* eslint-disable*/
import React, { Component } from "react";
import * as SpriteSpin from "spritespin";
import mrc from "../functions/mrc";
import config from "../functions/config";
import "./project-display.css";
import Footer from "../header/footer";
import QRpopup from "../popups/QR-popup";
import btnimg from "../images/button.png";
import Loader from "../loader";
import DeletProjectErrorPopup from "../popups/delete-project-error";
import Headertryon from "../header/headertryon";
import homeimg from "../images/home.png";
import { getLinkExtension } from "../functions/upload-services";
import { errorLog, userLog } from "../functions/useLogs";
import CreditWarning from "../CreditWarning";
import Verification from "./verification";
import InquiryForm from "./InquiryForm";
import axios from 'axios'
function loaderStop() {
  document.getElementById("projectdisplay-loader").style.display = "none";
}

let uid = "";
let finalProjectId = "";
let projectName = "";

export default class ProjectDisplay extends Component {
  state = {
    projectID: "",
    isProjectAvailable: true,
    isShowStore: false,
    overlay: false,
    projectName: "",
    isEnquriForm: false,
    adminEmail: "",
    isPasswordProtected: false,
    password: "",
  };

  postSession = (uid) => {
    axios.post("https://ptpvr.com/usage/session", {
      uid: uid,
      product: "noor",
    }).then((response) => {
    }).catch(err => {
      errorLog("PostSession", err, "");
    })
  }

  calculateUsage = (usage) => {
    axios.post("https://ptpvr.com/usage", {
      uid: uid,
      usage: usage,
      product: 'noor'
    }).then((result) => {
    }).catch(err => {
      errorLog("calculateUsage", err, "");
    });
  }

  componentDidMount() {
    let currentProjectId = window.location.href;
    currentProjectId = currentProjectId.substring(
      currentProjectId.lastIndexOf("/") + 1
    );
    let userid = localStorage.getItem("uid");
    this.getProjectDetails(currentProjectId);

    const zoomElement = document.querySelector(".spritespinDiv");
    let zoom = 1;
    const ZOOM_SPEED = 0.1;
    document.addEventListener("wheel", function (e) {
      if (e.deltaY > 0) {
        if (zoom > 0.3) {
        zoomElement.style.transform = `scale(${zoom -= ZOOM_SPEED})`;
        }
      } else {
        zoomElement.style.transform = `scale(${zoom += ZOOM_SPEED})`;
      }
    });
  }

  /**
   * this function is use for get project details
   *
   * @param {project id} projectId
   */
  getProjectDetails = (projectId) => {
    this.setState({ projectID: projectId });
    return mrc.get("projects/" + projectId, {
      headers: {
        "x-access-token": "null",
      },
      baseURL: "https://ptpvr.com",
    }).then((res) => {
      let project = res.data;

      uid = project.uid;
      finalProjectId = project._id;
      projectName = project.projectname;


      axios.post('https://ptpvr.com/paidview/isviewvalid', {
        product: 'Noor',
        uid: uid
      }).then(res => {
        if (res.data.isAllowed) {
          axios.put(`https://ptpvr.com/projects/updateview/${project._id}`, {
            views: project.views + 1,
          }).then((response) => {
            if (res.data.isPayAsYouGo) {
              this.postSession(uid);
            }
          })

          let cal_usage = 0;

          project.scenes[0].images360view.forEach((image360) => {

            cal_usage += image360.size;

          });
          let final_usage = cal_usage / 1024
          this.calculateUsage(final_usage);



          mrc.get(`https://ptpvr.com/projects/${project.uid}/Noor`).then((res) => {
            // if (res.data.isEnabled == true) {
            // if (
            //   res.data.config &&
            //   res.data.config.indexOf("Ecommerce") != -1
            // ) {
            // this.setState({
            //   isShowStore: true,
            // });
            // document.getElementById("visitOurStoreLink").href =
            //   "https://shop.melzo.com/product/" + project.uid;
            //   }
            // }
          }).catch((err) => {
            errorLog(
              uid,
              finalProjectId,
              projectName,
              "Noor Service Call ",
              err,
              ""
            );
          });

          if (project.isPasswordProtected) {
            this.setState({ isPasswordProtected: true, password: project.password })
          }
          if (project.isEnquiryEnabled) {
            this.setState({ isEnquriForm: true, adminEmail: project.enquiryMail })
          }

          console.log('project.scenes :: ', project.scenes[0]);

          let finalArray = []
          project.scenes[0].images360view[0].images.map((obj) => {
            finalArray.push(config.images + obj);
          })

          this.spriteSpinFunction(finalArray);
          userLog("360 View", uid, finalProjectId, projectName);
        } else {
          window.location.href = "/warning"
        }
      })
    }).catch((err) => {
      errorLog(
        uid,
        finalProjectId,
        projectName,
        "getProjectDetails",
        err,
        ""
      );
      this.setState({ isProjectAvailable: false });
    });
  };

  /**
   * this function is use for image spin in 360
   *
   * @param {link} myLink
   */
  spriteSpinFunction = (myLink, SpriteCounterFlag) => {
    // var res = myLink.substring(0, myLink.lastIndexOf("-") + 1);
    $(function () {
      $(".spritespin").spritespin({
        // source: SpriteSpin.sourceArray(
        //   res + "{frame}." + getLinkExtension(myLink),
        //   {
        //     frame: [0, SpriteCounterFlag - 1],
        //     digits: 3,
        //   }
        // ),
        source: myLink,

        // the number of lanes (vertical angles)

        // the number of frames per lane (per vertical angle)
        // frames: SpriteCounterFlag,
        // interaction sensitivity (and direction) modifier for horizontal movement
        sense: 1,
        // interaction sensitivity (and direction) modifier for vertical movement
        // animate: false,
        onComplete: loaderStop,

        // the initial lane number

        // the initial frame number (within the lane)
        // frame: 0,
        // disable autostart of the animation
        animate: false,

        // plugins: ["progress", "360", "drag"],
      });
    });
  };

  //Close Password protection PopUP
  closePassword = () => {
    this.setState({ isPasswordProtected: false })
  }

  //Close Enquire protection PopUP
  closeEnquire = () => {
    this.setState({ isEnquriForm: false })
  }

  render() {
    return (
      <div>
        {this.state.isPasswordProtected && <Verification password={this.state.password} successViewerPwdEntry={() => this.closePassword()} />}
        {this.state.isEnquriForm && <InquiryForm enquiryMail={this.state.adminEmail} projectname={this.state.projectName} successEnquiryFormEntry={() => this.closeEnquire()} />}
        {this.state.overlay && <CreditWarning />}
        <div id="projectdisplay-loader" style={{ display: "block" }}>
          <Loader />
        </div>
        {/* <Headertryon isShowStore={this.state.isShowStore} /> */}

        <div className="spritespinDiv">
          <div id="spritespin" className="spritespin"></div>
        </div>
        <button
          className="Btn compdisplay qrbottonposition"
          onClick={this.openQr}
        >
          <img src={btnimg} className="btnicon" alt="" />
          QR Code
        </button>
        <QRpopup />
        {/* <img
          src={homeimg}
          onClick={this.handleHome}
          className="iconwidth homebtnposition"
          data-tooltip="tooltip"
          data-placement="right"
          title="Go to Homescreen "
        /> */}

        <Footer />
        {this.state.isProjectAvailable === false && (
          <DeletProjectErrorPopup cancel={this.handleCancel} />
        )}
      </div>
    );
  }
  handleHome = () => {
    window.location.href = "/";
  };
  handleCancel = () => {
    this.setState({ isProjectAvailable: true });
  };

  /**
   * this function is use for open qr popup
   */
  openQr = () => {
    document.getElementById("scannerPopupOverlay").style.display = "block";
    document.getElementById("scannerPopup").style.display = "block";
    document.getElementById("scannerPopup").classList.add("toptobottom");
    document.getElementById("scannerPopup").classList.remove("bottomtotop");
  };
}