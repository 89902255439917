import React, { Component } from 'react'
import btnimg from "../images/button.png";

export default class DeletProjectErrorPopup extends Component {

    handleCancle = () => {
        this.props.cancel();
        window.location.href = '/home'
    }
    render() {
        return (
            <div>
                <div id="delete-project-error-popup" style={{ display: 'block' }}>
                    <div className="overlay" onClick={this.handleCancle} />
                    <ul className="popupboxdelete toptobottom">
                        <li>
                            <div className="flex">
                                <h4 className="popuptitle">
                                    Project not found
                        </h4>
                                <span className="closepopup" onClick={this.handleCancle} >&times;</span>
                            </div>
                        </li>
                        <li className="deletetext">
                            <p>No project found for this link. Make sure link is correct and the project exists.</p>
                        </li>
                        <li className="margintopbottom">
                            <div className="flexbtn">
                                <button
                                    className="Btn"
                                    onClick={this.handleCancle}

                                >
                                    <img src={btnimg} className="btnicon" alt="" />
                                    Ok
                            </button>

                                {/* <button type="submit" className="popupbtn marginrightcancel" onClick={this.handleCancle} > Ok</button> */}
                                {/* <button type="submit" className="popupbtn"  ><div id="yes" style={{ display: 'block' }}  onClick={this.handleYes} >Yes</div>
                            </button> */}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
