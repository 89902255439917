import React, { Component } from 'react';
import btnimg from '../images/button.png';

export default class EmbedPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.projectData,
        }
    }

    handleCancle = () => {
        this.props.closePopup();
    }

    /**
     * this function is use for copy
     */
    CopyLinkDisplay = () => {
        var copyText = document.getElementById('embed-description')
        if (copyText) {
            copyText.contentEditable = "true";

            var range, selection;
            var iOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

            if (iOS) {
                range = document.createRange();
                range.selectNodeContents(copyText);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                copyText.setSelectionRange(0, 999999);
            } else {
                copyText.select();
            }
            // copyText.select();
            // copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
            this.props.closePopup();
        }
    }


    handleYes = () => {
        // this.props.projectEmbed(this.props.embedProjectId)
        this.CopyLinkDisplay();
        // this.props.closePopup();
    }

    render() {
        return (
            <div>
                <div id="delete-popup">
                    <div className="overlay" onClick={this.handleCancle} />
                    <ul className="popupboxembed toptobottom">
                        <li>
                            <div className="flex">
                                <h4 className="popuptitle" >
                                    Add to webpage
                                </h4>
                                <span className="closepopup" onClick={this.handleCancle} >&times;</span>
                            </div>
                            <input type="text" id="embed-description" style={{ opacity: '0' }} value={`<iframe src="${window.location.href.replace('/home', '/360/' + this.props.embedProjectId)}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`} />
                        </li>
                        <li>
                            <div className="embedlinktext">{`<iframe src="${window.location.href.replace('/home', '/' + this.props.type + '/' + this.props.embedProjectId)}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}</div>

                        </li>
                        <li >
                            <div className="flexbtn">
                                <button className="Btn marginrightcancel" onClick={this.handleYes} id="yes">
                                    <img src={btnimg} className="btnicon" alt="" />
                                    Copy
                                </button>
                                {/* <button className="sparklebtn " onClick={this.handleYes} id="yes">
                                    <span>
                                        <img src={copyimg} className="btnicon" alt="" />
                                        Copy
                                    </span>
                                </button> */}

                            </div>
                        </li>
                    </ul>
                </div>
            </div >
        )
    }
}
