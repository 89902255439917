/* eslint-disable */
import './verification.css'
import React, { Component } from 'react'
import CryptoJS from 'crypto-js'
import 'bootstrap/dist/css/bootstrap.min.css';

export default class Verification extends Component {

    constructor(props) {
        super(props)

        this.state = {
            password: ''
        }
    }

    componentDidMount() {
        let body = document.querySelector('body');
        body.style.backgroundColor = 'white';
        body.style.color = 'black';
    }

    submitPassword = (e) => {
        e.preventDefault();

        const decrypted = CryptoJS.AES.decrypt(this.props.password.toString().replace(/[_]/g, '/'), "u7x!A%D*G-KaNdRgUkXp2s5v8y/B?E(H");
        let decryptedPwd = decrypted.toString(CryptoJS.enc.Utf8);
        if (this.state.password == decryptedPwd) {
            this.props.successViewerPwdEntry()
        } else {
            alert('Please Enter Correct Password')
        }
    }

    handleChange = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    render() {
        return (
            <div id="passwordProtected" className="wrapper1">
                <div className="container">
                    <div className="popupform1">
                        <h1 className="featurestitle1 ">
                            Viewer Secure Password
                                  </h1>
                        <form className="form">
                            <div
                                className="row"
                                style={{ alignItems: "center", marginBottom: "20px" }}
                            >
                                <div className="col-12 col-md-4 col-lg-4">
                                    <span className="form-field">Password</span>
                                </div>
                                <div className="col-12 col-md-8 col-lg-8">
                                    <input
                                        type="password"
                                        onChange={this.handleChange}
                                        className="form-input"
                                    ></input>
                                </div>
                            </div>
                            <button type="submit" className="submit-btnform1"
                                id="login-button" onClick={this.submitPassword}><span className="btntext">Login</span></button>
                        </form>
                    </div>

                </div>

            </div>
        )
    }
}
