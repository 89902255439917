import { guid, getUserToken } from "./aws-services";
import mrc from "./mrc";
import firebaseApp from "./firebase";
import firebaseStorage from "./firebase-storage";
import config from "./config";
import { createGltfModel } from "./model-upload-service";
import { userid } from "../project-list-page/project-list-page";
let modelProjectId = "";

/**
 * this function is use for upload responce in S3
 *
 * @param {resource} resource
 */
function uploadResToS3(resource) {
  //     const deferred = $q.defer();
  const widgetsFirebaseListener = ["widgetvideo", "video360", "image360"];
  // MONKEY PATCH TILL SERVER HAS METADATA FOR ASSETS STORED IN PROJECTS
  //
  //
  // const filename = guid() +'-'+resource.fileSuffix+ '.' + getFileExtension(resource.file);
  // let currentProjectId = window.location.href;
  // currentProjectId = currentProjectId.substring(currentProjectId.lastIndexOf('/') + 1)
  // console.warn('current project id is ::',currentProjectId)
  const urlData = {
    projectid: localStorage.getItem("projectId"),
    restype: "image",
    widgettype: resource.widgetType,
    filename: resource.filename,
  };

  return new Promise((resolve, reject) => {
    getUserToken().then((token) => {
      getAWSSignedUrl(
        {
          token: token,
        },
        urlData,
      ).then((response) => {
        // console.warn('response data url is ::', response.data.url);
        // console.warn('response data url is ::', resource);
        mrc
          .put(response.data.url, resource.file, {
            headers: {
              "Content-Type":
                resource.file.type !== ""
                  ? resource.file.type
                  : "application/octet-stream",
              "Cache-Control": "public, max-age=172800",
            },
          })
          // console.warn('response.data.url',response.data.url)
          .then((data) => {
            // console.warn('Put success', data);
            let url = data.config.url.substr(0, data.config.url.indexOf("?"));
            resource.file.result = url;
            url = url.replace(
              "https://pptvr-images.s3.ap-south-1.amazonaws.com/",
              "",
            );
            // // var normalisedurl = getNormalisedUrl(resource, url);

            // MONKEY PATCHING UNTIL FIREBASE INTEGRATED FOR 3D MODEL UPLOAD
            if (widgetsFirebaseListener.indexOf(resource.widgetType) === -1) {
              // addWidget(url, resource.widgetType);
              resolve(resource);
            } else {
              const normalisedurl = getNormalisedUrl(resource, url);
              const ref = firebaseApp.database().ref(normalisedurl);
              ref.set({
                index: "CREATING",
              });
              ref.on("value", (snapshot) => {
                if (
                  snapshot.val() === "CREATED" ||
                  snapshot.val().index === "CREATED"
                ) {
                  // addWidget(url, resource.widgetType);
                  resolve(resource);
                  ref.off();
                }
              });
            }
            localStorage.removeItem("projectId");
          })
          .catch((err) => {
            console.error("Error ", err);
            reject(err);
          });
      });
    });
  });
  // })
  // .catch((error) => {
  //     deferred.reject(error);
  // });
  // return deferred.promise;
}

/**
 * this function is use for get aws signed url
 *
 * @param {project details} obj
 * @param {url data} urlData
 */
function getAWSSignedUrl(obj, urlData) {
  return new Promise((resolve, reject) => {
    // console.warn('get aws signed url  obj is ::', obj)
    // console.warn('get aws signed url  urldata is ::', urlData)
    mrc

      .post(config.serverUrl + `upload/getSignedUrl`, urlData, {
        headers: {
          "x-access-token": obj.token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * this function is use for upload files
 *
 * @param {files for upload} files
 * @param {widget type} widgetType
 */
export function uploadFiles(files, widgetType) {
  console.warn("image ::", files);
  console.warn("type ::", widgetType);
  const thiswidgetType = widgetType;
  if (!files || !widgetType) {
    Promise.reject();
  }
  const resources = [];
  const currentGuid = guid();
  for (let index = 0; index < files.length; index += 1) {
    resources.push({
      file: files[index],
      widgetType: thiswidgetType,
      filename:
        currentGuid +
        "-" +
        pad(index, 3) +
        "." +
        getFileExtension(files[index]),
    });
  }
  let uid = localStorage.getItem("uid");
  let currentProjectId = window.location.href;
  currentProjectId = currentProjectId.substring(
    currentProjectId.lastIndexOf("/") + 1,
  );
  localStorage.setItem("projectId", currentProjectId);
  // console.warn('uid is :: ',uid);
  // console.warn('curent project id is ::',currentProjectId);
  let filePath =
    "image/private/" + uid + "/" + currentProjectId + "/widgetimage/";
  filePath =
    filePath + currentGuid + "-" + pad(0, 3) + "." + getFileExtension(files[0]);
  let defaultObj = {
    position: "0 0 10",
    src: filePath,
    height: 2,
    width: 2,
    scale: 1.0,
    isTargetEnabled: false,
    target: "UNKNOWN",
    depth: 10,
    opacity: 1,
  };

  let sceneDataValue = localStorage.getItem("sceneId");

  // console.warn('scene id is', sceneDataValue);
  createImage(sceneDataValue, defaultObj, currentProjectId)
    .then((res) => {
      // console.warn('create image', res)
    })
    .catch((err) => {
      console.warn("error", err);
    });
  // run the function over all files.
  const promises = resources.map(uploadResToS3);
  // we now have a promises array and we want to wait for it
  return Promise.all(promises);
}

/**
 * this function is use for upload files
 *
 * @param {files for upload} files
 * @param {widget type} widgetType
 */
export function uploadImageFile(files, widgetType, userId, sceneid, projectid) {
  console.warn("image ::", files);
  console.warn("type ::", widgetType);

  const thiswidgetType = widgetType;
  if (!files || !widgetType) {
    Promise.reject();
  }
  const resources = [];
  const currentGuid = guid();
  for (let index = 0; index < files.length; index += 1) {
    resources.push({
      file: files[index],
      widgetType: thiswidgetType,
      filename:
        currentGuid +
        "-" +
        pad(index, 3) +
        "." +
        getFileExtension(files[index]),
    });
  }
  let uid = userId;
  let sceneDataValue = sceneid;
  let currentProjectId = projectid;
  localStorage.setItem("projectId", currentProjectId);
  // console.warn('uid is :: ',uid);
  // console.warn('curent project id is ::',currentProjectId);
  let filePath =
    "image/private/" + uid + "/" + currentProjectId + "/widgetimage/";
  filePath =
    filePath + currentGuid + "-" + pad(0, 3) + "." + getFileExtension(files[0]);
  console.warn("file path is ::", filePath);
  let defaultObj = {
    position: "0 0 10",
    src: filePath,
    height: 2,
    width: 2,
    scale: 1.0,
    isTargetEnabled: false,
    target: "UNKNOWN",
    depth: 10,
    opacity: 1,
  };

  // console.warn('scene id is', sceneDataValue);
  createImage(sceneDataValue, defaultObj, currentProjectId)
    .then((res) => {
      // console.warn('create image', res)
    })
    .catch((err) => {
      console.warn("error", err);
    });
  // run the function over all files.
  const promises = resources.map(uploadResToS3);
  // we now have a promises array and we want to wait for it
  return Promise.all(promises);
}

export function uploadImageFileInFirebase(
  files,
  widgetType,
  userId,
  sceneid,
  projectid,
) {
  console.warn("image ::", files[0]);
  console.warn("type ::", widgetType);
  console.warn("userId ::", userId);
  console.warn("sceneid ::", sceneid);
  console.warn("projectid ::", projectid);
  const thiswidgetType = widgetType;
  if (!files || !widgetType) {
    Promise.reject();
  }
  const resources = [];
  const currentGuid = guid();
  for (let index = 0; index < files.length; index += 1) {
    resources.push({
      file: files[index],
      widgetType: thiswidgetType,
      filename:
        currentGuid +
        "-" +
        pad(index, 3) +
        "." +
        getFileExtension(files[index]),
    });
  }

  let myPromise = new Promise((resolve, reject) => {
    const myGuid = guid();
    const storageUrl = firebaseStorage.storage("gs://vr-expo.appspot.com/");
    const storageRef = storageUrl.ref();

    const uploadTask = storageRef
      .child("image")
      .child("private")
      .child(userId)
      .child(projectid)
      .child("widgetimage")
      .child(myGuid)
      .put(files[0]);
    uploadTask.on(
      "state_changed",
      (snapShot) => {},
      (err) => {
        //catches the errors
        console.error(err);
        reject(err);
      },
      () => {
        firebaseStorage
          .storage("gs://vr-expo.appspot.com/")
          .ref()
          .child("image")
          .child("private")
          .child(userId)
          .child(projectid)
          .child("widgetimage")
          .child(myGuid)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            resolve(fireBaseUrl);
          })
          .catch((err) => {
            console.error("error caught", err);
          });
      },
    );
  });
  myPromise
    .then((url) => {
      let defaultObj = {
        position: "0 0 10",
        src: url,
        height: 2,
        width: 2,
        scale: 1.0,
        isTargetEnabled: false,
        target: "UNKNOWN",
        depth: 10,
        opacity: 1,
      };

      // console.warn('scene id is', sceneDataValue);
      createImage(sceneid, defaultObj, projectid)
        .then((res) => {
          // console.warn('create image', res)
        })
        .catch((err) => {
          console.warn("error", err);
        });
      // run the function over all files.
      const promises = resources.map(uploadResToS3);
      // we now have a promises array and we want to wait for it
      return Promise.all(promises);
    })
    .catch((err) => {
      console.error("error caught", err);
    });
}

/**
 * this function is use for add padding in updated name.
 */
function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
 * this function is use for get file extension
 *
 * @param {files for upload} file
 */
export function getFileExtension(file) {
  // console.warn('file name is :: ', file.name)
  const arr = file.name.split(".");
  if (arr && arr.length > 0) {
    return arr[arr.length - 1];
  }
  return "";
}

/**
 * this function is use for get file extension
 *
 * @param {link to get} link
 */
 export function getLinkExtension(link) {
  // console.warn('file name is :: ', file.name)
  const arr = link.split(".");
  if (arr && arr.length > 0) {
    return arr[arr.length - 1];
  }
  return "";
}

/**
 * this function is use for get normalised url
 *
 * @param {url} url
 */
function getNormalisedUrl(resource, url) {
  let normalisedurl = url;
  const ext = normalisedurl.split(".").pop();
  normalisedurl = normalisedurl.replace("." + ext, "");
  normalisedurl = "/aws-pptvr-cubemap/" + normalisedurl;
  return normalisedurl;
}

/**
 * this function is use for create image
 *
 * @param {id of sscenes} sceneid
 * @param {details of image} newImageObj
 */
function createImage(sceneid, newImageObj, projectId) {
  return new Promise((resolve, reject) => {
    getUserToken()
      .then((token) => {
        console.warn("image obj ::", newImageObj);
        console.warn("project id is::", projectId);
        // console.warn('token is::',token);
        // console.warn('scene id is::',sceneid);
        createImageNetwork(
          {
            projectid: projectId,
            token: token,
            sceneid: sceneid,
          },
          newImageObj,
        ).then(
          (responseNewImage) => {
            console.warn("database updated");
            resolve(responseNewImage);
            alert("Uploaded Successfully");
            window.location.href = "/home";
          },
          (error) => {
            // let message = "Error: " + error.status + " " + error.statusText;
            reject(error);
          },
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * this function is use for create image network
 *
 * @param {project details} obj
 * @param {details of image} newImageObj
 */
function createImageNetwork(obj, newImageObj) {
  return new Promise((resolve, reject) => {
    // console.warn('create image network obj', obj);
    // console.warn('create image network newimageobj', newImageObj);
    mrc
      .post(
        config.serverUrl +
          `projects/${obj.projectid}/scenes/${obj.sceneid}/images/`,
        newImageObj,
        {
          headers: {
            "x-access-token": obj.token,
          },
          // baseURL: config.serverUrl,
          // data: newImageObj
        },
      )
      .then((res) => {
        mrc.post(
          config.serverUrl + `projects/${obj.projectid}/`,
          { projecttype: "sparkle-360" },
          {
            headers: {
              "x-access-token": obj.token,
            },
            // baseURL: config.serverUrl,
            // data: newImageObj
          },
        );
        resolve(res);
      })
      .catch((err) => {
        console.warn("Axios request error ", err);
        reject(err);
      });
  });
}

/**
 * this function is use for upload gltf model
 *
 * @param {files for upload} files
 * @param {widget type} widgetType
 * @param {user id} uid
 * @param {scene id} sceneid
 * @param {project id} projectid
 */
export function uploadGltfModel(files, widgetType, uid, sceneid, projectid) {
  // console.warn('files is ::', files)
  const thiswidgetType = widgetType;
  if (!files || !widgetType) {
    Promise.reject();
  }
  const resources = [];
  const currentGuid = guid();
  modelProjectId = projectid;

  for (let index = 0; index < files.length; index += 1) {
    resources.push({
      file: files[index],
      widgetType: thiswidgetType,
      filename: currentGuid + "." + getFileExtension(files[index]),
    });
  }
  let modelPath =
    "model/private/" + uid + "/" + projectid + "/widgetgltfmodel/";
  modelPath = modelPath + currentGuid + "." + getFileExtension(files[0]);

  let defaultObj = {
    src: modelPath,
    position: "0 0 10",
    rotation: "0 0 0",
    scale: "1 1 1",
    depth: 10,
  };

  createGltfModel(sceneid, defaultObj, projectid)
    .then((res) => {
      console.warn("project created successfully", modelPath);
      // document.getElementById('loader-createpopup').style.display = 'none';
      // document.getElementById('create-name').style.display = 'block';
      alert("Your project created successfully");
      window.location.reload(false);
      // document.getElementById('create-button-popup').style.display = 'none';
      // window.location.href = '/home';
    })
    .catch((error) => {
      console.warn("failed to create project", error);
    });
  // run the function over all files.
  const promises = resources.map(uploadModelResToS3);
  // we now have a promises array and we want to wait for it
  return Promise.all(promises);
}

/**
 * this function is use for upload model responce to S3
 *
 * @param {resource} resource
 */
function uploadModelResToS3(resource) {
  //     const deferred = $q.defer();
  const widgetsFirebaseListener = ["widgetvideo", "video360", "image360"];
  // MONKEY PATCH TILL SERVER HAS METADATA FOR ASSETS STORED IN PROJECTS
  //
  //
  // const filename = guid() +'-'+resource.fileSuffix+ '.' + getFileExtension(resource.file);
  // let currentProjectId = window.location.href;
  // currentProjectId = currentProjectId.substring(currentProjectId.lastIndexOf('/') + 1)
  // console.warn('current project id is ::',currentProjectId)
  const urlData = {
    projectid: modelProjectId,
    restype: "model",
    widgettype: resource.widgetType,
    filename: resource.filename,
  };

  return new Promise((resolve, reject) => {
    getUserToken().then((token) => {
      getAWSSignedUrl(
        {
          token: token,
        },
        urlData,
      ).then((response) => {
        // console.warn('response data url is ::',response.data.url);
        // console.warn('response data url is ::',resource);
        mrc
          .put(response.data.url, resource.file, {
            headers: {
              "Content-Type":
                resource.file.type !== ""
                  ? resource.file.type
                  : "application/octet-stream",
              "Cache-Control": "public, max-age=172800",
            },
          })
          // console.warn('response.data.url',response.data.url)
          .then((data) => {
            // console.warn('Put success', data);
            let url = data.config.url.substr(0, data.config.url.indexOf("?"));
            resource.file.result = url;
            url = url.replace(
              "https://pptvr-images.s3.ap-south-1.amazonaws.com/",
              "",
            );
            // // var normalisedurl = getNormalisedUrl(resource, url);

            // MONKEY PATCHING UNTIL FIREBASE INTEGRATED FOR 3D MODEL UPLOAD
            if (widgetsFirebaseListener.indexOf(resource.widgetType) === -1) {
              // addWidget(url, resource.widgetType);
              resolve(resource);
            } else {
              const normalisedurl = getNormalisedUrl(resource, url);
              const ref = firebaseApp.database().ref(normalisedurl);
              ref.set({
                index: "CREATING",
              });
              ref.on("value", (snapshot) => {
                if (
                  snapshot.val() === "CREATED" ||
                  snapshot.val().index === "CREATED"
                ) {
                  // addWidget(url, resource.widgetType);
                  resolve(resource);
                  ref.off();
                }
              });
            }
          })
          .catch((err) => {
            console.error("Error ", err);
            reject(err);
          });
      });
    });
  });
  // })
  // .catch((error) => {
  //     deferred.reject(error);
  // });
  // return deferred.promise;
}

/**
 * check for mobile browser
 */
export function isMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}
