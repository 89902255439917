import React, { Component } from 'react'
import firebaseApp from '../functions/firebase';
import cancelimg from '../images/cancel.png'
import btnimg from '../images/button.png'

export default class SignOutPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.projectData,
        }
    }

    handleCancle = () => {
        // this.props.closePopup();
        document.getElementById('popup-signout').style.display = 'none'
    }

    /**
     * this function is use for handle signout
     */
    handleSignOut = () => {
        let logoutQuery = new Promise((resolve, reject) => {
            firebaseApp.auth().signOut().then(function () {
                // Sign-out successful.
                resolve('signout successfully')
            }).catch(function (error) {
                // An error happened.
                reject(error)
            });
        })
        logoutQuery.then(result => {
            console.warn('logout successful', result)
            window.localStorage.clear();
            window.location.href = '/';
        }).catch(error => {
            console.warn(error)
        })
        document.getElementById('popup-signout').style.display = 'none'
    }


    render() {
        return (
            <div>
                <div>
                    <div id="signout-popup">
                        <div className="overlay" onClick={this.handleCancle} />
                        <ul className="popupboxdelete toptobottom">
                            <li>
                                <div className="flex">
                                    <h4 className="popuptitle">
                                        Logout                           </h4>

                                    <span className="closepopup" onClick={this.handleCancle} >&times;</span>
                                </div>
                            </li>
                            <li>
                                <p className="deletetext">
                                    Are you sure you want to Logout ?
                            </p>
                            </li>
                            <li >
                                <div className="flexbtn">
                                <button className="Btn marginrightcancel" onClick={this.handleCancle}>
                                    <img src={btnimg} className="btnicon" alt="" />
                                    No
                                </button>
                                <button className="Btn marginrightcancel" onClick={this.handleSignOut} id="yes">
                                    <img src={btnimg} className="btnicon" alt="" />
                                    Yes
                                </button>
                                    {/* <button type="submit" onClick={this.handleCancle} className="popupbtn marginrightcancel widthbtn"  > <img src={cancelimg} className="btnicon" />No</button>
                                    <button type="submit" className="popupbtn widthbtn"  ><div id="yes" style={{ display: 'block' }} onClick={this.handleSignOut} ><img src={yesimg} className="btnicon" />Yes</div>
                                    </button> */}
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        )
    }
}
