/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import deleteimg from "../images/delete.png";
import backGroundImage from "../images/background.png";
import embedImage from "../images/embed.png";
import ARImage from "../images/augmented-reality.png";
import config from "../functions/config";
import { isMobile } from "../functions/upload-services";
import ArInMobile from "../popups/ar-in-mobile-popup";
import { getUserToken } from "../functions/aws-services";
let displayImage = 0;
let showImages = null;
// import DeletePopoup from '../popups/delete-popup';
export default class ProjectListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.projectData,
      popupOpen: false,
      projectId: this.props.projectData.id,
      isDesktop: false,
      uid: "",
    };
  }
  componentDidMount() {
    console.warn("project is ::", this.state.data);
    getUserToken().then((token) => {
      this.setState({ uid: token });
    });
  }


  hoverEvent = () => {
    console.warn('images', this.state.data.images)
    // for (let i = 0; i < this.state.data.images.length; i++) {
    // console.warn('images is ', this.state.data.images[i])

    showImages = setInterval(() => {
      displayImage++;
      if (displayImage === this.state.data.images.length) {
        displayImage = 0;
      }
      document.getElementById('360Images').setAttribute('src', this.state.data.images[displayImage].src)
    }, 1500);
    // }
  }

  leaveMouse = () => {
    if (showImages) {
      clearInterval(showImages)
      displayImage = 0
    }
    document.getElementById('360Images').setAttribute('src', this.state.data.sourceImg)
  }

  render() {
    return (
      <div className="gallery-item" >
        <div className=" gallery-image">
          {this.state.data.projectType === "sparkle-360" && (
            <img
              src={
                this.state.data.sourceImg !== "" && this.state.data.sourceImg
                  ? config.images + this.state.data.sourceImg
                  : backGroundImage
              }
              alt={this.state.data.title}
              onClick={this.openProject}
            />
          )}
          {this.state.data.projectType !== "sparkle-360" && (
            <img
              // onMouseEnter={this.hoverEvent} onMouseLeave={this.leaveMouse} 
              id='360Images'
              src={
                this.state.data.sourceImg !== "" && this.state.data.sourceImg
                  ? this.state.data.sourceImg
                  : backGroundImage
              }
              alt={this.state.data.title}
              onClick={this.openProject}
            />
          )}
        </div>
        {/* <hr className="hr-line" />
                <label className="description-text">{this.state.data.description}</label> */}
        <label className="LABEL">{this.state.data.title}</label>

        <div className="deleteposition">
          {/* <button onClick={this.deleteProjectButton}>Delete</button> */}
          <img
            src={deleteimg}
            onClick={this.deleteProjectButton}
            className="deletebtn deleteProject"
            data-tooltip="tooltip"
            data-placement="right"
            title="Delete"
          />
          {((this.state.data.sourceImg && this.state.data.sourceImg !== "") ||
            this.state.data.modelSrc) && (
              <img
                src={embedImage}
                onClick={this.embedProjectButton}
                className="embedbtn marginiconlist embedOnWebSite"
                data-tooltip="tooltip"
                data-placement="right"
                title="Embed"
              />
            )}
          {
            (this.state.data.sourceImg || this.state.data.modelSrc) && (
              <img
                src={ARImage}
                onClick={this.handleAr}
                className="embedbtn marginiconlist tryOnAr"
                data-tooltip="tooltip"
                data-placement="right"
                title="AR"
              />
            )

            // <button onClick={this.handleAr} >AR</button>
          }
        </div>
        {this.state.isDesktop && (
          <ArInMobile
            cancel={this.closeArPopup}
            projectId={this.state.projectId}
          />
        )}
      </div>
    );
  }

  /**
   * this function is use for go to ar page
   *
   * @param {event object} e
   */
  handleAr = (e) => {
    e.preventDefault();
    if (this.state.data.projectType === "sparkle-360") {
      // window.location.href = `/ar/${this.state.projectId}`
      window.location.href = "/ar/" + this.state.projectId;
      // window.location.href = '/ar'
    } else if (this.state.data.projectType === "sparkle-3d") {
      if (isMobile()) {
        window.location.href = "/ar/" + this.state.projectId;
      } else {
        this.setState({ isDesktop: true });
        console.warn("this feature not work in desktop version");
      }
    } else if (this.state.data.projectType === "sparkle-ar-necklace") {
      setTimeout(() => {
        window.location.href =
          `necklace/?p=` + this.state.data.id + `&u=` + this.state.uid;
      }, 1000);
    } else if (this.state.data.projectType === "sparkle-ar-earring") {
      setTimeout(() => {
        window.location.href =
          `necklace/?p=` + this.state.data.id + `&u=` + this.state.uid;
      }, 1000);
    } else if (this.state.data.projectType === "sparkle-ar-nosering") {
      setTimeout(() => {
        window.location.href =
          `necklace/?p=` + this.state.data.id + `&u=` + this.state.uid;
      }, 1000);
    } else if (this.state.data.projectType === "sparkle-ar-ring") {
      setTimeout(() => {
        window.location.href =
          `bracelet2/?p=` + this.state.data.id + `&u=` + this.state.uid;
      }, 1000);
    } else if (this.state.data.projectType === "sparkle-ar-bracelet") {
      setTimeout(() => {
        window.location.href =
          `bracelet2/?p=` + this.state.data.id + `&u=` + this.state.uid;
      }, 1000);
    } else if (this.state.data.projectType === "face-tryon") {
      setTimeout(() => {
        window.location.href =
          `face/?p=` + this.state.data.id;
      }, 1000);
    } else if (this.state.data.projectType === "hand-tryon") {
      setTimeout(() => {
        window.location.href =
          `hand/?p=` + this.state.data.id;
      }, 1000);
    }
    // else if (this.state.data.projectType === "sparkle-ar-bracelet") {
    //   setTimeout(() => {
    //     window.location.href =
    //       `bracelet/?p=` + this.state.data.id + `&u=` + this.state.uid;
    //   }, 1000);
    // } else if (this.state.data.projectType === "sparkle-ar-ring") {
    //   setTimeout(() => {
    //     window.location.href =
    //       `ring/?p=` + this.state.data.id + `&u=` + this.state.uid;
    //   }, 1000);
    // }
  };

  closeArPopup = () => {
    this.setState({ isDesktop: false });
  };

  /**
   * this function is use for open project and see in 360
   *
   * @param {event object} e
   */
  openProject = (e) => {
    e.preventDefault();
    if (this.state.data.projectType === "sparkle-360") {
      // console.warn('1')
      this.props.ShowProduct(this.state.data.id, "image");
    } else if (this.state.data.projectType === "sparkle-3d") {
      // console.warn('2')
      this.props.ShowProduct(this.state.data.id, "model");
    } else if (this.state.data.sourceImg === "") {
      window.location.href = "/upload/" + this.state.projectId;
    } else if (this.state.data.projectType === 'face-tryon') {
      window.location.href = `face/?p=` + this.state.data.id;
    } else if (this.state.data.projectType === 'sparkle-ar-necklace'
      || this.state.data.projectType === 'sparkle-ar-earring'
      || this.state.data.projectType === 'sparkle-ar-nosering') {
      window.location.href = `necklace/?p=` + this.state.data.id + `&u=` + this.state.uid;
    } else if (this.state.data.projectType === 'sparkle-ar-ring' || this.state.data.projectType === 'sparkle-ar-bracelet') {
      window.location.href = `bracelet2/?p=` + this.state.data.id + `&u=` + this.state.uid;
    }
  };

  deleteProjectButton = () => {
    this.props.actionButton(this.props.projectData.id, "delete");
  };

  embedProjectButton = () => {
    // console.warn('project data is :: ', this.state.data)
    let projectType = this.state.data.modelSrc ? "3d" : "360";
    this.props.actionButton(this.props.projectData.id, "embed", projectType);
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };
}
