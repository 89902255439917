const firebaseApp = require('firebase');

SetupFirebase();

/**
* Firebase Initialization Function
* This must be called in onload
*/
function SetupFirebase() {
    const config = {
        apiKey: 'AIzaSyA3quzqT7-E8mB5ok1LgT-HM-5PvwHgss0',
        authDomain: 'melzo-app.firebaseapp.com',
        databaseURL: 'https://melzo-app.firebaseio.com',
        projectId: 'melzo-app',
        storageBucket: 'melzo-app.appspot.com',
        messagingSenderId: '126598841107'
    };
    firebaseApp.initializeApp(config);

}

export default firebaseApp;