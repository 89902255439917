/* eslint-disable no-unused-vars */
import { getUserToken } from "./aws-services";
import mrc from "./mrc";
import config from "./config";

/**
 * this function is use for handle project
 *
 * @param {type of project} projectType
 * @param {previous array} previousArray
 * @param {fetch status} fetch
 * @param {callbackfunction} callbackFunction
 */
export function handleProjects(
  projectType,
  previousArray,
  fetch,
  callbackFunction,
) {
  getProjectsLocal({
    // token,
    category: "PRIVATE",
    type: projectType,
    startindex: previousArray.length,
    count: 20,
  })
    // .query()
    .then(
      (response) => {
        console.warn("Response", response);
        const tempArray = previousArray;
        // const tempModelArray = modelArray;
        let noOfLikes = 0;
        let noOfViews = 0;
        let fetchStatus = fetch;
        for (let i = 0; i < response.data.projects.length; i++) {
          // if()
          noOfLikes += parseInt(response.data.projects[i].stars, 10);
          noOfViews += parseInt(response.data.projects[i].views, 10);
          fetchStatus = response.data.fetchedAll;
          if (response.data.projects[i].scenes[0].gltfmodels[0]) {
            // console.warn('gltf model ::', response.data.projects[i].scenes[0].gltfmodels[0])
            tempArray.push({
              title: response.data.projects[i].projectname,
              description: response.data.projects[i].description,
              id: response.data.projects[i]._id,
              views: response.data.projects[i].views,
              stars: response.data.projects[i].stars,
              modelSrc: response.data.projects[i].scenes[0].gltfmodels[0].src,
              projectType: response.data.projects[i].projecttype,
              images:response.data.projects[i].scenes[0].images[i]
            });
          } else {
            // console.warn('my project is ::', response.data.projects[i])
            let source = response.data.projects[i].scenes[0].images[0]
              ? response.data.projects[i].scenes[0].images[0].src
              : "";

            tempArray.push({
              title: response.data.projects[i].projectname,
              description: response.data.projects[i].description,
              id: response.data.projects[i]._id,
              views: response.data.projects[i].views,
              stars: response.data.projects[i].stars,
              sourceImg: source,
              projectType: response.data.projects[i].projecttype,
              images:response.data.projects[i].scenes[0].images
            });
          }
        }

        callbackFunction(tempArray, noOfLikes, noOfViews, fetchStatus);
      },
      (response) => {
        var message = "Error: " + response.status + " " + response.statusText;
      },
    )

    .finally(() => {});
}

/**
 * this function is use for get local projects
 *
 * @param {project details} obj
 */
function getProjectsLocal(obj) {
  return new Promise((resolve, reject) => {
    getUserToken()
      .then((token) => {
        getprojectsNetwork({
          token: token,
          category: obj.category,
          type: obj.type,
          startindex: obj.startindex,
          count: obj.count,
        }).then(
          (response) => {
            // response.self = true; // set the self property to true as this project belongs to the user.
            // projFacObj.privateProjects.push(response);
            resolve(response);
          },
          (error) => {
            //     message = "Error: " + error.status + " " + error.statusText;
          },
        );
      })
      .catch((err) => {
        console.warn("error is ::", err);
      });
  });
}

/**
 * this function is use for get projects network
 *
 * @param {project details} obj
 */
function getprojectsNetwork(obj) {
  return new Promise((resolve, reject) => {
    mrc
      .get(
        config.serverUrl +
          `projects/all/${obj.category}/${obj.type}/${obj.startindex}/${obj.count}`,
        {
          // .get(config.serverUrl + `projects/all/${obj.category}/${obj.startindex}/${obj.count}`, {
          headers: {
            "x-access-token": obj.token,
          },
        },
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
