/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
// import firebaseApp from '../functions/firebase';
import './header.css'
import homeimg from '../images/home.png';
import logoutimg from '../images/logout.png';
// import infoimg from '../images/info.png';
import logoimg from '../images/JEWEL.png';
import SignOutPopup from '../popups/sign-out-popup';
import shadowimg from '../images/part.png';

export default class Headertryon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isInHome: this.props.showHomeButton,
        }
    }

    // componentDidMount() {
    //     this.checkDashboardAvailibility();
    // }

    // checkDashboardAvailibility = () => {
    //     // console.warn(window.location.href)
    //     if (window.location.href.includes('/home')) {
    //         this.setState({ isInHome: false })
    //     }
    // }


    render() {
        return (
            <div>
                <div className="flexheader">
                    {/* <h1 className="heading animationtext" id="ProjectList">My Projects</h1> */}

                    {/* <img src={logoimg} className="iconwidthlogo" onClick={this.handleHome} /> */}
                    <ul className="headersubtext" style={{ width: '100%' }}>

                        <li>
                            {this.props.isShowStore &&
                                <a id="visitOurStoreLink" className="colorfooterbtn" target="_blank" href=""
                                    style={{ textDecoration: 'none' }}>
                                    <button className="BtnFooter" id="visitOurStore">
                                        <img src="../image/store.png" alt="" className="btnimgwidth" style={{ marginRight: '5px' }} />
                                            Visit our store
                                </button>
                                </a>
                            }

                        </li>
                        <li className="paddingleft">
                            <p className="marginzero emailtext">{localStorage.getItem('emailId')}</p>

                        </li>
                        <li className="paddingleft">
                            {/* <button onClick={this.handleLogout}>Log out</button> */}
                            <img src={logoutimg} onClick={this.handleLogout} className="iconwidth" data-tooltip="tooltip" data-placement="right" title="Logout" />

                        </li>
                        {/* <li className="paddingleft">
                            <img src={homeimg} onClick={this.handleHome} className="iconwidth" data-tooltip="tooltip" data-placement="right" title="Go back to Home" />
                            
                        </li> */}
                    </ul>

                </div>
                {/* <img src={shadowimg} className="shadowposition"></img> */}
                <div id="popup-signout" style={{ display: 'none' }}>
                    <SignOutPopup />
                </div>
            </div >

        )
    }



    handleHome = () => {
        window.location.href = "/home";
    }

    /**
     * this function is use for logout
     */
    handleLogout = () => {

        document.getElementById('popup-signout').style.display = 'block'

    }
}
