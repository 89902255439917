/* eslint-disable*/
import React, { Component } from "react";
import "./loader.css";

export default class CreditWarning extends Component {
  render() {
    return (
      <div>
        <main>
          <div
            className="dank-ass-loader"
            style={{ color: "white", fontSize: "36px", letterSpacing: "3px" }}
          >
            All your credits are consumed,
            <br /> Kindly refill your Account wallet.
            <br /> Thank you!
          </div>
        </main>
      </div>
    );
  }
}
