/* eslint-disable */

import React, { Component } from 'react'
// import './qr-popup.css'

export default class ArInMobile extends Component {

    componentDidMount() {
        this.showQr();
    }

    handleCancle = () => {
        this.props.cancel();

    }

/**
 * this function is use for create qr code
 */
    showQr = () => {
        const qr = new QRious({
            element: document.getElementById('qr-code'),
            size: 200,
            // value: '/export/' + projectId
        });
        qr.set({
            foreground: 'black',
            size: 200,
            value: window.location.href.replace('home', 'ar') + '/' + this.props.projectId
            // value: 'https://rpvu.melzo.com/export/' + projectId
        });
    }
    render() {
        return (
            <div>
                <div id="ar-in-mobile-popup" style={{ display: 'block' }}>
                    <div className="overlay" onClick={this.handleCancle} />
                    <div className="qrbox toptobottom ">
                        <div className="closeqr" onClick={this.handleCancle} >×</div>



                        {/* <li>
                            <div className="flex">
                                <h4 className="popuptitle">
                                    Feature not available
                        </h4>
                            </div>
                        </li>
                        <li className="deletetext">
                            <p>This feature is not available for desktop version.</p>
                        </li> */}
                        <div className="flexqr">
                        <h5 className="scantext">
                        This feature is not available for desktop version.
                        </h5>
                            <h5 className="scantext">
                                Scan this QR code to view in your mobile
                        </h5>

                            <canvas id="qr-code" className="qrwidth qrmargin" />
                        </div>
                        {/* <li className="margintopbottom">
                            <div className="flexbtn">
                                <button type="submit" className="popupbtn marginrightcancel" onClick={this.handleCancle} > Ok</button>
                              
                            </div>
                        </li> */}
                    </div>
                </div>
            </div>
        )
    }
}
