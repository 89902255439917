/* eslint-disable */
import './verification.css'
import React, { Component } from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';

export default class InquiryForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      contactno: '',
      companyname: '',
      city: ''
    }
  }

  componentDidMount() {
    // const e = new CustomEvent('hide-splash', {
    //   bubbles: true
    // });
    // const thisEl = document.querySelector('#splash');
    // thisEl.dispatchEvent(e);

    let body = document.querySelector('body');
    body.style.backgroundColor = 'white';
    body.style.color = 'black';
  }

  submitDetails = (e) => {
    e.preventDefault();

    const { name, companyname, contactno, email, city } = this.state

    if (!name || !email || !companyname || !contactno || !city) {
      alert('Please Enter All the Fields')
    } else {
      // call mail api with both email address
      var obj = {
        yourname: name,
        usermail: email,
        adminmail: this.props.enquiryMail,
        projectname: this.props.projectname,
        telephone: contactno,
        company: companyname,
        city: city
      };

      // axios.post('https://us-central1-sendtoemail-3610d.cloudfunctions.net/sendMail', obj, {
      axios
        .post(
          "https://us-central1-sendtoemail-3610d.cloudfunctions.net/viewerInquirySubmission",
          obj,
          {
            headers: {
              // 'x-access-token': token
            },
          }
        )
        .then((res) => {
          this.props.successEnquiryFormEntry()
          localStorage.setItem('isEnqryDone', true)
        }).catch(err => {
          alert(err.message)
        })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <div className="wrapper1">
        <div className="container">
          <div className="popupform1">

            <h1 className="featurestitle1 ">Enquiry Form</h1>
            <div
              className="row"
              style={{ alignItems: "center", marginBottom: "20px" }}
            >
              <div className="col-12 col-md-4 col-lg-4">
                <span className="form-field">Name</span>
              </div>
              <div className="col-12 col-md-8 col-lg-8">
                <input
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  className="form-input"

                ></input>
              </div>
            </div>

            <div
              className="row"
              style={{ alignItems: "center", marginBottom: "20px" }}
            >
              <div className="col-12 col-md-4 col-lg-4">
                <span className="form-field">Email Address</span>
              </div>
              <div className="col-12 col-md-8 col-lg-8">
                <input
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  className="form-input"

                ></input>
              </div>
            </div>
            <div
              className="row"
              style={{ alignItems: "center", marginBottom: "20px" }}
            >
              <div className="col-12 col-md-4 col-lg-4">
                <span className="form-field">Contact No.</span>
              </div>
              <div className="col-12 col-md-8 col-lg-8">
                <input
                  type="number"
                  name="contactno"
                  onChange={this.handleChange}
                  className="form-input"

                ></input>
              </div>
            </div>
            <div
              className="row"
              style={{ alignItems: "center", marginBottom: "20px" }}
            >
              <div className="col-12 col-md-4 col-lg-4">
                <span className="form-field">Company Name</span>
              </div>
              <div className="col-12 col-md-8 col-lg-8">
                <input
                  type="text"
                  name="companyname"
                  onChange={this.handleChange}
                  className="form-input"

                ></input>
              </div>
            </div>
            <div
              className="row"
              style={{ alignItems: "center", marginBottom: "20px" }}
            >
              <div className="col-12 col-md-4 col-lg-4">
                <span className="form-field">City</span>
              </div>
              <div className="col-12 col-md-8 col-lg-8">
                <input
                  type="text"
                  name="city"
                  onChange={this.handleChange}
                  className="form-input"

                ></input>
              </div>
            </div>
            <button type="submit" className="submit-btnform1"
              id="login-button" onClick={this.submitDetails}><span className="btntext">Submit Details</span></button>
          </div>
        </div>

      </div>
    )
  }
}
