import { getUserToken } from "./aws-services";
import mrc from "./mrc";
import config from "./config";


/**
 * this function is use for delete project local
 * 
 * @param {project id} projectid 
 */
 export function deleteProjectLocal(projectid) {
    return new Promise((resolve, reject) => {
        getUserToken().then((token) => {
            deleteProjectNetwork({
                "token": token,
                "projectid": projectid
            }).then(
                (response) => {
                    resolve(response)
                },
                (error) => {
                    //     message = "Error: " + error.status + " " + error.statusText;
                }
            );
        }).catch((err) => {});
    });
  }
  

  /**
   * this function is use for delete project network
   * 
   * @param {project details} obj 
   */
  function deleteProjectNetwork(obj) {
    return new Promise((resolve, reject) => {
        mrc
            .delete(config.serverUrl + `projects/${obj.projectid.projectid}`, {
                headers: {
                    'x-access-token': obj.token
                }
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
  }