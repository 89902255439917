/* eslint-disable */

import React, { Component } from 'react'
import './qr-popup.css'

export default class QRpopup extends Component {

    componentDidMount() {
        this.showQr();
    }

    closePopup = () => {
        document.getElementById('scannerPopupOverlay').style.display = 'none';
        // document.getElementById('scannerPopup').style.display = 'none';
        document.getElementById('scannerPopup').classList.remove("toptobottom");
        document.getElementById('scannerPopup').classList.add("bottomtotop");

    }

/**
 * this function is use for create qr code
 */
    showQr = () => {
        const qr = new QRious({
            element: document.getElementById('qr-code-ar'),
            size: 200,
            // value: '/export/' + projectId
        });
        qr.set({
            foreground: 'black',
            size: 200,
            value: window.location.href
            // value: 'https://rpvu.melzo.com/export/' + projectId
        });
    }

    render() {
        return (
            <div>
                <div className="overlayQr" id="scannerPopupOverlay" onClick={this.closePopup} style={{ display: "none" }}>

                </div>
                <div className="qrbox toptobottom" id="scannerPopup" style={{ display: "none" }}>
                    <div className="closeqr" onClick={this.closePopup} >×</div>

                    <div className="flexqr">
                        <h5 className="scantext">
                            Scan this QR code to view in your mobile
                        </h5>
                        <br />

                        <canvas id="qr-code-ar" className="qrwidth qrmargin" />

                    </div>
                </div>
            </div>
        )
    }
}
