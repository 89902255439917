import React, { Component } from "react";
import logomelzoimg from "../images/JEWEL.png";
import Logopartimg from "../images/Logopart.png";
import "./project-type.css";
import viewimg from "../images/degree.png";
import modelimg from "../images/3dmodel.png";
import tryonmg from "../images/tryon.png";
import Footer from "../header/footer";

export default class Projecttype extends Component {
  render() {
    return (
      <div>
        <section className="project-section">
          <div className="row project-sectionheight">
            <div className="col-12 col-md-4 col-lg-4">
              <div className="noorposition">
                <img src={logomelzoimg} className="noorwidth" />
              </div>
            </div>
            <div className="col-md-1 col-lg-1 hideshadow1">
              <img
                src={Logopartimg}
                className="fullwidth hideshadow1 heightshadow"
              />
            </div>

            <div className="col-12 col-md-7 col-lg-7">
              <div className="row">
                <div className="col-12 col-md-0 col-lg-2"></div>
                <div className="col-12 col-md-12 col-lg-10">
                  <p className="Choosetext">Choose a Project Type:</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-0 col-lg-2"></div>
                <div className="col-4 col-md-3 col-lg-3 create-360Project">
                  <div
                    onClick={() => {
                      this.props.openTour();
                      this.props.changeStateFor360View('360-view');
                    }}
                  >
                    <div className="boxproject">
                      <img src={viewimg} data-tooltip="tooltip"
                        data-placement="right"
                        title="360 View" className="widthicon"></img>
                      <p className="project-type">360 View</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-3 col-lg-3 view3Dmodel">
                  <div
                    onClick={() => {
                      this.props.openTour();
                      this.props.changeStateFor3dModel('3d-model');
                    }}
                  >
                    <div className="boxproject">
                      <img src={modelimg} data-tooltip="tooltip"
                        data-placement="right"
                        title="3D Model" className="widthicon"></img>
                      <p className="project-type">3D Model</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-3 col-lg-3 tryOnProject">
                  <div
                    onClick={() => {
                      this.props.openTour('tryOn');
                      this.props.changeStateForTryon('tryon');
                    }}
                  >
                    <div className="boxproject">
                      <img src={tryonmg} data-tooltip="tooltip"
                        data-placement="right"
                        title="Tryon" className="widthicon"></img>
                      <p className="project-type">Tryon</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-0 col-lg-1"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        {/* <div className="footer" id="Footer">
          <footer className="footer_area">
            <div className="container">
              <p className="footer-text">
                Powered by{" "}
                <a
                  href="https://melzo.com/"
                  target="_blank"
                  className="underline"
                >
                  melzo.com
                </a>
              </p>
            </div>
          </footer>
        </div> */}
      </div>
    );
  }
}
