// showProject: '360-view',
// isProjectSelected:false

// changeStateFor360View = (value) => {
//   this.setState({showProject:value})
//   this.setState({isProjectSelected:true})
//   document.getElementById("productgrid").setAttribute("class", "col-md-12");
// };
// changeStateFor3dModel = (value) => {
//   this.setState({showProject:value})
//   this.setState({isProjectSelected:true})
//   document.getElementById("productgrid").setAttribute("class", "col-md-12");
// };

// changeStateForTryon = (value) => {
//   this.setState({showProject:value})
//   this.setState({isProjectSelected:true})
//   // document.getElementById("productgrid").setAttribute("class", "col-md-12");
// };

// return (
//   <div>
//     {this.state.isProjectSelected === false &&(
//         <Projecttype
//           changeStateFor360View={this.changeStateFor360View}
//           changeStateFor3dModel={this.changeStateFor3dModel}
//           changeStateForTryon={this.changeStateForTryon}
//         />
//       )}

//     {this.state.isLoaderActive && <Loader />}

//     {this.state.showProject === '360-view' && <View360 />}
//     {this.state.showProject === '3d-model' && <Model3D />}
//     {this.state.showProject === 'tryon' && <TryOn />}

//   </div>
// );

/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import "./project-list-page.css";
import CreateButtonPopup from "../popups/create-button-popup";
import ProjectListItem from "./project-list-item";
import Header from "../header/header";
import { createProjectLocal, guid } from "../functions/aws-services";
import "../popups/create-button-popup.css";
import { handleProjects } from "../functions/get-projects-service";
import firebaseApp from "../functions/firebase";
import { deleteProjectLocal } from "../functions/delete-project";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getFileExtension,
  uploadFiles,
  uploadGltfModel,
  uploadImageFile,
  uploadImageFileInFirebase,
} from "../functions/upload-services";
import { createGltfModel } from "../functions/model-upload-service";
import { error } from "spritespin/release/src/utils";
import Footer from "../header/footer";
import DeletePopoup from "../popups/delete-popup";
import EmbedPopup from "../popups/embed-popup";
import SignOutPopup from "../popups/sign-out-popup";
import createimg from "../images/create.png";
import btnimg from "../images/button.png";
import Logopartimg from "../images/Logopart.png";
import viewimg from "../images/degree.png";
import modelimg from "../images/3dmodel.png";
import Necklacemg from "../images/Necklace.png";
import Earingmg from "../images/Earing.png";
import Noseringmg from "../images/Nosering.png";
import homeimg from '../images/home.png';

import Loader from "../loader";
import Projecttype from "./project-type";
import View360 from "./360-view";
import Model3D from "./3d-model";
import TryOn from "./try-on";
import Headertryon from "../header/headertryon";
import TryOnCatalogue from "./try-on-catalogue";
import Tour from 'reactour';
export var userid = "";

export default class ProjectListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myUid: "",
      select360project: false,
      select3Dproject: false,
      selecttryonproject: false,
      userEmail: "",
      allProjects: [],
      imageProjects: [],
      necklaceProjects: [],
      earringProjects: [],
      noseringProjects: [],
      modelProjects: [],
      allProjectsFetchStatus: false,
      isPopupOpen: false,
      projectName: "",
      projectDescription: "",
      selectedProductImage: "",
      isViewer: false,
      imageFetchStatus: false,
      modelFetchStatus: false,
      necklaceFetchStatus: false,
      earringFetchStatus: false,
      noseringFetchStatus: false,
      isEditor: false,
      typevalue: "360-view",
      deletePopup: false,
      embedProjectpopup: false,
      projectToDelete: "",
      projectToEmbed: "",
      titleForDelete: "Are you sure to Delete?",
      isLogout: false,
      embedProjectType: "",
      isLoaderActive: true,
      showProject: "all",
      isProjectSelected: false,
      isTourOpen: true,
      isShowingMore: false,
      steps1: [],
      isTryonHint: 0
    };
  }

  componentDidMount() {

    let demoArray = []
    console.log("Local Storage Data :: ", localStorage.getItem('homeHint'))
    if (localStorage.getItem('homeHint') == null) {
      demoArray = [
        {
          selector: '.create-project',
          content: 'Create a New Project.',
        },
        {
          selector: '.create-360Project',
          content: 'Create and View 360° Projects.',
        },
        {
          selector: '.view3Dmodel',
          content: 'View 3D Model Library.',

        },
        {
          selector: '.tryOnProject',
          content: 'Try on your Projects in AR.'
        },

        {
          selector: '.logOut',
          content: 'Logout from your session here.'

        },
      ]
      this.setState({
        steps1: demoArray
      }, () => {
        console.log("step Data :: ", this.state.steps1)
      })
    }

    this.getUserUid();
    this.setState({ allProjects: this.state.imageProjects });
    handleProjects(
      "sparkle-360",
      this.state.imageProjects,
      this.state.imageFetchStatus,
      this.handleProjectListValuesforImage,
    );
    handleProjects(
      "sparkle-3d",
      this.state.modelProjects,
      this.state.modelFetchStatus,
      this.handleProjectListValuesforModel,
    );
    handleProjects(
      "sparkle-ar-necklace",
      this.state.necklaceProjects,
      this.state.necklaceFetchStatus,
      this.handleProjectListValuesforNecklace,
    );

    handleProjects(
      "sparkle-ar-necklace" && "sparkle-ar-nosering" && "sparkle-ar-earring",
      this.state.allProjects,
      this.state.allProjectsFetchStatus,
      this.handleProjectListValuesforAllProjects,
    );
    handleProjects(
      "sparkle-ar-earring",
      this.state.earringProjects,
      this.state.earringFetchStatus,
      this.handleProjectListValuesforEarring,
    );
    handleProjects(
      "sparkle-ar-nosering",
      this.state.noseringProjects,
      this.state.noseringFetchStatus,
      this.handleProjectListValuesforNosering,
    );
    document.addEventListener("scroll", (e) => this.handleScroll(e));
  }

  /**
   * this function is use for handle project list values for image
   *
   * @param {array of image} imageArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforImage = (
    imageArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    this.setState({ imageProjects: imageArray });
    // this.setState({ modelProjects: modelArray })
    this.setState({ imageFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });

    console.warn("image projects", imageArray.length);
  };

  /**
   * this function is use for handle project list values for model
   *
   * @param {array of model} modelArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforModel = (
    modelArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ modelProjects: modelArray });
    this.setState({ modelFetchStatus: fetch });

    // this.handleSelector();
    this.setState({ isLoaderActive: false });
    console.warn("model projects", modelArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for handle project list of earring
   *
   * @param {image of earring} EarringArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforEarring = (
    EarringArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ earringProjects: EarringArray });
    this.setState({ earringFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });
    console.warn("earring projects", EarringArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for handle project list of nosering
   *
   * @param {image of nosering} NoseringArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforNosering = (
    NoseringArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ noseringProjects: NoseringArray });
    this.setState({ noseringFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });
    console.warn("nosering projects", NoseringArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for handle project list of Necklace
   *
   * @param {image of Necklace} NecklaceArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforNecklace = (
    NecklaceArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ necklaceProjects: NecklaceArray });
    this.setState({ necklaceFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });
    console.warn("necklace projects", NecklaceArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  togglepageto360 = () => {
    this.setState({ select360project: true });
    this.setState({ allProjects: [] });
    this.setState({ typevalue: "360-view" });

    let temp = this.state.imageProjects;
    setTimeout(() => {
      this.setState({ allProjects: temp });
    }, 1);
    document.getElementById("productgrid").setAttribute("class", "col-md-12");
  };
  togglepageto3d = () => {
    this.setState({ select3Dproject: true });
    this.setState({ typevalue: "3d-view" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.modelProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
    document.getElementById("productgrid").setAttribute("class", "col-md-12");
  };

  togglepagetotryon = () => {
    this.setState({ selecttryonproject: true });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.necklaceProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
    // document.getElementById("productgrid").setAttribute("class", "col-md-12");
  };

  /**
   * this function is use for handle project list of Necklace
   *
   * @param {image of Necklace} AllProjectArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforAllProjects = (
    AllProjectArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ AllProjectArray: AllProjectArray });
    this.setState({ allProjectsFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });
    console.log("All projects", AllProjectArray);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for get user id
   */
  getUserUid = () => {
    return new Promise((resolve, reject) => {
      firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
          // user is signed in
          resolve(user.uid);
          localStorage.setItem("uid", user.uid);
          localStorage.setItem("emailId", user.email);
          // console.warn('user uid',user.uid)
          this.setState({ userEmail: user.email });
          this.setState({ myUid: user.uid });
          this.setState({ isLoaderActive: false });
        } else {
          window.location.href = "/";
          // anonymous user, proceed with token as null
          resolve(null);
        }
      });
    });
  };

  changeStateFor360View = (value) => {
    this.setState({ showProject: value });
    this.setState({ isProjectSelected: true });
    document.getElementById("productgrid").setAttribute("class", "col-md-12");
  };
  changeStateFor3dModel = (value) => {
    this.setState({ showProject: value });
    this.setState({ isProjectSelected: true });
    document.getElementById("productgrid").setAttribute("class", "col-md-12");
  };

  changeStateForTryon = (value) => {
    this.setState({ showProject: value });
    this.setState({ isProjectSelected: true });
    // document.getElementById("productgrid").setAttribute("class", "col-md-12");
  };

  // refreshPage = () => {
  //     // this.componentDidMount();
  // }

  handleProjectName = (e) => {
    e.preventDefault();
    this.setState({ projectName: e.target.value });
  };

  handleProjectDescription = (e) => {
    e.preventDefault();
    this.setState({ projectDescription: e.target.value });
  };

  handleCreateButton = () => {
    this.setState({ isPopupOpen: true });
    // console.warn('create button clicked ::')

    // document.getElementById('create-button-popup').style.display='block';
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  // handleCancle=()=>{
  //     document.getElementById('create-button-popup').style.display = 'none';
  // }

  /**
   * this function is use for create a new project
   *
   * @param {project name} name
   * @param {project description} description
   * @param {project type} typevalue
   * @param {model} model
   */
  CreateProject = (name, description, typevalue, selectedFile = null) => {
    switch (typevalue) {
      case "360-view":
        // code block
        createProjectLocal(name, description, typevalue).then((res) => {
          // const notificationMsg = "Project created successfully"
          // localStorage.setItem('notificationMsg', notificationMsg);
          let sceneData = res.data.scenes[0].sceneid;
          let uid = res.data.uid;
          userid = uid;
          // console.warn('response value is', res.data);
          localStorage.setItem("sceneId", sceneData);

          this.setState({ isEditor: true });
          this.props.history.push(`/upload/${res.data._id}`);
          // window.location.href = `/upload/${res.data._id}`
        });
        break;
      case "3D-model":
        // code block
        createProjectLocal(name, description, typevalue).then((res) => {
          let uid = res.data.uid;
          let sceneData = res.data.scenes[0].sceneid;
          let projectId = res.data._id;
          console.warn("Project created successfully", projectId);
          uploadGltfModel(
            selectedFile,
            "widgetgltfmodel",
            uid,
            sceneData,
            projectId,
          ).then((res) => {
            this.closePopup();
          });
        });
        break;
      case "necklace":

      case "earring":

      case "nosering":
        // code block
        createProjectLocal(name, description, typevalue).then((res) => {
          // const notificationMsg = "Project created successfully"
          // localStorage.setItem('notificationMsg', notificationMsg);
          let sceneData = res.data.scenes[0].sceneid;
          let projectId = res.data._id;
          let uid = res.data.uid;
          userid = uid;
          // console.warn('response value is', res.data);
          localStorage.setItem("sceneId", sceneData);

          uploadImageFileInFirebase(
            selectedFile,
            "widgetimage",
            uid,
            sceneData,
            projectId,
          );
          // .then(
          //     () => {
          //         alert("Uploaded Successfully")
          //         window.location.href = '/home'
          //     }
          // );
          // window.location.href = `/upload/${res.data._id}`
        });
        break;
      default:
        // code block
        createProjectLocal(name, description, typevalue).then((res) => {
          // const notificationMsg = "Project created successfully"
          // localStorage.setItem('notificationMsg', notificationMsg);
          let sceneData = res.data.scenes[0].sceneid;
          let uid = res.data.uid;
          userid = uid;
          // console.warn('response value is', res.data);
          localStorage.setItem("sceneId", sceneData);

          this.setState({ isEditor: true });
          this.props.history.push(`/upload/${res.data._id}`);
          // window.location.href = `/upload/${res.data._id}`
        });
        break;
    }
  };

  showNecklaceProject = () => {
    this.setState({ typevalue: "necklace" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.necklaceProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  showEaringProject = () => {
    this.setState({ typevalue: "earring" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.earringProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  showNoseringProjecet = () => {
    this.setState({ typevalue: "nosering" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.noseringProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  /**
   * this function is use for show project in 360
   *
   * @param {project id} projectId
   * @param {project type} type
   */
  ShowProduct = (projectId, type) => {
    // e.preventDefault()
    // this.setState({ selectedprojectId: projectId });
    localStorage.setItem("selectedprojectId", projectId);
    console.warn("selected product is ::");
    if (type === "image") {
      window.location.href = `/360/${projectId}`;
      // window.location.href = '/360/'+ projectId
    } else if (type === "model") {
      window.location.href = `/3d/${projectId}`;
      // window.location.href = '/3d/'+ projectId
    }

    // this.setState({ isViewer: true })
    // setTimeout(() => {
    // console.warn('isviewer value', this.state.isViewer)
    // }, 1);
  };

  /**
   * this function is use for delete project
   *
   *
   * @param {project id of selected project for delete} projectIdToDelete
   */
  deleteProject = (projectIdToDelete) => {
    deleteProjectLocal({
      projectid: projectIdToDelete,
    }).then((response) => {
      this.setState({ allProjects: [] });
      this.setState({ imageProjects: [] });
      this.setState({ modelProjects: [] });
      this.setState({ earringProjects: [] });
      this.setState({ noseringProjects: [] });
      this.setState({ necklaceProjects: [] });
      setTimeout(() => {
        this.getUserUid();
        this.setState({ allProjects: this.state.imageProjects });
        handleProjects(
          "sparkle-360",
          this.state.imageProjects,
          this.state.imageFetchStatus,
          this.handleProjectListValuesforImage,
        );
        handleProjects(
          "sparkle-3d",
          this.state.modelProjects,
          this.state.modelFetchStatus,
          this.handleProjectListValuesforModel,
        );
        handleProjects(
          "sparkle-ar-necklace",
          this.state.necklaceProjects,
          this.state.necklaceFetchStatus,
          this.handleProjectListValuesforNecklace,
        );
        handleProjects(
          "sparkle-ar-earring",
          this.state.earringProjects,
          this.state.earringFetchStatus,
          this.handleProjectListValuesforEarring,
        );
        handleProjects(
          "sparkle-ar-nosering",
          this.state.noseringProjects,
          this.state.noseringFetchStatus,
          this.handleProjectListValuesforNosering,
        );
      }, 1);

      // this.componentDidMount();
    });
  };

  closeDeletePopup = () => {
    this.setState({ deletePopup: false });
  };
  closeEmbedPopup = () => {
    this.setState({ embedProjectpopup: false });
  };

  embedProject = (projectIdToEmbed) => {
    console.warn("embed ::", projectIdToEmbed);
  };

  /**
   * this function is use for delete or embade project
   *
   * @param {id of project} projectid
   * @param {work of function} actionToPerform
   * @param {project type} type
   */
  actionButton = (projectid, actionToPerform, type = "360") => {
    // deleteProjectLocal({
    //     projectid: projectIdToDelete
    // }).then((response) => {
    //     this.setState({ allProjects: [] });
    //     this.setState({ imageProjects: [] });
    //     this.setState({ modelProjects: [] });
    //     this.componentDidMount();
    // })
    if (actionToPerform === "delete") {
      this.setState({ projectToDelete: projectid });
      this.setState({ deletePopup: true });
    } else if (actionToPerform === "embed") {
      this.setState({ projectToEmbed: projectid });
      this.setState({ embedProjectType: type });
      this.setState({ embedProjectpopup: true });
    }
    // console.warn('project id is ::', projectid)
  };

  logOut = (msg) => {
    if (msg === "logOut") {
      // this.setState({isLogout:true})
      document.getElementById("popup-signout").style.display = "block";
    }
  };

  /**
   * this function is use for handle logout
   */
  handlelogout = () => {
    let logoutQuery = new Promise((resolve, reject) => {
      firebaseApp
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
          resolve("signout successfully");
        })
        .catch(function (error) {
          // An error happened.
          reject(error);
        });
    });
    logoutQuery
      .then((result) => {
        console.warn("logout successful", result);
        window.localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.warn(error);
      });
    this.setState({ isLogout: false });
  };

  closeLogout = () => {
    this.setState({ isLogout: false });
  };

  /**
   * this function is use for handle scroll
   *
   * @param {event object} e
   */
  handleScroll = (e) => {
    e.preventDefault();
    // console.warn('handle scroll', e.target.scrollingElement)
    const bottomScroll =
      document.documentElement.scrollTop + window.innerHeight ===
      document.documentElement.scrollHeight;
    if (bottomScroll) {
      // console.warn('Bottom reached');
      if (this.state.typevalue === "3D-model" && !this.state.modelFetchStatus) {
        handleProjects(
          "sparkle-3d",
          this.state.modelProjects,
          this.state.modelFetchStatus,
          this.handleProjectListValuesforModel,
        );
      } else if (
        this.state.typevalue === "360-view" &&
        !this.state.imageFetchStatus
      ) {
        handleProjects(
          "sparkle-360",
          this.state.imageProjects,
          this.state.imageFetchStatus,
          this.handleProjectListValuesforImage,
        );
      } else if (
        this.state.typevalue === "necklace" &&
        !this.state.necklaceFetchStatus
      ) {
        handleProjects(
          "sparkle-ar-necklace",
          this.state.necklaceProjects,
          this.state.necklaceFetchStatus,
          this.handleProjectListValuesforNecklace,
        );
      } else if (
        this.state.typevalue === "earring" &&
        !this.state.earringFetchStatus
      ) {
        handleProjects(
          "sparkle-ar-earring",
          this.state.earringProjects,
          this.state.earringFetchStatus,
          this.handleProjectListValuesforEarring,
        );
      } else if (
        this.state.typevalue === "nosering" &&
        !this.state.noseringFetchStatus
      ) {
        handleProjects(
          "sparkle-ar-nosering",
          this.state.noseringProjects,
          this.state.noseringFetchStatus,
          this.handleProjectListValuesforNosering,
        );
      }
    }
  };

  // /**
  //  * this function is use for handle dropdown for select project type "3D-model" or "360-view"
  //  */
  // handleSelector = () => {
  //   if (document.getElementById("selector").value === "3D-model") {
  //     document.getElementById("secondSelector").style.display = "none";
  //     // console.warn('Should display model', document.getElementById('selector').value);
  //     this.setState({ allProjects: [] });
  //     this.setState({ typevalue: document.getElementById("selector").value });

  //     let temp = this.state.modelProjects;
  //     setTimeout(() => {
  //       this.setState({ allProjects: temp });
  //     }, 1);
  //   } else if (document.getElementById("selector").value === "360-view") {
  //     document.getElementById("secondSelector").style.display = "none";
  //     this.setState({ typevalue: document.getElementById("selector").value });
  //     this.setState({ allProjects: [] });
  //     // console.warn('Should display images', this.state.imageProjects);
  //     let myTemp = this.state.imageProjects;
  //     setTimeout(() => {
  //       this.setState({ allProjects: myTemp });
  //     }, 1);
  //   } else if (document.getElementById("selector").value === "tryon") {
  //     document.getElementById("secondSelector").style.display = "block";

  //     if (document.getElementById("secondSelector").value === "necklace") {
  //       this.setState({
  //         typevalue: document.getElementById("secondSelector").value,
  //       });
  //       this.setState({ allProjects: [] });
  //       // console.warn('Should display images', this.state.imageProjects);
  //       let myTemp = this.state.necklaceProjects;
  //       setTimeout(() => {
  //         this.setState({ allProjects: myTemp });
  //       }, 1);
  //     } else if (
  //       document.getElementById("secondSelector").value === "earring"
  //     ) {
  //       this.setState({
  //         typevalue: document.getElementById("secondSelector").value,
  //       });
  //       this.setState({ allProjects: [] });
  //       // console.warn('Should display images', this.state.imageProjects);
  //       let myTemp = this.state.earringProjects;
  //       setTimeout(() => {
  //         this.setState({ allProjects: myTemp });
  //       }, 1);
  //     } else if (
  //       document.getElementById("secondSelector").value === "nosering"
  //     ) {
  //       this.setState({
  //         typevalue: document.getElementById("secondSelector").value,
  //       });
  //       this.setState({ allProjects: [] });
  //       // console.warn('Should display images', this.state.imageProjects);
  //       let myTemp = this.state.noseringProjects;
  //       setTimeout(() => {
  //         this.setState({ allProjects: myTemp });
  //       }, 10);

  //       // setTimeout(() => {
  //       //     console.warn('nosering project is ::', this.state.allProjects)
  //       // }, 1000);
  //     }
  //   }
  // };

  handleSelectorForNewDropDown = () => {
    if (document.getElementById("selector").value === "tryon") {
      document.getElementById("secondSelector").style.display = "block";

      if (document.getElementById("secondSelector").value === "necklace") {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.necklaceProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 1);
      } else if (
        document.getElementById("secondSelector").value === "earring"
      ) {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.earringProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 1);
      } else if (
        document.getElementById("secondSelector").value === "nosering"
      ) {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.noseringProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 10);

        // setTimeout(() => {
        //     console.warn('nosering project is ::', this.state.allProjects)
        // }, 1000);
      }
    }
  };

  // checkconsole = () => {
  //     console.warn('image fetchstatus::', this.state.imageFetchStatus);
  //     console.warn('model fetchstatus::', this.state.modelFetchStatus)
  // }

  closeTour = () => {
    this.setState({ isTourOpen: false });
    if (this.state.steps1.length == 5 && this.state.isTryonHint == false) {
      localStorage.setItem('homeHint', "done")
    }
    if ((this.state.steps1.length == 5 || this.state.steps1.length == 2) &&
      this.state.isTryonHint) {
      localStorage.setItem('projectHint', "done")
      localStorage.setItem('tryOnHint', "done")
    }

  };

  openTour = (newValue) => {
    setTimeout(() => {
      let step1Data = []
      if (localStorage.getItem('projectHint') == null) {
        step1Data = [
          {
            selector: '.tryOnAr',
            content: 'Try on your Project in AR.'
          },
          {
            selector: '.embedOnWebSite',
            content: 'Embed the Project on your Website.'
          },
          {
            selector: '.deleteProject',
            content: 'Delete your Project.'
          },
        ]
      }

      if (newValue == 'tryOn' && localStorage.getItem('tryOnHint') == null) {
        // let newRecord = {
        //   selector: '.faceTry',
        //   content: 'Earrings, Necklaces and Nosepins.'
        // }
        // let newRecord1 = {
        //   selector: '.handTry',
        //   content: 'Bracelets and Rings.'
        // }
        // step1Data.push(newRecord)
        // step1Data.push(newRecord1)
      }
      this.setState({
        steps1: step1Data,
        isTryonHint: 1
      }, () => {
        this.setState({ isTourOpen: true });
      })

    }, 2000);
  };


  toggleShowMore = () => {
    this.setState(prevState => ({
      isShowingMore: !prevState.this.isShowingMore
    }));
  };


  render() {
    return (
      <div>
        {this.state.isTourOpen && this.state.steps1.length > 0 &&
          <Tour

            id="tour1"
            steps={this.state.steps1}

            isOpen={this.state.isTourOpen}
            onRequestClose={this.closeTour}
            // openTour={() => this.openTour(true)}
            toggleShowMore={this.toggleShowMore}
            getCurrentStep={curr => {
              if (curr + 1 == this.state.steps1.length && this.state.steps1.length == 5) {
                localStorage.setItem('homeHint', "done")
              }
              if (curr + 1 == this.state.steps1.length && this.state.steps1.length == 3) {
                localStorage.setItem('projectHint', "done")
              }
              if (curr + 1 == this.state.steps1.length &&
                (this.state.steps1.length == 5 || this.state.steps1.length == 2) &&
                this.state.isTryonHint == 1) {
                localStorage.setItem('projectHint', "done")
                localStorage.setItem('tryOnHint', "done")
                this.setState({ isTryonHint: 0 })
              }
            }}
          // startAt={this.extendedTour && this.currentStep >= 7 ? 7 : undefined}

          />
        }

        {this.state.isProjectSelected === false && (
          <Projecttype
            openTour={this.openTour}
            changeStateFor360View={this.changeStateFor360View}
            changeStateFor3dModel={this.changeStateFor3dModel}
            changeStateForTryon={this.changeStateForTryon}
          />
        )}

        {this.state.isLoaderActive && <Loader />}
        {this.state.showProject === '360-view' && <View360 changeStateFor360View={this.changeStateFor360View}
          changeStateFor3dModel={this.changeStateFor3dModel}
          changeStateForTryon={this.changeStateForTryon} />}
        {this.state.showProject === '3d-model' && <Model3D changeStateFor360View={this.changeStateFor360View}
          changeStateFor3dModel={this.changeStateFor3dModel}
          changeStateForTryon={this.changeStateForTryon} />}
        {/* {this.state.showProject === 'tryon' && <TryOn changeStateFor360View={this.changeStateFor360View}
          changeStateFor3dModel={this.changeStateFor3dModel}
          changeStateForTryon={this.changeStateForTryon} />} */}
        {this.state.showProject === 'tryon' && <TryOnCatalogue changeStateFor360View={this.changeStateFor360View}
          changeStateFor3dModel={this.changeStateFor3dModel}
          changeStateForTryon={this.changeStateForTryon} />}
        {/* {this.state.showProject === "360-view" && <View360 />}
        {this.state.showProject === "3d-model" && <Model3D />}
        {this.state.showProject === "tryon" && <TryOnCatalogue />} */}
        {this.state.showProject === "all" && (
          <div>
            {!this.state.isLoaderActive && (
              <div>
                <div className="project-list">
                  <Header
                    userEmail={this.state.userEmail}
                    showHomeButton={this.state.isViewer || this.state.isEditor}
                    confirmLogout={this.logOut}
                  />
                  {this.state.isViewer === false && <Headertryon></Headertryon>}

                  <div className="container-fluid">
                    {/* {this.state.isViewer === true &&
                                <ProjectDisplay selectedProductImagevalue={this.state.selectedProductImage} />
                            } */}
                    {this.state.isViewer === false && (
                      <>
                        <div className="margintopfive">
                          {/* <div className="row margintopbottom">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h1 className="heading animationtext" id="ProjectList">My Projects</h1>
                                                </div>
                                            </div> */}
                          <div className="row margintopbottom columnrev">
                            <div
                              className="col-12 col-md-6 col-lg-6"
                              style={{ display: "none" }}
                            >
                              <div
                                className="flexdropdown "
                                style={{ display: "none" }}
                              >
                                <label
                                  htmlFor="menu"
                                  className="Choosetext animationdropdown"
                                  style={{ display: "none" }}
                                >
                                  Choose a project type:
                                </label>

                                <div
                                  className="box animationdropdown"
                                  style={{ display: "none" }}
                                >
                                  <select
                                    name="menu"
                                    id="selector"
                                    onChange={this.handleSelector}
                                  >
                                    <option id="dropDown-360" value="360-view">
                                      360 view
                                    </option>
                                    <option id="dropDown-3d" value="3D-model">
                                      3D Model
                                    </option>
                                    <option id="dropDown-tryon" value="tryon">
                                      Tryon
                                    </option>
                                  </select>
                                </div>
                                <div
                                  className="box animationdropdown"
                                  style={{ display: "none" }}
                                >
                                  <select
                                    name="menu"
                                    style={{ display: "none" }}
                                    id="secondSelector"
                                    onChange={this.handleSelectorForNewDropDown}
                                  >
                                    <option
                                      id="dropDown-necklace"
                                      value="necklace"
                                    >
                                      Necklace
                                    </option>
                                    <option
                                      id="dropDown-earring"
                                      value="earring"
                                    >
                                      Earing
                                    </option>
                                    <option
                                      id="dropDown-nosering"
                                      value="nosering"
                                    >
                                      Nose ring
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                              <div className="Btnposition">
                                <img src={homeimg} onClick={this.handleHome} className="iconwidth first-step " data-tooltip="tooltip" data-placement="right" title="Go to Homescreen " />

                                <div className="vl"></div>

                                <button
                                  className="Btn create-project"
                                  onClick={this.handleCreateButton}
                                  data-tooltip="tooltip"
                                  data-placement="right"
                                  title="Create a New Project "
                                >
                                  <img src={btnimg} className="btnicon" alt="" />
                            Create
                                </button>
                              </div>

                            </div>
                          </div>

                          {/* <button onClick={this.handleFile} id="selectBtn" style={{display:'none'}}>select file</button> */}
                          {/* <input type="file" style={{ display: 'none' }} onChange={this.handleSelect} accept=".glb" name="myfile" /> */}
                          <div className="row margintopbottom flexcolumn">
                            <div
                              className="col-12 col-md-7 col-lg-7"
                              id="productgrid"
                            >
                              <div className="gridtryon">
                                {this.state.allProjects.map(
                                  (projectItem, index) => {
                                    return (
                                      <div key={index}>
                                        <ProjectListItem
                                          projectData={projectItem}
                                          ShowProduct={this.ShowProduct}
                                          // deleteProject={this.deleteProject}
                                          actionButton={this.actionButton}
                                        // showDeletePopup={this.showDeletePopup}
                                        />
                                      </div>
                                    );
                                  },
                                )}
                              </div>
                            </div>
                            <div className="col-md-1 col-lg-1 hideshadow1">
                              <img
                                src={Logopartimg}
                                className="fullwidth hideshadow1 heightshadow rotateshadow"
                              />
                            </div>
                            <div
                              className="col-12 col-md-4 col-lg-4"
                              style={{ alignSelf: "center" }}
                            >
                              <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                  <p className="Choosetext">
                                    Choose a Project Type:
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-4 col-md-4 col-lg-4">
                                  <div
                                    className="boxproject"
                                    onClick={this.showNecklaceProject}
                                  >
                                    <img
                                      src={Necklacemg}
                                      className="widthicon"
                                    ></img>
                                    <p className="project-type">Necklace</p>
                                  </div>
                                </div>
                                <div className="col-4 col-md-4 col-lg-4">
                                  <div
                                    className="boxproject"
                                    onClick={this.showEaringProject}
                                  >
                                    <img
                                      src={Earingmg}
                                      className="widthicon"
                                    ></img>
                                    <p className="project-type">Earing</p>
                                  </div>
                                </div>
                                <div className="col-4 col-md-4 col-lg-4">
                                  <div
                                    className="boxproject"
                                    onClick={this.showNoseringProjecet}
                                  >
                                    <img
                                      src={Noseringmg}
                                      className="widthicon"
                                    ></img>
                                    <p className="project-type">Nose ring</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {this.state.isPopupOpen === true && (
                  <CreateButtonPopup
                    CreateProject={this.CreateProject}
                    projectCreateFrom="all"
                    close={this.closePopup}
                  />
                )}
                {this.state.deletePopup === true && (
                  <DeletePopoup
                    deleteProjectId={this.state.projectToDelete}
                    projectDelete={this.deleteProject}
                    closePopup={this.closeDeletePopup}
                  />
                )}
                {this.state.embedProjectpopup === true && (
                  <EmbedPopup
                    embedProjectId={this.state.projectToEmbed}
                    type={this.state.embedProjectType}
                    projectEmbed={this.embedProject}
                    closePopup={this.closeEmbedPopup}
                  />
                )}
                <div className="footer" id="Footer">
                  <footer className="footer_area">
                    <div className="container">
                      <p className="footer-text">
                        Powered by{" "}
                        <a
                          href="https://melzo.com/"
                          target="_blank"
                          className="underline"
                        >
                          melzo.com
                        </a>
                      </p>
                    </div>
                  </footer>
                </div>
                {/* <Footer /> */}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  handleHome = () => {
    window.location.href = "/home";
  }
}
