import React, { Component } from "react";
import "./project-list-page.css";
import CreateButtonPopup from "../popups/create-button-popup";
import ProjectListItem from "./project-list-item";
import Header from "../header/header";
import { createProjectLocal, guid } from "../functions/aws-services";
import "../popups/create-button-popup.css";
import { handleProjects } from "../functions/get-projects-service";
import firebaseApp from "../functions/firebase";
import { deleteProjectLocal } from "../functions/delete-project";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getFileExtension,
  uploadFiles,
  uploadGltfModel,
  uploadImageFile,
  uploadImageFileInFirebase,
} from "../functions/upload-services";
import { error } from "spritespin/release/src/utils";
import Footer from "../header/footer";
import DeletePopoup from "../popups/delete-popup";
import EmbedPopup from "../popups/embed-popup";
import SignOutPopup from "../popups/sign-out-popup";
// import createimg from "../images/create.png";
import btnimg from "../images/button.png";
import Logopartimg from "../images/Logopart.png";
// import viewimg from "../images/degree.png";
// import modelimg from "../images/3dmodel.png";
import Necklacemg from "../images/Necklace.png";
import braceletImg from "../images/bracelet.png";
import ringImg from "../images/ring.png";
import Earingmg from "../images/Earing.png";
import Noseringmg from "../images/Nosering.png";
import homeimg from '../images/home.png';

import Loader from "../loader";
// import Projecttype from "./project-type";
export var userid = "";

export class TryOn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myUid: "",
      userEmail: "",
      allProjects: [],
      allProjectsFetchStatus: false,
      isPopupOpen: false,
      projectName: "",
      projectDescription: "",
      selectedProductImage: "",
      isViewer: false,

      necklaceProjects: [],
      necklaceFetchStatus: false,
      isEditor: false,
      typevalue: "necklace",
      earringProjects: [],
      noseringProjects: [],
      ringProjects: [],
      braceletProjects: [],
      braceletFetchStatus: false,
      ringFetchStatus: false,
      earringFetchStatus: false,
      noseringFetchStatus: false,
      deletePopup: false,
      embedProjectpopup: false,
      projectToDelete: "",
      projectToEmbed: "",
      titleForDelete: "Are you sure to Delete?",
      isLogout: false,
      embedProjectType: "",
      isLoaderActive: true,
    };
  }
  componentDidMount() {
    this.getUserUid();
    this.setState({ allProjects: this.state.necklaceProjects });
    handleProjects(
      "sparkle-ar-necklace",
      this.state.necklaceProjects,
      this.state.necklaceFetchStatus,
      this.handleProjectListValuesforNecklace,
    );
    handleProjects(
      "sparkle-ar-ring",
      this.state.ringProjects,
      this.state.ringFetchStatus,
      this.handleProjectListValuesforRing,
    );
    handleProjects(
      "sparkle-ar-bracelet",
      this.state.braceletProjects,
      this.state.braceletFetchStatus,
      this.handleProjectListValuesforBracelet,
    );
    handleProjects(
      "sparkle-ar-earring",
      this.state.earringProjects,
      this.state.earringFetchStatus,
      this.handleProjectListValuesforEarring,
    );
    handleProjects(
      "sparkle-ar-nosering",
      this.state.noseringProjects,
      this.state.noseringFetchStatus,
      this.handleProjectListValuesforNosering,
    );
    document.addEventListener("scroll", (e) => this.handleScroll(e));
  }

  /**
   * this function is use for handle project list of nosering
   *
   * @param {image of nosering} NoseringArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforNosering = (
    NoseringArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ noseringProjects: NoseringArray });
    this.setState({ noseringFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });

    console.warn("nosering projects", NoseringArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for handle project list of Necklace
   *
   * @param {image of Necklace} NecklaceArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforBracelet = (
    BraceletArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ braceletProjects: BraceletArray });
    this.setState({ braceletFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });

    console.warn("bracelet projects", BraceletArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for handle project list of Necklace
   *
   * @param {image of Necklace} NecklaceArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforNecklace = (
    NecklaceArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ necklaceProjects: NecklaceArray });
    this.setState({ necklaceFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });

    console.warn("necklace projects", NecklaceArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for handle project list of Necklace
   *
   * @param {image of Necklace} RingArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforRing = (
    RingArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ ringProjects: RingArray });
    this.setState({ ringFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });

    console.warn("ring projects", RingArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for handle project list of earring
   *
   * @param {image of earring} EarringArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforEarring = (
    EarringArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ earringProjects: EarringArray });
    this.setState({ earringFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });

    console.warn("earring projects", EarringArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  getUserUid = () => {
    return new Promise((resolve, reject) => {
      firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
          // user is signed in
          resolve(user.uid);
          localStorage.setItem("uid", user.uid);
          localStorage.setItem("emailId", user.email);
          // console.warn('user uid',user.uid)
          this.setState({ userEmail: user.email });
          this.setState({ myUid: user.uid });
        } else {
          window.location.href = "/";
          // anonymous user, proceed with token as null
          resolve(null);
        }
      });
    });
  };

  handleProjectName = (e) => {
    e.preventDefault();
    this.setState({ projectName: e.target.value });
  };

  handleProjectDescription = (e) => {
    e.preventDefault();
    this.setState({ projectDescription: e.target.value });
  };

  handleCreateButton = () => {
    this.setState({ isPopupOpen: true });
    // console.warn('create button clicked ::')

    // document.getElementById('create-button-popup').style.display='block';
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  /**
   * this function is use for create a new project
   *
   * @param {project name} name
   * @param {project description} description
   * @param {project type} typevalue
   * @param {model} model
   */
  /**
   * this function is use for create a new project
   *
   * @param {project name} name
   * @param {project description} description
   * @param {project type} typevalue
   * @param {model} model
   */
  CreateProject = (name, description, typevalue, selectedFile = null) => {
    console.warn("type value is ;;", typevalue);
    createProjectLocal(name, description, typevalue).then((res) => {
      // const notificationMsg = "Project created successfully"
      // localStorage.setItem('notificationMsg', notificationMsg);
      console.warn("response ", res);
      let sceneData = res.data.scenes[0].sceneid;
      let projectId = res.data._id;
      let uid = res.data.uid;
      userid = uid;
      // console.warn('response value is', res.data);
      localStorage.setItem("sceneId", sceneData);

      uploadImageFileInFirebase(
        selectedFile,
        "widgetimage",
        uid,
        sceneData,
        projectId,
      );
      // .then(
      //     () => {
      //         alert("Uploaded Successfully")
      //         window.location.href = '/home'
      //     }
      // );
      // window.location.href = `/upload/${res.data._id}`
    });
  };

  showNecklaceProject = () => {
    this.setState({ typevalue: "necklace" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.necklaceProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  showRingProject = () => {
    this.setState({ typevalue: "ring" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.ringProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  showBraceletProject = () => {
    this.setState({ typevalue: "bracelet" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.braceletProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  showEaringProject = () => {
    this.setState({ typevalue: "earring" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.earringProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  showNoseringProjecet = () => {
    this.setState({ typevalue: "nosering" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.noseringProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  /**
   * this function is use for delete project
   *
   * @param {project id of selected project for delete} projectIdToDelete
   */
  deleteProject = (projectIdToDelete) => {
    deleteProjectLocal({
      projectid: projectIdToDelete,
    }).then((response) => {
      this.setState({ allProjects: [] });
      //   this.setState({ imageProjects: [] });
      //   this.setState({ modelProjects: [] });
      this.setState({ earringProjects: [] });
      this.setState({ noseringProjects: [] });
      this.setState({ necklaceProjects: [] });
      setTimeout(() => {
        this.getUserUid();
        this.setState({ allProjects: this.state.necklaceProjects });

        handleProjects(
          "sparkle-ar-necklace",
          this.state.necklaceProjects,
          this.state.necklaceFetchStatus,
          this.handleProjectListValuesforNecklace,
        );
        handleProjects(
          "sparkle-ar-earring",
          this.state.earringProjects,
          this.state.earringFetchStatus,
          this.handleProjectListValuesforEarring,
        );
        handleProjects(
          "sparkle-ar-ring",
          this.state.ringProjects,
          this.state.ringFetchStatus,
          this.handleProjectListValuesforRing,
        );
        handleProjects(
          "sparkle-ar-bracelet",
          this.state.braceletProjects,
          this.state.braceletFetchStatus,
          this.handleProjectListValuesforBracelet,
        );
        handleProjects(
          "sparkle-ar-nosering",
          this.state.noseringProjects,
          this.state.noseringFetchStatus,
          this.handleProjectListValuesforNosering,
        );
      }, 1);

      // this.componentDidMount();
    });
  };

  closeDeletePopup = () => {
    this.setState({ deletePopup: false });
  };
  closeEmbedPopup = () => {
    this.setState({ embedProjectpopup: false });
  };

  embedProject = (projectIdToEmbed) => {
    console.warn("embed ::", projectIdToEmbed);
  };

  /**
   * this function is use for delete or embade project
   *
   * @param {id of project} projectid
   * @param {work of function} actionToPerform
   * @param {project type} type
   */
  actionButton = (projectid, actionToPerform, type = "360") => {
    // deleteProjectLocal({
    //     projectid: projectIdToDelete
    // }).then((response) => {
    //     this.setState({ allProjects: [] });
    //     this.setState({ imageProjects: [] });
    //     this.setState({ modelProjects: [] });
    //     this.componentDidMount();
    // })
    if (actionToPerform === "delete") {
      this.setState({ projectToDelete: projectid });
      this.setState({ deletePopup: true });
    } else if (actionToPerform === "embed") {
      this.setState({ projectToEmbed: projectid });
      this.setState({ embedProjectType: type });
      this.setState({ embedProjectpopup: true });
    }
    // console.warn('project id is ::', projectid)
  };

  logOut = (msg) => {
    if (msg === "logOut") {
      // this.setState({isLogout:true})
      document.getElementById("popup-signout").style.display = "block";
    }
  };

  /**
   * this function is use for handle logout
   */
  handlelogout = () => {
    let logoutQuery = new Promise((resolve, reject) => {
      firebaseApp
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
          resolve("signout successfully");
        })
        .catch(function (error) {
          // An error happened.
          reject(error);
        });
    });
    logoutQuery
      .then((result) => {
        console.warn("logout successful", result);
        window.localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.warn(error);
      });
    this.setState({ isLogout: false });
  };

  closeLogout = () => {
    this.setState({ isLogout: false });
  };

  /**
   * this function is use for handle scroll
   *
   * @param {event object} e
   */
  handleScroll = (e) => {
    e.preventDefault();
    // console.warn('handle scroll', e.target.scrollingElement)
    const bottomScroll =
      document.documentElement.scrollTop + window.innerHeight ===
      document.documentElement.scrollHeight;
    if (bottomScroll) {
      // console.warn('Bottom reached');
      if (
        this.state.typevalue === "necklace" &&
        !this.state.necklaceFetchStatus
      ) {
        handleProjects(
          "sparkle-ar-necklace",
          this.state.necklaceProjects,
          this.state.necklaceFetchStatus,
          this.handleProjectListValuesforNecklace,
        );
      } else if (
        this.state.typevalue === "earring" &&
        !this.state.earringFetchStatus
      ) {
        handleProjects(
          "sparkle-ar-earring",
          this.state.earringProjects,
          this.state.earringFetchStatus,
          this.handleProjectListValuesforEarring,
        );
      } else if (
        this.state.typevalue === "ring" &&
        !this.state.ringFetchStatus
      ) {
        handleProjects(
          "sparkle-ar-ring",
          this.state.ringProjects,
          this.state.ringFetchStatus,
          this.handleProjectListValuesforRing,
        );
      } else if (
        this.state.typevalue === "bracelet" &&
        !this.state.braceletFetchStatus
      ) {
        handleProjects(
          "sparkle-ar-bracelet",
          this.state.braceletProjects,
          this.state.braceletFetchStatus,
          this.handleProjectListValuesforBracelet,
        );
      } else if (
        this.state.typevalue === "nosering" &&
        !this.state.noseringFetchStatus
      ) {
        handleProjects(
          "sparkle-ar-nosering",
          this.state.noseringProjects,
          this.state.noseringFetchStatus,
          this.handleProjectListValuesforNosering,
        );
      }
    }
  };

  render() {
    return (
      <div>
        {this.state.isLoaderActive && <Loader />}
        {!this.state.isLoaderActive && (
          <div>
            <div className="project-list">
              <Header
                userEmail={this.state.userEmail}
                showHomeButton={this.state.isViewer || this.state.isEditor}
                confirmLogout={this.logOut}
              />
              <div className="container-fluid">
                {/* {this.state.isViewer === true &&
                                <ProjectDisplay selectedProductImagevalue={this.state.selectedProductImage} />
                            } */}
                {this.state.isViewer === false && (
                  <>
                    <div className="margintopfive">
                      {/* <div className="row margintopbottom">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h1 className="heading animationtext" id="ProjectList">My Projects</h1>

                                                </div>

                                            </div> */}
                      <div className="row margintopbottom columnrev">
                        <div
                          className="col-12 col-md-6 col-lg-6"
                          style={{ display: "none" }}
                        >
                          <div
                            className="flexdropdown "
                            style={{ display: "none" }}
                          >
                            <label
                              htmlFor="menu"
                              className="Choosetext animationdropdown"
                              style={{ display: "none" }}
                            >
                              Choose a project type:
                            </label>

                            <div
                              className="box animationdropdown"
                              style={{ display: "none" }}
                            >
                              <select
                                name="menu"
                                id="selector"
                                onChange={this.handleSelector}
                              >
                                <option id="dropDown-360" value="360-view">
                                  360 view
                                </option>
                                <option id="dropDown-3d" value="3D-model">
                                  3D Model
                                </option>
                                <option id="dropDown-tryon" value="tryon">
                                  Tryon
                                </option>
                              </select>
                            </div>
                            <div
                              className="box animationdropdown"
                              style={{ display: "none" }}
                            >
                              <select
                                name="menu"
                                style={{ display: "none" }}
                                id="secondSelector"
                                onChange={this.handleSelectorForNewDropDown}
                              >
                                <option id="dropDown-necklace" value="necklace">
                                  Necklace
                                </option>
                                <option id="dropDown-earring" value="earring">
                                  Earing
                                </option>
                                <option id="dropDown-nosering" value="nosering">
                                  Nose ring
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                          <div className="Btnposition">
                            <img src={homeimg} onClick={this.handleHome} className="iconwidth" data-tooltip="tooltip" data-placement="right" title="Go to Homescreen " />

                            <div className="vl"></div>

                            <button
                              className="Btn"
                              onClick={this.handleCreateButton}
                              data-tooltip="tooltip"
                              data-placement="right"
                              title="Create a New Project "
                            >
                              <img src={btnimg} className="btnicon" alt="" />
                            Create
                          </button>
                          </div>

                        </div>
                      </div>

                      {/* <button onClick={this.handleFile} id="selectBtn" style={{display:'none'}}>select file</button> */}
                      {/* <input type="file" style={{ display: 'none' }} onChange={this.handleSelect} accept=".glb" name="myfile" /> */}
                      <div className="row margintopbottom flexcolumn">
                        <div
                          className="col-12 col-md-7 col-lg-7 heightprojectlist"
                          id="productgrid"
                        >
                          <div className="gridtryon">
                            {this.state.allProjects.map(
                              (projectItem, index) => {
                                return (
                                  <div key={index}>
                                    <ProjectListItem
                                      projectData={projectItem}
                                      ShowProduct={this.ShowProduct}
                                      // deleteProject={this.deleteProject}
                                      actionButton={this.actionButton}
                                    // showDeletePopup={this.showDeletePopup}
                                    />
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                        <div className="col-md-1 col-lg-1 hideshadow1">
                          <img
                            src={Logopartimg}
                            className="fullwidth hideshadow1 heightshadow rotateshadow"
                          />
                        </div>
                        <div
                          className="col-12 col-md-4 col-lg-4"
                        
                        >
                          <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                              <p className="Choosetext">
                                Choose a Project Type:
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4 col-md-4 col-lg-4">
                              <div
                                className="boxproject"
                                onClick={this.showNecklaceProject}
                              >
                                <img
                                  src={Necklacemg}
                                  className="widthicon"
                                  data-tooltip="tooltip"
                                  data-placement="right"
                                  title="Necklace"
                                ></img>
                                <p className="project-type">Necklace</p>
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4">
                              <div
                                className="boxproject"
                                onClick={this.showRingProject}
                              >
                                <img
                                  src={ringImg}
                                  className="widthicon"
                                  data-tooltip="tooltip"
                                  data-placement="right"
                                  title="Ring"
                                ></img>
                                <p className="project-type">Ring</p>
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4">
                              <div
                                className="boxproject"
                                onClick={this.showBraceletProject}
                              >
                                <img
                                  src={braceletImg}
                                  className="widthicon"
                                  data-tooltip="tooltip"
                                  data-placement="right"
                                  title="Bracelet"
                                ></img>
                                <p className="project-type">Bracelet</p>
                              </div>
                            </div>

                            <div className="col-4 col-md-4 col-lg-4">
                              <div
                                className="boxproject"
                                onClick={this.showEaringProject}
                              >
                                <img
                                  src={Earingmg}
                                  data-tooltip="tooltip"
                                  data-placement="right"
                                  title="Earing"
                                  className="widthicon"
                                ></img>
                                <p className="project-type">Earing</p>
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4">
                              <div
                                className="boxproject"
                                onClick={this.showNoseringProjecet}
                              >
                                <img
                                  src={Noseringmg}
                                  className="widthicon"
                                  data-tooltip="tooltip"
                                  data-placement="right"
                                  title="Nose ring"
                                ></img>
                                <p className="project-type">Nose ring</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {this.state.isPopupOpen === true && (
              <CreateButtonPopup
                CreateProject={this.CreateProject}
                projectCreateFrom="tryOn"
                close={this.closePopup}
              />
            )}
            {this.state.deletePopup === true && (
              <DeletePopoup
                deleteProjectId={this.state.projectToDelete}
                projectDelete={this.deleteProject}
                closePopup={this.closeDeletePopup}
              />
            )}
            {this.state.embedProjectpopup === true && (
              <EmbedPopup
                embedProjectId={this.state.projectToEmbed}
                type={this.state.embedProjectType}
                projectEmbed={this.embedProject}
                closePopup={this.closeEmbedPopup}
              />
            )}
            <Footer />
          </div>
        )}
      </div>
    );
  }
  handleHome = () => {
    window.location.href = "/home";
  }
}

export default TryOn;
