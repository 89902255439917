/* eslint-disable no-unused-vars */
import config from "./config";
import firebaseApp from "./firebase";
import mrc from "./mrc";
let sceneValue = "";

/**
 * this function is use for create new project on local
 *
 * @param {project name} projectnameObj
 * @param {project description} descriptionObj
 */
export function createProjectLocal(projectnameObj, descriptionObj, typevalue) {
  return new Promise((resolve, reject) => {
    getUserToken()
      .then((token) => {
        let projectType = "";
        if (typevalue === "360-view") {
          projectType = "sparkle-360";
        } else if (typevalue === "3D-model") {
          projectType = "sparkle-3d";
        } else if (typevalue === "necklace") {
          projectType = "sparkle-ar-necklace";
        } else if (typevalue === "earring") {
          projectType = "sparkle-ar-earring";
        } else if (typevalue === "nosering") {
          projectType = "sparkle-ar-nosering";
        } else if (typevalue === "bracelet") {
          projectType = "sparkle-ar-bracelet";
        } else if (typevalue === "ring") {
          projectType = "sparkle-ar-ring";
        }
        console.warn("project type is ::", projectType);
        const newProj = {
          projectname: projectnameObj,
          description: descriptionObj,
          planType: "FREE",
          projecttype: projectType,
          featured: false,
          scenes: [
            {
              sceneid: guid(),
              bgimage:
                "image/public/image360/abstract/15265120282_41440237cc_o.jpg",
              srcType: "bgImage",
              switchbuttons: [],
              images: [],
            },
          ],
          sound: {
            _id: guid(),
            src: "sound/public/sound-80kb.mp3",
            volume: 1,
            enable: false,
          },
        };
        postProjectNetwork(
          {
            token: token,
          },
          newProj,
        ).then(
          (response) => {
            response.self = true; // set the self property to true as this project belongs to the user.
            // projFacObj.privateProjects.push(response);
            sceneValue = response;
            // console.warn('response',sceneValue)
            resolve(response);
          },
          (error) => {
            //     message = "Error: " + error.status + " " + error.statusText;
          },
        );
      })
      .catch((err) => {});
  });
}

/**
 * this function is use for get user token
 */
export function getUserToken() {
  return new Promise((resolve, reject) => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        // user is signed in
        resolve(user.getIdToken(false));
      } else {
        // anonymous user, proceed with token as null
        resolve(null);
      }
    });
  });
}

/**
 * this function is use for post project network
 *
 * @param {object} obj
 * @param {new project} newProj
 */
export function postProjectNetwork(obj, newProj) {
  return new Promise((resolve, reject) => {
    // console.warn('post project network', obj);
    // console.warn('post project network is :: ', newProj)
    mrc
      .post(config.serverUrl + `projects`, newProj, {
        headers: {
          "x-access-token": obj.token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return String(
    s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4(),
  );
}
