/* eslint-disable */
import React, { Component } from "react";
import "./create-button-popup.css";
import { createProjectLocal } from "../functions/aws-services";
import { handleProjects } from "../functions/get-projects-service";
import btnimg from "../images/button.png";
import createimg from "../images/create.png";
import selectimg from "../images/select.png";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";

export default class CreateButtonPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: "",
      projectDescription: "",
      model: [],
      image: [],
      typeValue: "360-view",
    };
  }

  componentDidMount() {
    if (this.props.projectCreateFrom === "360View") {
      this.setState({ typeValue: "360-view" });
    } else if (this.props.projectCreateFrom === "3dModel") {
      this.setState({ typeValue: "3D-model" });
    } else if (this.props.projectCreateFrom === "tryOn") {
      this.setState({ typeValue: "tryon" });
    } else if (this.props.projectCreateFrom === "all") {
      this.setState({ typeValue: "360-view" });
    }
  }

  closeTutorialPopup = () => {
    document.getElementById("tutorial-popup").style.display = "none";
  };

  render() {
    return (
      <div>
        <div id="tutorial-popup" style={{ display: "none" }}>
          <div className="overlaypopup" onClick={this.closeTutorialPopup} />

          <ul
            className="popupboxdashboard1 "
            style={{ zIndex: "10000000", width: "250px" }}
          >
            <li>
              <span className="closepopup" onClick={this.closeTutorialPopup}>
                &times;
              </span>
            </li>
            <li>
              <p className="embedlinktext" style={{ marginTop: "20px" }}>
                For the best user experience, please upload transparent images.
              </p>
            </li>
          </ul>
        </div>

        <div id="create-button-popup">
          <div
            className="createoverlayBtn"
            id="create-overlay-btn"
            style={{ display: "none" }}
          ></div>
          <div className="overlay" onClick={this.handleCancle} />

          <ul className="popupboxdashboard ">
            <li>
              <div className="flex">
                <h4 className="popuptitle">Create New Project</h4>
                <span className="closepopup" onClick={this.handleCancle}>
                  &times;
                </span>
              </div>
            </li>
            {/* <li className="margintopbottom">
                        <h4 className="popuptitle">
                            Create New Project
                        </h4>
                    </li> */}
            <hr className="hrline"></hr>
            <li className="margintopbottom margintopextra">
              <input
                className="inputclass"
                contentEditable="true"
                type="text"
                value={this.state.projectName}
                onChange={this.handleProjectName}
                onKeyUp={this.projectNameKeyPress}
                autoFocus
                placeholder="Project Name"
              ></input>
            </li>
            <li className="margintopbottom">
              <input
                className="inputclass"
                type="text"
                contentEditable="true"
                value={this.state.projectDescription}
                onChange={this.handleProjectDescription}
                onKeyUp={this.descriptionKeyPress}
                id="description"
                placeholder="Description"
              ></input>
            </li>
            <li className="margintopbottom height">
              {this.props.projectCreateFrom !== "360View" &&
                <label htmlFor="menu" className="Choosetext1">
                  Choose a project type:
                </label>}


              <div className="flexdropdown">
                {(this.props.projectCreateFrom === "tryOn" ||
                  this.props.projectCreateFrom === "all") && (
                    <div className="box">
                      <select
                        name="menu"
                        id="secondMenu"
                        onChange={this.handleSecondDropDown}
                      >
                        <option value="necklace">Necklace</option>
                        <option value="earring">Earing</option>
                        <option value="nosering">Nose ring</option>
                        <option value="ring">Ring</option>
                        <option value="bracelet">Bracelet</option>
                      </select>
                    </div>
                  )}
                {this.props.projectCreateFrom === "all" && (
                  <div className="box">
                    <select
                      name="menu"
                      id="menu"
                      onChange={this.handleDropDown}
                    >
                      <option value="360-view">360 view</option>
                      <option value="3D-model">3D Model</option>
                      <option value="tryon">Tryon</option>
                    </select>
                  </div>
                )}
              </div>
              {this.state.typeValue === "3D-model" && (
                <div className="flexmodel">
                  <label className="custom-file-upload" id="selectBtn">
                    <input
                      type="file"
                      accept=".glb, .gltf, .usdz"
                      name="myfile"
                      onChange={this.handleSelect}
                      style={{ display: "none" }}
                    />
                    <img src={btnimg} className="btnicon" />
                    Select Model
                  </label>
                  <div id="selected-modelName" className="modelnametext" />
                </div>
              )}

              {this.state.typeValue === "tryon" && (
                <div className="flexmodel">
                  <label className="custom-file-upload" id="imgSelectBtn">
                    <input
                      type="file"
                      accept="image/*"
                      name="myfile"
                      onChange={this.selectImage}
                      style={{ display: "none" }}
                    />
                    <img src={btnimg} className="btnicon" />
                    Select Image
                  </label>
                  <div id="selected-imageName" className="modelnametext" />
                </div>
              )}

              {/* <input className="custom-file-input" type="file" id="selectBtn" contentEditable="true" style={{ display: 'none' }} onChange={this.handleSelect} accept=".glb" name="myfile" /> */}
            </li>
            <li className="margintopbottom">
              <div className="flexbtn">
                <button
                  className="Btn marginrightcancel"
                  onClick={this.handleCancle}
                >
                  <img
                    src={btnimg}
                    className="btnicon"
                    alt=""
                    data-tooltip="tooltip"
                    data-placement="right"
                    title="Cancel"
                  />
                  Cancel
                </button>
                <button
                  className="Btn"
                  onClick={this.handleCreate}
                  id="create-btn-popup"
                >
                  <img
                    src={btnimg}
                    className="btnicon"
                    data-tooltip="tooltip"
                    data-placement="right"
                    title="Create"
                    alt=""
                  />
                  Create
                </button>
                {/* <button className="sparklebtn marginrightcancel" onClick={this.handleCancle}  >
                  <span>
                    <img src={cancelimg} className="btnicon" alt="" />
                    Cancel
                                                        </span>
                </button>
                <button className="sparklebtn" onClick={this.handleCreate}  id="create-btn-popup">
                  <span>
                    <img src={createimg} className="btnicon" alt="" />
                                                        Create
                  </span>
                </button>
             */}
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  /**
   * this function is use for handle select value from dropdown of 3d model
   *
   * @param {event object} e
   */
  handleSelect = (e) => {
    var target = e.currentTarget;
    var file = target.files[0];

    if (target.files && file) {
      console.warn("file is ", file);
      let tempArray = this.state.model;
      document.getElementById("selected-modelName").innerHTML = file.name;
      tempArray.push(file);
      this.setState({ model: tempArray });
    }
  };

  selectImage = (e) => {
    var target = e.currentTarget;
    var file = target.files[0];
    if (file.name && (file.name.split('.')[1] == 'png' || file.name.split('.')[1] == 'jpg' || file.name.split('.')[1] == 'jpeg')) {
      if (target.files && file) {
        console.warn("file is ", file);
        let tempArray = this.state.image;
        document.getElementById("selected-imageName").innerHTML = file.name;
        tempArray.push(file);
        this.setState({ image: tempArray });
      }
    }
    else {
      alert("This program only supports .png, .jpg and .jpeg files.")
    }
  };

  /**
   * this function is use for get selected value of project type
   *
   * @param {event object} e
   */
  handleDropDown = (e) => {
    let myvalue = document.getElementById("secondMenu").value;

    if (
      (myvalue === "necklace" ||
        myvalue === "earring" ||
        myvalue === "ring" ||
        myvalue === "bracelet" ||
        myvalue === "nosering") &&
      document.getElementById("menu").value === "tryon"
    ) {
      document.getElementById("tutorial-popup").style.display = "block";
    }
    this.setState(
      {
        typeValue: document.getElementById("menu").value,
      },
      () => console.warn("typevalue is ;:", this.state.typeValue),
    );
  };

  projectNameKeyPress = (e) => {
    e = e || window.event;
    if (e.keyCode === 13) {
      document.getElementById("description").focus();
    }
  };
  descriptionKeyPress = (e) => {
    e = e || window.event;
    if (e.keyCode === 13) {
      this.handleCreate();
    }
  };
  handleCancle = () => {
    this.props.close();
  };

  hideLoaderAndShowButton = () => {
    document.getElementById("create-overlay-btn").style.display = "none";
    // document.getElementById("loader-createpopup").style.display = "none";
    // document.getElementById("create-name").style.display = "block";
  };

  /**
   * this function is use for create project button trigger
   */
  handleCreate = () => {
    // document.getElementById("loader-createpopup").style.display = "block";
    document.getElementById("create-overlay-btn").style.display = "block";
    // document.getElementById("create-name").style.display = "none";
    document.getElementById("create-btn-popup").setAttribute("disabled", "true");
    let projectname = this.state.projectName;
    let description = this.state.projectDescription;
    let model = this.state.model;
    let image = this.state.image;
    this.setState({
      typeValue: this.state.typeValue,
    });
    if (projectname !== "") {
      if (this.state.typeValue === "360-view") {

        this.props.CreateProject(
          projectname,
          description,
          this.state.typeValue,
        );
      } else if (
        this.state.typeValue === "tryon" &&
        document.getElementById("secondMenu").value === "necklace"
      ) {
        if (image.length === 0) {
          alert("Please select an image to upload");
          this.hideLoaderAndShowButton();
        } else {
          this.props.CreateProject(projectname, description, "necklace", image);
        }
      } else if (
        this.state.typeValue === "tryon" &&
        document.getElementById("secondMenu").value === "earring"
      ) {
        if (image.length === 0) {
          alert("Please select an image to upload");
          this.hideLoaderAndShowButton();
        } else {
          this.props.CreateProject(projectname, description, "earring", image);
        }
      } else if (
        this.state.typeValue === "tryon" &&
        document.getElementById("secondMenu").value === "nosering"
      ) {
        if (image.length === 0) {
          alert("Please select an image to upload");
          this.hideLoaderAndShowButton();
        } else {
          this.props.CreateProject(projectname, description, "nosering", image);
        }
      } else if (
        this.state.typeValue === "tryon" &&
        document.getElementById("secondMenu").value === "ring"
      ) {
        if (image.length === 0) {
          alert("Please select an image to upload");
          this.hideLoaderAndShowButton();
        } else {
          this.props.CreateProject(projectname, description, "ring", image);
        }
      } else if (
        this.state.typeValue === "tryon" &&
        document.getElementById("secondMenu").value === "bracelet"
      ) {
        if (image.length === 0) {
          alert("Please select an image to upload");
          this.hideLoaderAndShowButton();
        } else {
          this.props.CreateProject(projectname, description, "bracelet", image);
        }
      } else if (this.state.typeValue === "3D-model") {
        if (model.length === 0) {
          alert("Please select a model to upload");
          this.hideLoaderAndShowButton();
        } else {
          console.warn("model ::", model);
          this.props.CreateProject(
            projectname,
            description,
            this.state.typeValue,
            model,
          );
        }
      }
    } else {
      alert("Please enter project name");
      // document.getElementById("loader-createpopup").style.display = "none";
      document.getElementById("create-overlay-btn").style.display = "none";
      // document.getElementById("create-name").style.display = "block";
    }
  };

  handleProjectName = (e) => {
    e.preventDefault();
    this.setState({ projectName: e.target.value });
  };

  handleProjectDescription = (e) => {
    e.preventDefault();
    this.setState({ projectDescription: e.target.value });
  };
}
