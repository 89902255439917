import React, { Component } from 'react'
import './footer.css'
import logoimg from '../images/JEWEL.png';
import shadowimg from '../images/part.png';
import btnimg from '../images/button.png';

export default class Footer extends Component {
    componentDidMount() {
        // this.PageResizeListener();
    }
    /**
     * this function is use for page resize when keyboard open in mobile view
     */
    // PageResizeListener = () => {
    //     var initialScreenSize = window.innerHeight;
    //     window.addEventListener("resize", function () {
    //         if (window.innerHeight < initialScreenSize) {
    //             if (document.getElementById("Footer")) {
    //                 document.getElementById("Footer").style.display = "none";
    //             }
    //         } else {
    //             if (document.getElementById("Footer")) {
    //                 document.getElementById("Footer").style.display = "flex";
    //             }


    //         }
    //     });
    // }
    render() {
        return (
            <div>
                {/* <img src={shadowimg} className="shadowpositionfooter"></img> */}
                <p className="footer-text footertextposition" >Powered by <a href="https://melzo.com/"
                    target="_blank" className="underline">melzo.com</a></p>
                <img src={logoimg} className="iconwidthlogo footerlogoposition" />

                <ul className="flexfooter" id="Footer">
                  
                    <li >

                      
                    </li>
                </ul>
            </div>
        )
    }
}
