import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import RouterContainier from './components/router-container/router-container';
// import Sortable from './components/editer/sortable';

const history = createBrowserHistory();
export default class App extends Component {
  render() {
    return (
      <div>
        <Router history={history}>
        <RouterContainier  history={history}/>
      </Router>
       
      </div>
    )
  }
}

