import React, { Component } from "react";
import "./project-list-page.css";
import CreateButtonPopup from "../popups/create-button-popup";
import ProjectListItem from "./project-list-item";
import Header from "../header/header";
import { createProjectLocal } from "../functions/aws-services";
import "../popups/create-button-popup.css";
import { handleProjects } from "../functions/get-projects-service";
import firebaseApp from "../functions/firebase";
import { deleteProjectLocal } from "../functions/delete-project";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  uploadImageFileInFirebase,
} from "../functions/upload-services";
import Footer from "../header/footer";
import DeletePopoup from "../popups/delete-popup";
import EmbedPopup from "../popups/embed-popup";
// import createimg from "../images/create.png";
import btnimg from "../images/button.png";
import Logopartimg from "../images/Logopart.png";
// import viewimg from "../images/degree.png";
// import modelimg from "../images/3dmodel.png";
import faceTryonImg from "../images/face-tryon.png";
import handTryonImg from "../images/hand-tryon.png";
import homeimg from '../images/home.png';

import Loader from "../loader";
// import Projecttype from "./project-type";
export var userid = "";

export class TryOnCatalogue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myUid: "",
      userEmail: "",
      allProjects: [],
      allProjectsFetchStatus: false,
      isPopupOpen: false,
      projectName: "",
      projectDescription: "",
      selectedProductImage: "",
      isViewer: false,

      faceProjects: [],
      faceFetchStatus: false,
      isEditor: false,
      typevalue: "face",
      handProjects: [],
      braceletProjects: [],
      braceletFetchStatus: false,
      handFetchStatus: false,
      deletePopup: false,
      embedProjectpopup: false,
      projectToDelete: "",
      projectToEmbed: "",
      titleForDelete: "Are you sure to Delete?",
      isLogout: false,
      embedProjectType: "",
      isLoaderActive: true,
    };
  }
  componentDidMount() {
    this.getUserUid();
    this.setState({ allProjects: this.state.faceProjects });
    handleProjects(
      "face-tryon",
      this.state.faceProjects,
      this.state.faceFetchStatus,
      this.handleProjectListValuesforFaceTryon,
    );
    handleProjects(
      "hand-tryon",
      this.state.handProjects,
      this.state.handFetchStatus,
      this.handleProjectListValuesforHandTryon,
    );

    document.addEventListener("scroll", (e) => this.handleScroll(e));
  }

  /**
   * this function is use for handle project list of Necklace
   *
   * @param {image of Necklace} NecklaceArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforFaceTryon = (
    faceTryonArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ faceProjects: faceTryonArray });
    this.setState({ faceFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });

    console.warn("face projects", faceTryonArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  /**
   * this function is use for handle project list of Hand Try on
   *
   * @param {image of Necklace} FaceTryonArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforHandTryon = (
    handTryonArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ handProjects: handTryonArray });
    this.setState({ handFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false });

    console.warn("hand projects", handTryonArray.length);
    // console.warn('badhi vastu', modelArray)
  };

  getUserUid = () => {
    return new Promise((resolve, reject) => {
      firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
          // user is signed in
          resolve(user.uid);
          localStorage.setItem("uid", user.uid);
          localStorage.setItem("emailId", user.email);
          // console.warn('user uid',user.uid)
          this.setState({ userEmail: user.email });
          this.setState({ myUid: user.uid });
        } else {
          window.location.href = "/";
          // anonymous user, proceed with token as null
          resolve(null);
        }
      });
    });
  };

  handleProjectName = (e) => {
    e.preventDefault();
    this.setState({ projectName: e.target.value });
  };

  handleProjectDescription = (e) => {
    e.preventDefault();
    this.setState({ projectDescription: e.target.value });
  };

  handleCreateButton = () => {
    this.setState({ isPopupOpen: true });
    // console.warn('create button clicked ::')

    // document.getElementById('create-button-popup').style.display='block';
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  /**
   * this function is use for create a new project
   *
   * @param {project name} name
   * @param {project description} description
   * @param {project type} typevalue
   * @param {model} model
   */
  CreateProject = (name, description, typevalue, selectedFile = null) => {
    console.warn("type value is ;;", typevalue);
    createProjectLocal(name, description, typevalue).then((res) => {
      // const notificationMsg = "Project created successfully"
      // localStorage.setItem('notificationMsg', notificationMsg);
      console.warn("response ", res);
      let sceneData = res.data.scenes[0].sceneid;
      let projectId = res.data._id;
      let uid = res.data.uid;
      userid = uid;
      // console.warn('response value is', res.data);
      localStorage.setItem("sceneId", sceneData);

      uploadImageFileInFirebase(
        selectedFile,
        "widgetimage",
        uid,
        sceneData,
        projectId,
      );
      // .then(
      //     () => {
      //         alert("Uploaded Successfully")
      //         window.location.href = '/home'
      //     }
      // );
      // window.location.href = `/upload/${res.data._id}`
    });
  };

  showFaceTryonProject = () => {
    this.setState({ typevalue: "face" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.faceProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  showHandTryonProject = () => {
    this.setState({ typevalue: "hand" });
    this.setState({ allProjects: [] });
    // console.warn('Should display images', this.state.imageProjects);
    let myTemp = this.state.handProjects;
    setTimeout(() => {
      this.setState({ allProjects: myTemp });
    }, 1);
  };

  /**
   * this function is use for delete project
   *
   * @param {project id of selected project for delete} projectIdToDelete
   */
  deleteProject = (projectIdToDelete) => {
    deleteProjectLocal({
      projectid: projectIdToDelete,
    }).then((response) => {
      this.setState({ allProjects: [] });
      //   this.setState({ imageProjects: [] });
      //   this.setState({ modelProjects: [] });
      this.setState({ faceProjects: [] });
      setTimeout(() => {
        this.getUserUid();
        this.setState({ allProjects: this.state.faceProjects });

        handleProjects(
          "face-tryon",
          this.state.faceProjects,
          this.state.faceFetchStatus,
          this.handleProjectListValuesforFaceTryon,
        );

        handleProjects(
          "hand-tryon",
          this.state.handProjects,
          this.state.handFetchStatus,
          this.handleProjectListValuesforHandTryon,
        );

      }, 1);

      // this.componentDidMount();
    });
  };

  closeDeletePopup = () => {
    this.setState({ deletePopup: false });
  };
  closeEmbedPopup = () => {
    this.setState({ embedProjectpopup: false });
  };

  embedProject = (projectIdToEmbed) => {
    console.warn("embed ::", projectIdToEmbed);
  };

  /**
   * this function is use for delete or embade project
   *
   * @param {id of project} projectid
   * @param {work of function} actionToPerform
   * @param {project type} type
   */
  actionButton = (projectid, actionToPerform, type = "360") => {
    // deleteProjectLocal({
    //     projectid: projectIdToDelete
    // }).then((response) => {
    //     this.setState({ allProjects: [] });
    //     this.setState({ imageProjects: [] });
    //     this.setState({ modelProjects: [] });
    //     this.componentDidMount();
    // })
    if (actionToPerform === "delete") {
      this.setState({ projectToDelete: projectid });
      this.setState({ deletePopup: true });
    } else if (actionToPerform === "embed") {
      this.setState({ projectToEmbed: projectid });
      this.setState({ embedProjectType: type });
      this.setState({ embedProjectpopup: true });
    }
    // console.warn('project id is ::', projectid)
  };

  logOut = (msg) => {
    if (msg === "logOut") {
      // this.setState({isLogout:true})
      document.getElementById("popup-signout").style.display = "block";
    }
  };

  /**
   * this function is use for handle logout
   */
  handlelogout = () => {
    let logoutQuery = new Promise((resolve, reject) => {
      firebaseApp
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
          resolve("signout successfully");
        })
        .catch(function (error) {
          // An error happened.
          reject(error);
        });
    });
    logoutQuery
      .then((result) => {
        console.warn("logout successful", result);
        window.localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.warn(error);
      });
    this.setState({ isLogout: false });
  };

  closeLogout = () => {
    this.setState({ isLogout: false });
  };

  /**
   * this function is use for handle scroll
   *
   * @param {event object} e
   */
  handleScroll = (e) => {
    e.preventDefault();
    // console.warn('handle scroll', e.target.scrollingElement)
    const bottomScroll =
      document.documentElement.scrollTop + window.innerHeight ===
      document.documentElement.scrollHeight;
    if (bottomScroll) {
      // console.warn('Bottom reached');
      if (
        this.state.typevalue === "face" &&
        !this.state.faceFetchStatus
      ) {
        handleProjects(
          "face-tryon",
          this.state.faceProjects,
          this.state.faceFetchStatus,
          this.handleProjectListValuesforFaceTryon,
        );
      } else if (
        this.state.typevalue === "hand" &&
        !this.state.handFetchStatus
      ) {
        handleProjects(
          "hand-tryon",
          this.state.handProjects,
          this.state.handFetchStatus,
          this.handleProjectListValuesforHandTryon,
        );
      }
    }
  };

  onHover = () => {
    console.warn('hover')

  }

  render() {
    return (
      <div>
        {this.state.isLoaderActive && <Loader />}
        {!this.state.isLoaderActive && (
          <div>
            <div className="project-list">
              <Header
                userEmail={this.state.userEmail}
                showHomeButton={this.state.isViewer || this.state.isEditor}
                confirmLogout={this.logOut}
              />
              <div className="container-fluid">
                {/* {this.state.isViewer === true &&
                                <ProjectDisplay selectedProductImagevalue={this.state.selectedProductImage} />
                            } */}
                {this.state.isViewer === false && (
                  <>
                    <div className="margintopfive">

                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="Btnposition">
                          <img src={homeimg} onClick={this.handleHome} className="iconwidth" data-tooltip="tooltip" data-placement="right" title="Go to Homescreen " />

                          <div className="vl"></div>

                          <button
                            className="Btn"
                            onClick={this.handleCreateButton}
                            data-tooltip="tooltip"
                            data-placement="right"
                            title="Create a New Project "
                          >
                            <img src={btnimg} className="btnicon" alt="" />
                            Create
                          </button>
                        </div>

                      </div>
                    </div>

                    {/* <button onClick={this.handleFile} id="selectBtn" style={{display:'none'}}>select file</button> */}
                    {/* <input type="file" style={{ display: 'none' }} onChange={this.handleSelect} accept=".glb" name="myfile" /> */}
                    <div className="row margintopbottom flexcolumn">
                      <div
                        className="col-12 col-md-7 col-lg-7 "
                        id="productgrid"
                      >
                        <div className="heightprojectlist">

                          <div className="gridtryon">
                            {this.state.allProjects.map(
                              (projectItem, index) => {
                                return (
                                  <div key={index}>
                                    <ProjectListItem
                                      projectData={projectItem}
                                      onHover={this.onHover}
                                      ShowProduct={this.ShowProduct}
                                      actionButton={this.actionButton}
                                    />
                                  </div>
                                );
                              },
                            )}
                          </div>
                        
                        
                        </div></div>
                        <div className="col-md-1 col-lg-1 hideshadow1">
                          <img
                            src={Logopartimg}
                            className="fullwidth hideshadow1 heightshadow rotateshadow"
                            alt="logo"
                          />
                        </div>
                        <div
                          className="col-12 col-md-4 col-lg-4"

                        >
                          <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                              <p className="Choosetext">
                                Choose a Project Type:
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4 col-md-4 col-lg-4 faceTry">
                              <div
                                className="boxproject"
                                onClick={this.showFaceTryonProject}
                              >
                                <img
                                  src={faceTryonImg}
                                  className="widthicon"
                                  data-tooltip="tooltip"
                                  data-placement="right"
                                  title="Necklace"
                                  alt="face-tryon"
                                ></img>
                                <p className="project-type">Face</p>
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4 handTry">
                              <div
                                className="boxproject"
                                onClick={this.showHandTryonProject}
                              >
                                <img
                                  src={handTryonImg}
                                  className="widthicon"
                                  data-tooltip="tooltip"
                                  data-placement="right"
                                  title="Hand Tryon"
                                  alt="hand-tryon"
                                ></img>
                                <p className="project-type">Hand</p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                  </>
                )}
              </div>
            </div>
              {this.state.isPopupOpen === true && (
                <CreateButtonPopup
                  CreateProject={this.CreateProject}
                  projectCreateFrom="tryOn"
                  close={this.closePopup}
                />
              )}
              {this.state.deletePopup === true && (
                <DeletePopoup
                  deleteProjectId={this.state.projectToDelete}
                  projectDelete={this.deleteProject}
                  closePopup={this.closeDeletePopup}
                />
              )}
              {this.state.embedProjectpopup === true && (
                <EmbedPopup
                  embedProjectId={this.state.projectToEmbed}
                  type={this.state.embedProjectType}
                  projectEmbed={this.embedProject}
                  closePopup={this.closeEmbedPopup}
                />
              )}
              <Footer />
            </div>
        )}
          </div>
        );
  }
  handleHome = () => {
          window.location.href = "/home";
  }
}

export default TryOnCatalogue;
