import React from 'react';
import './FaceTryOn.css';
import necklacePng from './image/faceImages/necklace.png';
import earingPng from './image/faceImages/earing.png';
import noseringPng from './image/faceImages/nosering.png';
import eyewearPng from './image/faceImages/eyewear.png';
import necklacePng1 from './image/faceImages/necklace1.png';
import earingPng1 from './image/faceImages/earing1.png';
import noseringPng1 from './image/faceImages/nosering1.png';
import eyewearPng1 from './image/faceImages/eyewear1.png';
import newFaceWebPng from './image/newFaceWeb.png';
import downloadPng from './image/faceImages/download.png';
import whatsappPng from './image/faceImages/whatsapp.png';
import menuPng from './image/faceImages/menu.png';
import selfie2Png from './image/faceImages/selfie2.png';
import cameraSwitchPng from './image/faceImages/cameraSwitch.png';
import livetryonPng from './image/faceImages/livetryon.png';
import uploadPhotoPng from './image/faceImages/uploadPhoto.png';
import tryitonmodelPng from './image/faceImages/try it on model.png';
import clothPng from './image/cloth.png';
import takepicturePng from './image/faceImages/take picture.png';
import howtousePng from './image/faceImages/how to use.png';
import model1Png from './image/faceImages/model1.png';
import model1fullPng from './image/faceImages/model1full.png';
import model2Png from './image/faceImages/model2.png';
import model2fullPng from './image/faceImages/model2full.png';
import model3Png from './image/faceImages/model3.png';
import model3fullPng from './image/faceImages/model3full.png';
import model4Png from './image/faceImages/model4.png';
import model4fullPng from './image/faceImages/model4full.png';
import model5Png from './image/faceImages/model5.png';
import model5fullPng from './image/faceImages/model5full.png';
import model6Png from './image/faceImages/model6.png';
import model6fullPng from './image/faceImages/model6full.png';
import transparentPng from './image/transparent.png';
import singledownloadcolorPng from './image/faceImages/singledownloadcolor.png';
import facebookBlack1Png from './image/faceImages/facebook-black1.png';
import whatsappBlack1Png from './image/faceImages/whatsapp-black1.png';
import multipledownloadPng from './image/faceImages/multipledownload.png';
import demoAlertAud from './image/faceImages/demoAlert.mp3'
import { errorLog, userLog } from "../functions/useLogs";

import Tour from 'reactour';

import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-cpu';
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import * as tf from '@tensorflow/tfjs-core';
import Stats from 'stats.js';
import axios from 'axios';
import html2canvas from 'html2canvas';
import CryptoJS from 'crypto-js';
import { CallToActionSharp } from '@material-ui/icons';
import baseUrls from '../functions/config';

const GREEN = '#32EEDB';
let stopRendering = false;


let model, ctx, videoWidth, videoHeight, video, canvas;


let uid, finalProjectId, projectName;
let cal_usage = 0;
let necklace = false;
let earring = false;
let nosering = false;

let necklaceSelected = "";
let earringSelected = "";
let noseringSelected = "";
let eyewearSelected = "";
let isPayAsYouGo = false;

let necklaceCount = false;
let noseringCount = false;
let earringCount = false;
let eyewearCount = false;

let ornamentImageElement1;
let imgCtxGlass;

const stats = new Stats();
const state = {
    backend: 'webgl',
    maxFaces: 1,
    triangulateMesh: true,
    predictIrises: true
};

let playSound = !1;

let leftEarringx = 0;
let leftEarringy = 0;

let rightEarringx = 0;
let rightEarringy = 0;

let necklashx = 0;
let necklashy = 0;

let noseRingx = 0;
let noseRingy = 0;

let glassx = 0;
let glassy = 0;

let selfieIndex = 1;

class FaceTryOn extends React.Component {

    constructor() {
        super();
        this.state = {
            itemId: "",
            isSecoundTimeLoad: false,
            isUserCamero: true,
            mobile: false,
            renderPointcloud: false,
            isVideo: true,
            streamImage: "",
            imageUrl: '',
            isLoading: true,
            ismultipleCamera: false,

            passwordProtected: "",
            password: "",

            steps1: [],
            isTourOpen: false,

            nameInq: "",
            emailInq: "",
            contactnoInq: "",
            companynameInq: "",
            cityInq: "",
            enquiryMail: "",

            cameraId: "",
            cameraList: "",

            isModelPopUp: false,
            isClothPopup: false,
            isCameraNotFound: false,

            uid: "",
            finalProjectId: "",
            projectName: "",

            isCameraLoading: false,

            imageIndex: "",
        }
    }

    // create tour and get project data
    componentDidMount() {
        try {
            const params = new URLSearchParams(window.location.search);
            // let { projectid, itemId } = this.props.match.params;
            let projectid = params.get("p");
            let itemId = params.get("q");

            if (this.isIOS()) {
                let tmpLink = `/faceios/?p=${projectid}`
                if (itemId) {
                    tmpLink = tmpLink + `&q=${itemId}`
                }
                window.location.href = tmpLink;
            } else {
                navigator.mediaDevices.enumerateDevices().then(this.gotDevices);
                let demoArray = [];
                demoArray = [{
                    selector: '#lblNecklace',
                    content: 'Try on Necklace designs here.',
                }, {
                    selector: '#lblEaring',
                    content: 'Try on Earring designs here.',
                }, {
                    selector: '#lblNoseRing',
                    content: 'Try on Noserings designs here.',
                }, {
                    selector: '#lblEyeWear',
                    content: 'Try on Eye-Wear designs here.',
                }, {
                    selector: '#uploadImage',
                    content: 'Try on an existing picture.',
                }, {
                    selector: '#tryItonModel',
                    content: 'Look at the trial on a model.',
                }, {
                    selector: '#tackPicture',
                    content: 'Take a picture with your Jewels.',
                }, {
                    selector: '#howtouse',
                    content: 'Get a quick Recap.',
                }]

                this.setState({
                    steps1: demoArray
                })

                setTimeout(() => {
                    if (document.getElementById("neck"))
                        document.getElementById("neck").classList.add("overflowcolumn");
                }, 1000);
                setTimeout(() => {
                    if (document.getElementById("Earing"))
                        document.getElementById("Earing").classList.add("overflowcolumn");
                }, 1000);
                setTimeout(() => {
                    if (document.getElementById("nose"))
                        document.getElementById("nose").classList.add("overflowcolumn");
                }, 1000);
                setTimeout(() => {
                    if (document.getElementById("eyeWear"))
                        document.getElementById("eyeWear").classList.add("overflowcolumn");
                }, 1000);


                if (itemId) {
                    this.setState({ itemId: itemId })
                }

                axios.get("projects/" + projectid, {
                    headers: {
                        "x-access-token": "null",
                    },
                    baseURL: "https://ptpvr.com",
                }).then((res) => {
                    let project = res.data;
                    uid = project.uid;
                    finalProjectId = project._id;
                    projectName = project.projectname;
                    this.setState({
                        uid: project.uid,
                        finalProjectId: project._id,
                        projectName: project.projectname,
                    })

                    axios.post('https://ptpvr.com/paidview/isviewvalid', {
                        product: 'Noor',
                        uid: uid
                    }).then(resview => {
                        isPayAsYouGo = resview.data.isPayAsYouGo;
                        if (resview.data.isAllowed) {
                            axios.put(`https://ptpvr.com/projects/updateview/${project._id}`, {
                                views: project.views + 1,
                            }).then((response) => {
                                if (resview.data.isPayAsYouGo) {
                                    this.postSession(uid);
                                }
                            }).catch(err => {
                                errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "updateview service ", err, "");
                            })
                            document.getElementById("projectName").innerHTML = project.projectname;

                            project.scenes[0].images.forEach((faceProjectImage) => {
                                let imageurl = faceProjectImage.src.startsWith("https") ? faceProjectImage.src : baseUrls.images + faceProjectImage.src;
                                this.formatAllImages(
                                    faceProjectImage.type,
                                    imageurl,
                                    faceProjectImage.name,
                                    faceProjectImage._id
                                );
                                cal_usage += faceProjectImage.size;

                            });

                            let final_usage = cal_usage / 1024
                            this.calculateUsage(final_usage);

                            axios.get(`https://ptpvr.com/projects/${uid}/Noor`).then((res) => {
                                if (res.data.storeLogoUrl && !isPayAsYouGo) {
                                    document.getElementById("clientLogo").src = res.data.storeLogoUrl.includes("https") == false ? baseUrls.images + res.data.storeLogoUrl : res.data.storeLogoUrl;
                                } else {
                                    if (isPayAsYouGo) {
                                        document.getElementById("clientLogo").src = '../image/color.png';
                                    }
                                }
                            }).catch((err) => {
                                errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "/Noor service ", err, "");
                            })


                            if (project.isPasswordProtected) {
                                this.setState({ passwordProtected: project.password }, () => {
                                    document.getElementById('passwordProtection').style.display = 'block';
                                })
                            }
                            if (project.isEnquiryEnabled) {
                                this.setState({
                                    enquiryMail: project.enquiryMail,
                                })
                            }

                            let stylechange = 100 / (necklaceCount + noseringCount + earringCount + eyewearCount);

                            var ele = document.getElementsByClassName('slider');
                            for (var i = 0; i < ele.length; i++) {
                                ele[i].style.width = stylechange + '%';
                            }

                            var list = document.getElementById("category");
                            var tabs = document.getElementById("tabs");
                            var imageList = document.getElementById("imageList");

                            // NecklaceImages
                            // EaringImages
                            // NoseRingImages
                            // EyeWearImages

                            if (!eyewearCount) {
                                document.getElementById('lblEyeWear').style.display = 'none';
                                list.removeChild(document.getElementById('lblEyeWear'));
                                tabs.removeChild(document.getElementById('tab4'));
                                imageList.removeChild(document.getElementById('EyeWearImages'));
                            } else {
                                document.getElementById('lblEyeWearlbl').click();
                            }

                            if (!noseringCount) {
                                document.getElementById('lblNoseRing').style.display = 'none';
                                list.removeChild(document.getElementById('lblNoseRing'));
                                tabs.removeChild(document.getElementById('tab3'));
                                imageList.removeChild(document.getElementById('NoseRingImages'));
                            } else {
                                document.getElementById('lblNoseRinglbl').click();
                            }

                            if (!earringCount) {
                                document.getElementById('lblEaring').style.display = 'none';
                                list.removeChild(document.getElementById('lblEaring'));
                                tabs.removeChild(document.getElementById('tab2'));
                                imageList.removeChild(document.getElementById('EaringImages'));
                            } else {
                                document.getElementById('lblEaringlbl').click();
                            }

                            if (!necklaceCount) {
                                document.getElementById('lblNecklace').style.display = 'none';
                                list.removeChild(document.getElementById('lblNecklace'));
                                tabs.removeChild(document.getElementById('tab1'));
                                imageList.removeChild(document.getElementById('NecklaceImages'));
                            } else {
                                document.getElementById('lblNecklacelbl').click();
                            }

                            document.getElementById('loder').style.display = "none";
                            document.getElementById('cameraLoading').style.display = "block";
                            userLog("Ar Face", uid, finalProjectId, projectName);
                        } else {
                            window.location.href = "/warning"
                        }
                    }).catch(err => {
                        console.error('error', err)
                    })
                }).catch((err) => {
                    errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "Project Load ", err, "");
                });

                setTimeout(() => {
                    this.setState({
                        mobile: this.isMobile()
                    }, () => {
                        this.setState({
                            renderPointcloud: (this.state.mobile === false)
                        }, () => {
                            this.main();
                        })
                    })
                }, 2000);
            }
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "componentDidMount ", err, "");
        }
    }

    //session service call to update product session
    postSession = (uid) => {
        axios.post("https://ptpvr.com/usage/session", {
            uid: uid,
            product: "noor",
        }).then((response) => {
        }).catch(err => {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "PostSession", err, "");
        })
    }

    //how much data user user service call
    calculateUsage = (usage) => {
        // usage in MB
        axios.post("https://ptpvr.com/usage", {
            uid: uid,
            usage: usage,
            product: 'noor'
        }).then((result) => {
        }).catch(err => {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "calculateUsage", err, "");
        });
    }

    // get a multiple camera if is access
    gotDevices = (mediaDevices) => {
        try {
            let cameraListTmp = [];
            mediaDevices.forEach((mediaDevice) => {
                if (mediaDevice.kind === "videoinput") {
                    cameraListTmp.push(mediaDevice);
                }
            });

            if (cameraListTmp.length > 1) {
                this.setState({ ismultipleCamera: true }, () => {
                    document.getElementById("btn-switch-camera").style.display = "flex";
                })
            } else {
                this.setState({ ismultipleCamera: false }, () => {
                    document.getElementById("btn-switch-camera").style.display = "none";
                })
            }

            if (!cameraListTmp[0]) {
                this.setState({ isCameraNotFound: true })
            } else {
                this.setState({
                    cameraList: cameraListTmp,
                    cameraId: cameraListTmp[0].deviceId
                })
            }
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "gotDevices", err, "")
        }
    }

    // model try on into model load then model draw in canvas and create stream
    handleOnLoad = (imageTmp) => {
        try {
            let canvasTmp = document.getElementById('modelTryOnCanvas');
            let tmpCtx = canvasTmp.getContext("2d");
            canvasTmp.width = document.getElementById("faceData").width;
            canvasTmp.height = document.getElementById("faceData").height;
            tmpCtx.setTransform(-1.0, 0, 0, 1, document.getElementById("faceData").width, 0);
            tmpCtx.drawImage(document.getElementById("faceData"), 0, 0, document.getElementById("faceData").width, document.getElementById("faceData").height);

            const videoOutputStream = canvasTmp.captureStream();
            this.setState({ streamImage: videoOutputStream }, () => {
                this.main();
            });
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "handleOnLoad ", err, "");
        }
    }

    // image type wise create image
    formatAllImages = (type, imageSrc, imageName, objId) => {
        try {
            let firstNecklaceImgSrc = "";
            let firstEarringImgSrc = "";
            let firstNoseringImgSrc = "";
            let firstEyewearImgSrc = "";

            let isNecklaceIdGet = false;
            let isEarringIdGet = false;
            let isNoseringIdGet = false;
            let iseyewearIdGet = false;

            if (type === "necklace") {
                if (firstNecklaceImgSrc === "" && imageSrc !== "") {
                    firstNecklaceImgSrc = imageSrc;
                }
                necklaceCount = true;
                this.setImageToContainer(imageSrc, "Necklace", imageName, objId);
                document.getElementById("lblNecklace").style.display = "block";
            }
            if (type === "earring") {
                if (firstEarringImgSrc === "" && imageSrc !== "") {
                    firstEarringImgSrc = imageSrc;
                }
                earringCount = true;
                this.setImageToContainer(imageSrc, "Earing", imageName, objId);
                document.getElementById("lblEaring").style.display = "block";
            }
            if (type === "nosering") {
                if (firstNoseringImgSrc === "" && imageSrc !== "") {
                    firstNoseringImgSrc = imageSrc;
                }
                noseringCount = true;
                this.setImageToContainer(imageSrc, "Nosering", imageName, objId);
                document.getElementById("lblNoseRing").style.display = "block";
            }

            if (type.toLowerCase() === "eyewear") {
                if (firstEyewearImgSrc === "" && imageSrc !== "") {
                    firstEyewearImgSrc = imageSrc;
                }
                eyewearCount = true;
                this.setImageToContainer(imageSrc, "Eyewear", imageName, objId);
                document.getElementById("lblEyeWear").style.display = "block";
            }

            if (this.state.itemId && objId == this.state.itemId) {
                if (type === "necklace") {
                    isNecklaceIdGet = true;
                    firstNecklaceImgSrc = imageSrc;
                }
                if (type === "earring") {
                    isEarringIdGet = true;
                    firstEarringImgSrc = imageSrc;
                }
                if (type === "nosering") {
                    isNoseringIdGet = true;
                    firstNoseringImgSrc = imageSrc;
                }
                if (type.toLowerCase() === "eyewear") {
                    iseyewearIdGet = true;
                    firstEyewearImgSrc = imageSrc;
                }
            }

            setTimeout(() => {
                this.wearDefaultOrnament(
                    firstNecklaceImgSrc,
                    firstEarringImgSrc,
                    firstNoseringImgSrc,
                    firstEyewearImgSrc,
                    isNecklaceIdGet,
                    isEarringIdGet,
                    isNoseringIdGet,
                    iseyewearIdGet
                );
            }, 2500);
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "Load Models ", err, "");
        }
    }

    // Image create in side tab
    setImageToContainer = (imageSource, imageContainer, imageName, objId) => {
        try {
            // let imageElement = document.createElement("img");
            let imageElement = new Image();
            let imageLblName = document.createElement("label");
            let divElement = document.createElement("div");
            // imageElement.setAttribute("crossOrigin", "anonymous");
            imageElement.crossOrigin = "anonymous";
            imageElement.setAttribute("id", "image" + imageSource);
            imageLblName.innerHTML = imageName != undefined ? imageName : "";
            imageLblName.setAttribute("class", "createdLabel");
            divElement.setAttribute("id", "div" + imageSource);
            imageElement.setAttribute("alt", objId);

            divElement.addEventListener("click", function (event) {
                let previousImage = document.getElementById(imageContainer + "ToTry").src;
                if (previousImage === imageSource) {
                    if (imageContainer === "Necklace") {
                        document.getElementById(necklaceSelected).classList.remove("boxShadow");
                        necklaceSelected = "";
                    }
                    if (imageContainer === "Earing") {
                        document.getElementById(earringSelected).classList.remove("boxShadow");
                        earringSelected = "";
                    }
                    if (imageContainer === "Nosering") {
                        document.getElementById(noseringSelected).classList.remove("boxShadow");
                        noseringSelected = "";
                    }
                    if (imageContainer === "Eyewear") {
                        document.getElementById(eyewearSelected).classList.remove("boxShadow");
                        eyewearSelected = "";
                    }
                    document.getElementById(imageContainer + "ToTry").setAttribute("src", transparentPng);
                } else {
                    if (imageContainer === "Necklace") {
                        necklaceSelected = "div" + imageSource;
                    }
                    if (imageContainer === "Earing") {
                        earringSelected = "div" + imageSource;
                    }
                    if (imageContainer === "Nosering") {
                        noseringSelected = "div" + imageSource;
                    }
                    if (imageContainer === "Eyewear") {
                        eyewearSelected = "div" + imageSource;
                    }

                    document.getElementById(imageContainer + "ToTry").setAttribute("src", imageSource);
                }

                // if (document.getElementById("buythisproduct").style.display == "block") {
                //   document.getElementById("buythisproductLink").href =
                //     "https://shop.melzo.com/product/" + event.target.alt + "/" + uid;
                // } else {
                //   document.getElementById("buythisproductLink").href = "";
                // }
                refreshContainer(document.getElementById(imageContainer + "Image"));
                // previousMagnitude = 0.0;
                // magnitudeChange = 0.0;

                function refreshContainer(containerElement) {

                    let images = containerElement.getElementsByClassName("imgwidth");
                    for (let i = 0; i < images.length; i++) {
                        images[i].parentNode.classList.remove("boxShadow");
                    }
                    if (necklaceSelected !== "" && document.getElementById(necklaceSelected)) {
                        document.getElementById(necklaceSelected).classList.add("boxShadow");
                    }
                    if (earringSelected !== "" && document.getElementById(earringSelected)) {
                        document.getElementById(earringSelected).classList.add("boxShadow");
                    }
                    if (noseringSelected !== "" && document.getElementById(noseringSelected)) {
                        document.getElementById(noseringSelected).classList.add("boxShadow");
                    }
                    if (eyewearSelected !== "" && document.getElementById(eyewearSelected)) {
                        document.getElementById(eyewearSelected).classList.add("boxShadow");
                    }
                }

            });
            imageElement.setAttribute("class", "imgwidth");
            divElement.setAttribute("class", "boximg");

            imageElement.setAttribute("src", imageSource);
            divElement.appendChild(imageElement);
            divElement.appendChild(imageLblName);
            document.getElementById(imageContainer + "Image").appendChild(divElement);
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "Load Models ", err, "");
        }
    }

    //Selected image into add css
    refreshContainer = (containerElement) => {
        try {
            let images = containerElement.getElementsByClassName("imgwidth");
            for (let i = 0; i < images.length; i++) {
                images[i].parentNode.classList.remove("boxShadow");
            }
            if (necklaceSelected !== "" && document.getElementById(necklaceSelected)) {
                document.getElementById(necklaceSelected).classList.add("boxShadow");
            }
            if (earringSelected !== "" && document.getElementById(earringSelected)) {
                document.getElementById(earringSelected).classList.add("boxShadow");
            }
            if (noseringSelected !== "" && document.getElementById(noseringSelected)) {
                document.getElementById(noseringSelected).classList.add("boxShadow");
            }
            if (eyewearSelected !== "" && document.getElementById(eyewearSelected)) {
                document.getElementById(eyewearSelected).classList.add("boxShadow");
            }
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "Load Models ", err, "");
        }
    }

    // Earring flip for draw in canvas
    flipEarringImage = () => {
        try {
            var c = document.getElementById("flipedEarring");
            var img = document.getElementById("EaringToTry");
            c.width = img.width;
            c.height = img.height;
            var ctx = c.getContext("2d");
            ctx.setTransform(-1.0, 0, 0, 1, img.width, 0);
            ctx.drawImage(img, 0, 0, img.width, img.height);

            let canvas = document.getElementById("flipedEarring");
            let Flipedimg = canvas.toDataURL("image/png");
            document.getElementById("EaringFlipedToTry").src = Flipedimg;
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "FlipEarring Image", err, "");
        }
    }

    // default model select
    wearDefaultOrnament = (
        firstNecklace,
        firstEarring,
        firstNosering,
        firstEyewear,
        isNecklaceIdGet,
        isEarringIdGet,
        isNoseringIdGet,
        iseyewearIdGet
    ) => {
        try {
            if ((necklaceSelected === "" && firstNecklace !== "") || isNecklaceIdGet) {
                if (document.getElementById("image" + firstNecklace)) {
                    necklaceSelected = "div" + firstNecklace;
                    document.getElementById("NecklaceToTry").setAttribute("src", firstNecklace);
                    this.refreshContainer(document.getElementById("NecklaceImage"));
                }
            }
            if ((earringSelected === "" && firstEarring !== "") || isEarringIdGet) {
                if (document.getElementById("image" + firstEarring)) {
                    earringSelected = "div" + firstEarring;
                    document.getElementById("EaringToTry").setAttribute("src", firstEarring);
                    this.refreshContainer(document.getElementById("EaringImage"));
                }
            }
            if ((noseringSelected === "" && firstNosering !== "") || isNoseringIdGet) {
                if (document.getElementById("image" + firstNosering)) {
                    noseringSelected = "div" + firstNosering;
                    document.getElementById("NoseringToTry").setAttribute("src", firstNosering);
                    this.refreshContainer(document.getElementById("NoseringImage"));
                }
            }

            if ((eyewearSelected === "" && firstEyewear !== "") || iseyewearIdGet) {
                if (document.getElementById("image" + firstEyewear)) {
                    eyewearSelected = "div" + firstEyewear;
                    document.getElementById("EyewearToTry").setAttribute("src", firstEyewear);
                    this.refreshContainer(document.getElementById("EyewearImage"));
                }
            }
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "Load Models ", err, "");
        }
    }

    // get is mobie device or not
    isMobile = () => {
        const isAndroid = /Android/i.test(navigator.userAgent);
        const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        return isAndroid || isiOS;
    }

    // get a is ios Device
    isIOS = () => {
        let iOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
        let isSafari = /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator
            .userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent));
        return isSafari || iOS;
    }

    // get a two points distance
    distance = (a, b) => {
        return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2));
    }

    // for use draw lines in face
    drawPath(ctx, points, closePath) {
        const region = new Path2D();
        region.moveTo(points[0][0], points[0][1]);
        for (let i = 1; i < points.length; i++) {
            const point = points[i];
            region.lineTo(point[0], point[1]);
        }

        if (closePath) {
            region.closePath();
        }
        ctx.stroke(region);
    }

    // Set Camera for get points
    setupCamera = async () => {
        video = document.getElementById('video');
        document.getElementById('cameraLoading').style.display = "block";
        try {
            let stream;
            if (this.state.isVideo) {
                if (this.state.mobile == true) {
                    stream = await navigator.mediaDevices.getUserMedia({
                        'audio': false,
                        'video': {
                            facingMode: this.state.isUserCamero ? 'user' : {
                                exact: "environment",
                            },
                            width: 640,
                            height: 480,
                        },
                    });
                } else {
                    stream = await navigator.mediaDevices.getUserMedia({
                        'video':
                        {
                            deviceId: this.state.cameraId,
                            width: 640,
                            height: 480,
                        },
                    });
                }
            } else {
                stream = this.state.streamImage;
            }
            video.srcObject = stream;

            return new Promise((resolve) => {
                video.onloadedmetadata = () => {
                    resolve(video);
                };
            });
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "Switch Camera ", err, "");
            this.setState({ isCameraNotFound: true })
        }
    }

    // Draw Image in canvas like necklace,earring,nosering,glass
    renderPrediction = async () => {
        try {
            if (!this.state.isCameraLoading) {
                return;
            }

            if (stopRendering) {
                return;
            }

            if (video.srcObject == null) {
                return;
            }

            stats.begin();
            document.getElementById('cameraLoading').style.display = "none";

            const predictions = await model.estimateFaces({
                input: video,
                returnTensors: false,
                flipHorizontal: false,
                predictIrises: state.predictIrises
            });
            ctx.drawImage(video, 0, 0, videoWidth, videoHeight, 0, 0, canvas.width, canvas.height);

            if (localStorage.getItem('faceHint') == null) {
                this.setState({ isTourOpen: true })
            }

            if (predictions.length > 0) {
                document.getElementById("overlayShade").style.display = "none";
                playSound = !1;
                predictions.forEach(prediction => {
                    const keypoints = prediction.scaledMesh;
                    ctx.fillStyle = GREEN;


                    let xRtoLEye = (keypoints[390][0] - keypoints[163][0]);
                    let yRtoLEye = (keypoints[390][1] - keypoints[163][1]);

                    let xNosetoMouth = ((keypoints[0][0] - keypoints[10][0]) / 2);
                    let yNosetoMouth = ((keypoints[0][1] - keypoints[10][1]) / 2);

                    let distanceEye = Math.sqrt(Math.pow(xRtoLEye, 2) + Math.pow(yRtoLEye, 2));
                    let distanceNtoM = Math.sqrt(Math.pow(xNosetoMouth, 2) + Math.pow(yNosetoMouth, 2));

                    // Nose Point
                    const x1 = keypoints[220][0];
                    const y1 = keypoints[220][1];


                    if (noseRingx > x1) {
                        if (noseRingx - x1 > 5) {
                            noseRingx = x1;
                        }
                    } else if (noseRingx < x1) {
                        if (noseRingx - x1 < -5) {
                            noseRingx = x1;
                        }
                    }

                    if (noseRingy > y1) {
                        if (noseRingy - y1 > 5) {
                            noseRingy = y1;
                        }
                    } else if (noseRingy < y1) {
                        if (noseRingy - y1 < -5) {
                            noseRingy = y1;
                        }
                    }

                    ctx.drawImage(
                        document.getElementById("NoseringToTry"),
                        noseRingx - 15,
                        noseRingy - (document.getElementById("NoseringToTry").height / 20),
                        distanceNtoM / 2.5,
                        ((distanceNtoM / 3) *
                            document.getElementById("NoseringToTry").height) /
                        document.getElementById("NoseringToTry").width
                    );

                    let noseToRightEarring = (keypoints[1][0] - keypoints[132][0]);

                    //Right Earring Point
                    if (noseToRightEarring > 35) {
                        const x2 = keypoints[132][0];
                        const y2 = keypoints[132][1];


                        if (rightEarringx > x2) {
                            if (rightEarringx - x2 > 3) {
                                rightEarringx = x2;
                            }
                        } else if (rightEarringx < x2) {
                            if (rightEarringx - x2 < -3) {
                                rightEarringx = x2;
                            }
                        }

                        if (rightEarringy > y2) {
                            if (rightEarringy - y2 > 5) {
                                rightEarringy = y2;
                            }
                        } else if (rightEarringy < y2) {
                            if (rightEarringy - y2 < -5) {
                                rightEarringy = y2;
                            }
                        }

                        ctx.drawImage(
                            document.getElementById("EaringToTry"),
                            // rightEarringx - (distanceNtoM / 3),
                            rightEarringx - 12,
                            rightEarringy - 10,
                            distanceNtoM / 3.8,
                            ((distanceNtoM / 4) * document.getElementById("EaringFlipedToTry").height) / document.getElementById("EaringFlipedToTry").width
                        );
                    }
                    //Left Earring Point

                    let noseToLeftEarring = (keypoints[1][0] - keypoints[361][0]);

                    if (noseToLeftEarring < -30) {
                        const x3 = keypoints[361][0];
                        const y3 = keypoints[361][1];

                        if (leftEarringx > x3) {
                            if (leftEarringx - x3 > 3) {
                                leftEarringx = x3;
                            }
                        } else if (leftEarringx < x3) {
                            if (leftEarringx - x3 < -3) {
                                leftEarringx = x3;
                            }
                        }

                        if (leftEarringy > y3) {
                            if (leftEarringy - y3 > 5) {
                                leftEarringy = y3;
                            }
                        } else if (leftEarringy < y3) {
                            if (leftEarringy - y3 < -5) {
                                leftEarringy = y3;
                            }
                        }

                        ctx.drawImage(
                            document.getElementById("EaringFlipedToTry"),
                            leftEarringx,
                            leftEarringy - 10,
                            distanceNtoM / 3.8,
                            ((distanceNtoM / 4) * document.getElementById("EaringFlipedToTry").height) / document.getElementById("EaringFlipedToTry").width
                        );
                    }

                    //Neck Point
                    const x4 = keypoints[152][0];
                    const y4 = keypoints[152][1];

                    if (necklashx > x4) {
                        if (necklashx - x4 > 13) {
                            necklashx = x4;
                        }
                    } else if (necklashx < x4) {
                        if (necklashx - x4 < -13) {
                            necklashx = x4;
                        }
                    }

                    if (necklashy > y4) {
                        if (necklashy - y4 > 5) {
                            necklashy = y4;
                        }
                    } else if (necklashy < y4) {
                        if (necklashy - y4 < -5) {
                            necklashy = y4;
                        }
                    }

                    ctx.drawImage(
                        document.getElementById("NecklaceToTry"),
                        necklashx - (distanceEye - (distanceEye * 0.1)),
                        necklashy,
                        distanceEye * 1.9,
                        (distanceEye * (12 / 7) *
                            document.getElementById("NecklaceToTry").height) / document.getElementById("NecklaceToTry").width
                    );

                    var wrist = keypoints[152];

                    let xWrist = wrist[0];
                    let yWrist = wrist[1];

                    let xF9 = keypoints[163][0];
                    let yF9 = keypoints[163][1];


                    let x9to0 = xF9 - xWrist;
                    let y9to0 = yF9 - yWrist;

                    let radians = Math.PI / 2 - Math.atan2(-y9to0, x9to0);


                    this.drawGlass(keypoints[163], distanceEye, distanceNtoM, radians);

                });
            } else {
                document.getElementById("overlayShade").style.display = "flex";
                if (!playSound) {
                    document.getElementById('audio-sound').muted = false;
                    document.getElementById('audio-sound').play();
                    playSound = !0
                }
                imgCtxGlass.clearRect(0, 0, ornamentImageElement1.width, ornamentImageElement1.height);
            }

            stats.end();
            requestAnimationFrame(this.renderPrediction);

        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "renderPrediction ", err, "");
        }
    };

    // Draw glass
    drawGlass = (landmarkPoints, disEye, disNtoM, rotate) => {
        try {
            imgCtxGlass.save();
            imgCtxGlass.clearRect(0, 0, ornamentImageElement1.width, ornamentImageElement1.height);

            if (glassx > landmarkPoints[0]) {
                if (glassx - landmarkPoints[0] > 5) {
                    glassx = landmarkPoints[0];
                }
            } else if (glassx < landmarkPoints[0]) {
                if (glassx - landmarkPoints[0] < -5) {
                    glassx = landmarkPoints[0];
                }
            }

            if (glassy > landmarkPoints[1]) {
                if (glassy - landmarkPoints[1] > 5) {
                    glassy = landmarkPoints[1];
                }
            } else if (glassy < landmarkPoints[1]) {
                if (glassy - landmarkPoints[1] < -5) {
                    glassy = landmarkPoints[1];
                }
            }

            glassy = (glassy - (disNtoM / 2));

            imgCtxGlass.translate(glassx, glassy);

            imgCtxGlass.rotate(rotate + 0.35);

            imgCtxGlass.translate(-glassx, -glassy);


            imgCtxGlass.drawImage(
                document.getElementById("EyewearToTry"),
                glassx - (window.innerWidth > 700 ? (disEye / 2.25) : (disEye / 2.75)),
                glassy,
                disEye * (window.innerWidth > 700 ? (12 / 6.5) : (12 / 7)),
                (disEye * (window.innerWidth > 700 ? (12 / 6.7) : (12 / 7)) * document.getElementById("EyewearToTry").height) / document.getElementById("EyewearToTry").width
            );
            imgCtxGlass.restore();
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "drawGlass", err, "");
        }
    }

    // this is main function to intigrate tfjs library
    main = async () => {
        try {

            await tf.setBackend(state.backend);

            await this.setupCamera();
            video.play();
            videoWidth = video.videoWidth;
            videoHeight = video.videoHeight;
            video.width = videoWidth;
            video.height = videoHeight;

            canvas = document.getElementById('output');

            ornamentImageElement1 = document.getElementById("out_imageGlass");

            ornamentImageElement1.width = videoWidth;
            ornamentImageElement1.height = videoHeight;
            canvas.width = videoWidth;
            canvas.height = videoHeight;

            ctx = canvas.getContext('2d');
            if (this.state.isUserCamero && this.state.isVideo == true) {
                document.getElementById('output').style.transform = 'scaleX(-1)';
                document.getElementById('out_imageGlass').style.transform = 'scaleX(-1)';
            } else {
                document.getElementById('output').style.removeProperty("transform");
                document.getElementById('out_imageGlass').style.removeProperty("transform");
            }
            ctx.fillStyle = GREEN;
            ctx.strokeStyle = GREEN;
            ctx.lineWidth = 0.5;

            imgCtxGlass = ornamentImageElement1.getContext("2d");
            imgCtxGlass.fillStyle = GREEN;
            imgCtxGlass.strokeStyle = GREEN;
            imgCtxGlass.lineWidth = 0.5;

            model = await faceLandmarksDetection.load(faceLandmarksDetection.SupportedPackages.mediapipeFacemesh, { maxFaces: 1 });

            this.setState({ isCameraLoading: true }, () => {
                this.renderPrediction();
            })
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "main", err, "");
        }
    };

    // open try on model popup
    tryItonModel = () => {
        this.setState({ isModelPopUp: true });
    }

    // Open Model Cloth Popup
    changeCloth = () => {
        this.setState({ isClothPopup: true })
    }

    // model and Upload image click event
    imageClick = (imageUrl, imageIndex) => {

        try {

            document.getElementById('cameraLoading').style.display = "block";
            this.setState({
                isVideo: false,
                imageUrl: imageUrl,
                isCameraLoading: false,
                imageIndex: imageIndex,
            }, () => {
                if (video.srcObject != null) {
                    video.srcObject.getTracks().forEach(function (t) {
                        t.stop();
                    })
                }
                video.srcObject = null;

                document.getElementById("btn-live-tryon").style.display = "flex";

                if (this.state.ismultipleCamera)
                    document.getElementById("btn-switch-camera").style.display = "none";

                document.getElementById("_canvas_container").classList.add("_canvas_container_upload");
                document.getElementById("output").classList.remove("_imageData");
                document.getElementById("output").classList.remove("_imagePhoto");
                document.getElementById("output").classList.remove("absolutePosition");
                document.getElementById("output").classList.add("_imageUploadPhoto");
                document.getElementById("output").classList.add("absolutePositionUpload");

                document.getElementById("out_imageGlass").classList.remove("_imageData");
                document.getElementById("out_imageGlass").classList.remove("_imagePhoto");
                document.getElementById("out_imageGlass").classList.remove("absolutePosition");
                document.getElementById("out_imageGlass").classList.add("_imageUploadPhoto");
                document.getElementById("out_imageGlass").classList.add("absolutePositionUpload");


                document.getElementById("photoUpload").style.background = `url(${this.state.imageUrl})`;

                this.setState({ isModelPopUp: false })
            })
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "imageClick", err, "")
        }
    }

    // Camera Start click event
    camerastart = () => {
        try {
            if (video.srcObject != null) {
                video.srcObject.getTracks().forEach(function (t) {
                    t.stop();
                })
            }
            video.srcObject = null;
            this.setState({
                isVideo: true,
                imageUrl: '',
                isCameraLoading: false,
            }, () => {
                document.getElementById("btn-live-tryon").style.display = "none";
                document.getElementById('cameraLoading').style.display = "block";

                if (this.state.ismultipleCamera)
                    document.getElementById("btn-switch-camera").style.display = "flex";

                document.getElementById("_canvas_container").classList.remove("_canvas_container_upload");
                document.getElementById("output").classList.add("_imageData");
                document.getElementById("output").classList.add("absolutePosition");
                document.getElementById("output").classList.remove("_imagePhoto");
                document.getElementById("output").classList.remove("_imageUploadPhoto");
                document.getElementById("output").classList.remove("absolutePositionUpload");

                document.getElementById("out_imageGlass").classList.add("_imageData");
                document.getElementById("out_imageGlass").classList.add("absolutePosition");
                document.getElementById("out_imageGlass").classList.remove("_imagePhoto");
                document.getElementById("out_imageGlass").classList.remove("_imageUploadPhoto");
                document.getElementById("out_imageGlass").classList.remove("absolutePositionUpload");

                document.getElementById("photoUpload").style.background = `url(#)`;
                document.getElementById("file").value = "";
                this.main();
            })
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "camerastart", err, "")
        }
    }

    // Download All Capture Images
    downloadAllPic = () => {
        try {
            let totalNumberOfImg = selfieIndex;
            for (let index = 1; index < totalNumberOfImg; index++) {
                let str = "capturedSelfieImageel" + index;
                this.downloadURI(str);
            }
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "downloadAllPic", err, "")
        }
    }

    // Download particular Capture Image
    downloadURI = (id) => {
        try {
            let filename = 'MelzoLiveTryOn';
            var link = document.createElement("a");
            link.download = filename;
            link.href = document.getElementById(id).src;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // delete link;
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "downloadURI", err, "")
        }
    }

    // Share All Capture Image in Whatsapp
    shareAllPic = () => {

        try {
            let totalNumberOfImg = selfieIndex;
            let fileObjArr = [];
            for (let index = 1; index < totalNumberOfImg; index++) {
                let str = "capturedSelfieImageel" + index;

                let imguri = document.getElementById(str);

                let uri = imguri.src;
                let blob = this.dataURItoBlob(uri);

                let resultFile = new File([blob], "melzolivetryon.png");
                fileObjArr.push(resultFile);

            }
            this.uploadFiles(fileObjArr, 'widgetimage').then(urls => {

                let msg = 'Here is your all saved images: ';
                urls.forEach((url, index) => {
                    msg = msg + ` ${index + 1}. ` + url;
                })
                window.open(`https://api.whatsapp.com/send/?phone=&text=${msg}&source=&data=`, "_blank");
            })
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "shareAllPic", err, "")
        }

    }

    // Share Particular Capture Image on Whatsapp
    sharePicSingle = (id) => {
        try {
            let imguri = document.getElementById(id);

            let uri = imguri.src;
            let blob = this.dataURItoBlob(uri);

            let resultFile = new File([blob], "melzolivetryon.png");
            this.uploadFiles([resultFile], 'widgetimage').then(urls => {
                let socialicons = document.getElementsByClassName(id);

                const shareImageUrl = urls[0].replace('https://pptvr-images.s3.ap-south-1.amazonaws.com/', baseUrls.images)
                imguri.setAttribute('generatedUrl', shareImageUrl);
                for (let index = 0; index < socialicons.length; index++) {
                    socialicons[index].style.display = 'block';
                }

            })
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "sharePic", err, "")
        }
    }

    // Download Particular Capture Image
    dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        try {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {
                type: mimeString
            });
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "dataURItoBlob", err, "")
            return null;
        }
    }

    // Upload Capture image in server
    uploadFiles = (files, widgetType) => {
        try {

            const thiswidgetType = widgetType;
            if (!files || !widgetType) {
                Promise.reject();
            }
            const resources = [];
            const currentGuid = this.guid();
            for (let index = 0; index < files.length; index += 1) {
                resources.push({
                    file: files[index],
                    widgetType: thiswidgetType,
                    filename: currentGuid +
                        "." +
                        this.getFileExtension(files[index]),
                });
            }
            let filePath =
                "image/private/widgetimage/";
            filePath =
                filePath + currentGuid + "." + this.getFileExtension(files[0]);

            const promises = resources.map(this.uploadResToS3);
            return Promise.all(promises);
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "uploadFiles", err, "")
            return null;
        }
    }

    // Create GUID for upload Image
    guid = () => {
        try {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return String(s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4(),);


        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "guid", err, "")
            return null;
        }
    }

    // Upload Image in S3 Server
    uploadResToS3 = (resource) => {
        try {

            const widgetsFirebaseListener = ["widgetvideo", "video360", "image360"];
            const urlData = {
                restype: "image",
                widgettype: resource.widgetType,
                filename: resource.filename,
            };

            return new Promise((resolve, reject) => {

                this.getAWSSignedUrl(
                    urlData
                ).then((response) => {
                    axios.put(response.data.url, resource.file, {
                        headers: {
                            "Content-Type": resource.file.type !== "" ?
                                resource.file.type : "application/octet-stream",
                            "Cache-Control": "public, max-age=172800",
                        },
                    }).then((data) => {
                        let url = data.config.url.substr(0, data.config.url.indexOf("?"));
                        resource.file.result = url;
                        resolve(url);

                    }).catch((err) => {
                        reject(err);
                    });
                });

            });

        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "uploadResToS3", err, "")
            return null;
        }
    }

    // Get AWS Image url
    getAWSSignedUrl = (urlData) => {
        try {
            return new Promise((resolve, reject) => {
                axios.post('https://ptpvr.com/' + `upload/getSignedUrlWithoutToken`, urlData).then((res) => {
                    resolve(res);
                }).catch((err) => {
                    errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "getAwssignedUrl ", err, "")
                });
            });
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "getAWSSignedUrl", err, "")
            return null;
        }
    }

    // Get Uploaded Image Extension
    getFileExtension = (file) => {
        try {
            const arr = file.name.split(".");
            if (arr && arr.length > 0) {
                return arr[arr.length - 1];
            }
            return "";
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "getFileExtension", err, "")
        }
    }

    // Open Catlog Menu
    Openmenu = () => {
        try {

            document.getElementById("ImagePanelselfie").classList.remove("imagepanelactive");

            document.getElementById("Menu_layer").classList.add("menuactive");
            document.getElementById("ImagePanel").classList.add("imagepanelactive");


            document.getElementById("selfie-gallery").classList.remove("ishidden");
            document.getElementById("menu").classList.add("ishidden");
            document.getElementById("menutext").classList.add("ishidden");
            document.getElementById("selfietext").classList.remove("ishidden");

            document.getElementById('countSelfie').style.display = 'flex'

            setTimeout(() => {
                document.getElementById('NecklaceImage').classList.add('moveanimation');
                document.getElementById('NecklaceImage').classList.remove('removeanimation');
                setTimeout(() => {
                    document.getElementById('NecklaceImage').classList.add('removeanimation');
                    document.getElementById('NecklaceImage').classList.remove('moveanimation');
                }, 1000);
            }, 1000);
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "Openmenu", err, "")
        }
    }

    // Open Selfie Menu
    selfiegallery = () => {
        try {
            document.getElementById("ImagePanel").classList.remove("imagepanelactive");

            document.getElementById("ImagePanelselfie").classList.add("imagepanelactive");

            document.getElementById("selfie-gallery").classList.add("ishidden");
            document.getElementById("menu").classList.remove("ishidden");
            document.getElementById("menutext").classList.remove("ishidden");
            document.getElementById("selfietext").classList.add("ishidden");

            document.getElementById('countSelfie').style.display = 'none'
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "selfiegallery", err, "")
        }
    }

    // Switch Camera Button Event
    switchCamera = () => {

        try {
            if (video.srcObject != null) {
                video.srcObject.getTracks().forEach(function (t) {
                    t.stop();
                })
            }
            video.srcObject = null;

            this.setState({ isCameraLoading: false, }, () => {

                document.getElementById('cameraLoading').style.display = "block";
                let isSet = true;

                if (this.state.mobile == false) {
                    if (this.state.cameraList.length > 1) {
                        this.state.cameraList.map(obj => {
                            if (obj.deviceId != this.state.cameraId && isSet == true) {
                                this.setState({ cameraId: obj.deviceId }, () => {
                                    isSet = false;
                                    this.main();
                                });
                            }
                        })
                    }
                }
                else {
                    this.setState({
                        isUserCamero: !this.state.isUserCamero
                    }, () => {
                        this.main();
                    })
                }
            })
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "switchCamera", err, "")
        }
    }

    // Open File menu To Upload Image
    uploadimage = () => {
        try {
            document.getElementById("file").click();
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "uploadimage", err, "")
        }
    }

    // Capture Image Button
    captureScreen = () => {
        try {

            const self = this;

            html2canvas(document.getElementById('_canvas_container')).then(function (canvas) {


                let clientWaterMark = document.getElementById('clientLogo');
                let dupCanvas = canvas;
                let ctx = dupCanvas.getContext("2d");

                let clientLogoWidth = window.innerWidth > 700 ? clientWaterMark.width + (clientWaterMark.width * 0.15) : clientWaterMark.width - (clientWaterMark.width * 0.25);
                let clientLogoHeight = window.innerWidth > 700 ? clientWaterMark.height : clientWaterMark.height - (clientWaterMark.height * 0.05);

                let clientLogoX = window.innerWidth > 700 ? (window.innerWidth - (clientWaterMark.width + 20)) : (window.innerWidth - (clientWaterMark.width));
                let clientLogoY = 10;

                ctx.drawImage(clientWaterMark, clientLogoX, clientLogoY, clientLogoWidth, clientLogoHeight);
                if (isPayAsYouGo) {
                    const lblPowerdWidth = window.innerWidth > 700 ? 230 : 200;
                    const lblPowerdHeight = window.innerWidth > 700 ? 10 : 19;
                    const lblPowerdY = window.innerWidth > 700 ? dupCanvas.height - 5 : window.innerHeight - (window.innerHeight * 0.24);
                    const lblPowerdX = window.innerWidth > 700 ? (window.innerWidth / 1.75) : (window.innerWidth / 3)
                    ctx.fillStyle = "#47E5AE";
                    ctx.font = window.innerWidth > 700 ? 'bold 18px verdana, sans-serif' : 'bold 15px verdana, sans-serif';
                    ctx.fillText('Powered by melzo.com', lblPowerdX, lblPowerdY, lblPowerdWidth, lblPowerdHeight);
                }

                const canvasWidth = window.innerWidth > 700 ? window.innerWidth - (window.innerWidth * 0.35) : window.innerWidth;
                const canvasHeight = window.innerWidth > 700 ? window.innerHeight : window.innerHeight - (window.innerHeight * 0.35)

                self.resizeImage(dupCanvas.toDataURL("image/png"), canvasWidth, canvasHeight).then(resolve => {

                    let img = resolve;
                    let filename = 'MelzoLiveTryOn';
                    let imgel = document.createElement("img");
                    imgel.src = img;
                    imgel.id = "capturedSelfieImageel" + selfieIndex;

                    imgel.style.padding = '2px';
                    imgel.className = "imageImgSelfie";

                    let imgcontainer = document.getElementById("Selfie__Container");

                    let div = document.createElement('div');
                    div.className = "containerImgSelfie";
                    div.appendChild(imgel);

                    let middleDiv = document.createElement('div');
                    middleDiv.className = "middle";

                    let graylayerDiv = document.createElement('div');
                    graylayerDiv.className = "graylayer";

                    let dwnldbtn = document.createElement('img');
                    dwnldbtn.addEventListener('click', () => {
                        self.downloadURI(imgel.id);
                    })

                    dwnldbtn.style.margin = 'auto 10px auto auto';
                    dwnldbtn.className = "downloadbutton";
                    dwnldbtn.src = singledownloadcolorPng;
                    dwnldbtn.style.marginRight = "10px";

                    // for fb icon
                    let fbbtn = document.createElement('img');
                    fbbtn.addEventListener('click', () => {
                        self.fbPic(imgel.id);
                    })
                    fbbtn.style.margin = 'auto 10px auto auto';
                    fbbtn.src = facebookBlack1Png;
                    fbbtn.className = "buttonFB";
                    fbbtn.style.display = 'block';

                    // for wp icon
                    let wpbtn = document.createElement('img');
                    wpbtn.addEventListener('click', () => {
                        self.wpPic(imgel.id);
                    })
                    wpbtn.style.margin = 'auto 10px auto auto';
                    wpbtn.src = whatsappBlack1Png;
                    wpbtn.className = "buttonWP";
                    wpbtn.style.display = 'block';

                    middleDiv.appendChild(dwnldbtn);
                    middleDiv.appendChild(fbbtn);
                    middleDiv.appendChild(wpbtn);
                    div.appendChild(middleDiv);
                    div.appendChild(graylayerDiv);

                    imgcontainer.appendChild(div);

                    if (document.getElementById('toolTip1').style.display == 'none') {
                        document.getElementById('selfie-gallery').click();
                    }
                    document.getElementById('toolTip1').style.display = "flex";
                    document.getElementById('notification-box').style.display = "block";
                    setTimeout(() => {
                        document.getElementById('notification-box').style.display = "none";
                    }, 1500);

                    document.getElementById('finalMenu').style.display = 'flex';
                    // countSelfie++;
                    document.getElementById('countSelfie').innerHTML = selfieIndex;
                    selfieIndex++;

                })
            });
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "addToSelfieGallery", err, "")
        }
    }

    resizeImage = (base64Str, maxWidth, maxHeight) => {
        return new Promise((resolve) => {
            let img = new Image()
            img.src = base64Str
            img.onload = () => {
                let canvas = document.createElement('canvas')
                const MAX_WIDTH = maxWidth
                const MAX_HEIGHT = maxHeight
                let width = img.width
                let height = img.height

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = MAX_WIDTH
                canvas.height = MAX_HEIGHT
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, MAX_WIDTH, MAX_HEIGHT)
                resolve(canvas.toDataURL())
            }
        })
    }

    // Start Tour
    getHelp = () => {
        this.setState({
            isTourOpen: true
        })
    }

    // Close Model Popup
    closeModel = () => {
        this.setState({ isModelPopUp: false })
    }

    // Close Model Cloth Popup
    closeClothModel = () => {
        this.setState({ isClothPopup: false })
    }

    // Share Particuler Capture Image To FaceBook
    fbPic = (id) => {
        try {
            if (document.getElementById(id).getAttribute('generatedurl') == null) {
                let imguri = document.getElementById(id);

                let uri = imguri.src;
                let blob = this.dataURItoBlob(uri);

                let resultFile = new File([blob], "melzolivetryon.png");
                this.uploadFiles([resultFile], 'widgetimage').then(urls => {

                    const imageUrl = urls[0].replace('https://pptvr-images.s3.ap-south-1.amazonaws.com/', baseUrls.images)
                    imguri.setAttribute('generatedUrl', imageUrl);
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${imageUrl}`, "_blank");
                })
            } else {
                let generatedUrl = document.getElementById(id).getAttribute('generatedurl');
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${generatedUrl}`, "_blank");
            }
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "fbPic", err, "")
        }
    }

    // Share Capture Capture Image On Whatsapp
    wpPic = (id) => {
        try {
            if (document.getElementById(id).getAttribute('generatedurl') == null) {
                let imguri = document.getElementById(id);

                let uri = imguri.src;
                let blob = this.dataURItoBlob(uri);

                let resultFile = new File([blob], "melzolivetryon.png");
                this.uploadFiles([resultFile], 'widgetimage').then(urls => {

                    const imageUrl = urls[0].replace('https://pptvr-images.s3.ap-south-1.amazonaws.com/', baseUrls.images);
                    imguri.setAttribute('generatedUrl', imageUrl);
                    window.open(`https://api.whatsapp.com/send/?phone=&text=${imageUrl}&source=&data=`, "_blank");
                })
            } else {
                let generatedUrl = document.getElementById(id).getAttribute('generatedurl');
                window.open(`https://api.whatsapp.com/send/?phone=&text=${generatedUrl}&source=&data=`, "_blank");
            }
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "wpPic", err, "")
        }
    }

    // Selected File send to Image Click
    getoutput = (input) => {
        try {
            if (input.target.files && input.target.files[0]) {
                this.setState({ isClothPopup: false });
                this.imageClick(URL.createObjectURL(input.target.files[0]), '0');
            }
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "getoutput", err, "")
        }
    }

    //Password Protection Match and close passwordProtected popup
    submitPassword = () => {
        try {
            const decrypted = CryptoJS.AES.decrypt(this.state.passwordProtected.toString().replace(/[_]/g, '/'), "u7x!A%D*G-KaNdRgUkXp2s5v8y/B?E(H");
            let decryptedPwd = decrypted.toString(CryptoJS.enc.Utf8);

            if (this.state.password == decryptedPwd) {
                this.setState({ passwordProtected: "" })
            } else {
                alert('Please Enter Correct Password');
            }

        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "submitPassword", err, "")
        }
    }

    // change stater
    onChangeState = (e) => {
        try {
            this.setState({ [e.target.name]: e.target.value });
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "onChangeState", err, "")
        }
    }

    closeTour = () => {
        try {
            this.setState({ isTourOpen: !this.state.isTourOpen })
            localStorage.setItem('faceHint', "done");
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "closeTour", err, "")
        }
    }

    submitDetails = () => {
        try {
            let nameInq = this.state.nameInq;
            let email = this.state.emailInq;
            let contactno = this.state.contactnoInq;
            let companyname = this.state.companynameInq;
            let city = this.state.cityInq;

            if (!nameInq || !email || !companyname || !contactno || !city) {
                alert('Please Enter All the Fields');
            } else {
                // call mail api with both email address
                var obj = {
                    yourname: nameInq,
                    usermail: email,
                    adminmail: this.state.enquiryMail,
                    projectname: projectName,
                    telephone: contactno,
                    company: companyname,
                    city: city
                };

                axios.post("https://us-central1-sendtoemail-3610d.cloudfunctions.net/viewerInquirySubmission",
                    obj, {
                    headers: {},
                }
                ).then((res) => {
                    this.setState({ enquiryMail: "" });
                }).catch(err => {
                    alert(err.message);
                })
            }

        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "submitDetails", err, "")
        }
    }

    closePopup = () => {
        try {
            this.setState({ isCameraNotFound: false }, () => {
                document.getElementById('loder').style.display = "none";
                document.getElementById('cameraLoading').style.display = "none";
            })
        } catch (err) {
            errorLog(this.state.uid, this.state.finalProjectId, this.state.projectName, "closePopup", err, "")
        }
    }

    imageChangeWhenClick = (id) => {
        let Imageurl = ""
        if (id == "necklacetabImg") {
            Imageurl = necklacePng1;
        } else {
            if (document.getElementById('necklacetabImg'))
                document.getElementById('necklacetabImg').src = necklacePng;
        }
        if (id == "earingtabimg") {
            Imageurl = earingPng1;
        } else {
            if (document.getElementById('earingtabimg'))
                document.getElementById('earingtabimg').src = earingPng;
        }
        if (id == "nosetabimg") {
            Imageurl = noseringPng1;
        } else {
            if (document.getElementById('nosetabimg'))
                document.getElementById('nosetabimg').src = noseringPng;
        }
        if (id == "eyetabimg") {
            Imageurl = eyewearPng1;
        } else {
            if (document.getElementById('eyetabimg'))
                document.getElementById('eyetabimg').src = eyewearPng;
        }
        if (Imageurl != "")
            document.getElementById(id).src = Imageurl;
    }

    render() {
        return (
            <React.Fragment>
                {/* <!------------------ Loader ------------------> */}
                <Tour
                    id="tour1"
                    steps={this.state.steps1}
                    isOpen={this.state.isTourOpen}
                    onRequestClose={this.closeTour}
                    getCurrentStep={curr => {
                        if (curr + 1 == this.state.steps1.length && this.state.steps1.length == 3) {
                            localStorage.setItem('faceHint', "done")
                        }
                    }}
                />
                <div id="loder">
                    <div className="main1">
                        <div className="dank-ass-loader">
                            <div className="row1">
                                <div className="arrow up outer outer-18"></div>
                                <div className="arrow down outer outer-17"></div>
                                <div className="arrow up outer outer-16"></div>
                                <div className="arrow down outer outer-15"></div>
                                <div className="arrow up outer outer-14"></div>
                            </div>
                            <div className="row1">
                                <div className="arrow up outer outer-1"></div>
                                <div className="arrow down outer outer-2"></div>
                                <div className="arrow up inner inner-6"></div>
                                <div className="arrow down inner inner-5"></div>
                                <div className="arrow up inner inner-4"></div>
                                <div className="arrow down outer outer-13"></div>
                                <div className="arrow up outer outer-12"></div>
                            </div>
                            <div className="row1">
                                <div className="arrow down outer outer-3"></div>
                                <div className="arrow up outer outer-4"></div>
                                <div className="arrow down inner inner-1"></div>
                                <div className="arrow up inner inner-2"></div>
                                <div className="arrow down inner inner-3"></div>
                                <div className="arrow up outer outer-11"></div>
                                <div className="arrow down outer outer-10"></div>
                            </div>
                            <div className="row1">
                                <div className="arrow down outer outer-5"></div>
                                <div className="arrow up outer outer-6"></div>
                                <div className="arrow down outer outer-7"></div>
                                <div className="arrow up outer outer-8"></div>
                                <div className="arrow down outer outer-9"></div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <!-- error popup design Start --> */}
                {this.state.isCameraNotFound &&
                    <div id="camera-popup" style={{ zIndex: '150001', position: 'fixed' }}>
                        <div className="overlay">
                            <ul className="popupboxdelete toptobottom">
                                <li className="marginbottomheader">
                                    <div className="flex">
                                        <h4 className="popuptitle">Can't access camera</h4>
                                        <span className="closepopup" onClick={() => this.closePopup()}>&times;</span>
                                    </div>
                                </li>
                                <li>
                                    <p className="embedlinktext">
                                        We can’t find your Camera<br />Check to be sure if it’s connected or working properly. Make sure your device allows permission to access the camera.
                                    </p>
                                </li>
                                <li className="margintopbottom">
                                    <div className="flexbtn">
                                        <button className="submit-btnform Btn" onClick={() => this.closePopup()}>
                                            <span className="btntext">Ok</span></button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                {/* <!-- error popup design End --> */}

                {/* <img id="image" src={img} style={{ display: 'none' }}></img> */}
                <div className="centerflex">
                    <div id="photoUpload" className="photoUpload"></div>
                    <canvas id="flipedEarring" style={{ display: "none" }}></canvas>
                    <canvas id="modelTryOnCanvas" style={{ display: "none" }}></canvas>
                    <div style={{ display: "none" }} id="controlPanel"></div>
                    <div id="_canvas_container" className="wow zoomIn _canvas_container">
                        <canvas style={{ transform: 'scaleX(-1)' }} id="output" className="_imageData output_canvas" width="640px" height="480px"></canvas>
                        <canvas style={{ transform: 'scaleX(-1)' }} id="out_imageGlass" className="_imageData absolutePosition out_imageGlass" width="640px" height="480px"></canvas>
                        <video id="video"
                            className="_imageData absolutePosition out_imageGlass"
                            playsInline
                            style={{
                                transform: 'scaleX(-1)',
                                visibility: 'hidden',
                                width: 'auto',
                                height: 'auto'
                            }}
                            width="640px" height="480px"
                        >
                        </video>
                    </div>
                    <div className="overlayShade wow zoomIn" style={{ display: 'none' }} id="overlayShade">
                        <img id="nofaceFound" className="widthnofacefound" src={newFaceWebPng} crossOrigin="anonymous" style={{ display: 'flex' }} />
                    </div>
                    <div className="overlayShade wow zoomIn zindexIncress" style={{ display: 'none' }} id="cameraLoading">
                        <div className="centerCameraLoading">
                            <p className="embedlinktext positionOfEmbedLink">
                                Setting up your Trial Booth.
                            </p>
                            <div className="loader123">
                                <div className="loader-bar"></div>
                            </div>
                        </div>
                    </div>
                </div>


                <canvas id="flipedEarring" style={{ display: 'none' }}></canvas>
                <div className="container-fluid" style={{ position: 'absolute', height: '100vh' }}>
                    <div className="row">
                        <div className="imagePanel positionimagepanel imagepanelactive" id="ImagePanel">
                            <div className="flexcolumndiv">
                                <label className="titlelabel" id="projectName"></label>
                            </div>

                            <div className="tabs" id="tabs">
                                <input type="radio" id="tab1" name="tab-control" defaultChecked />
                                <input type="radio" id="tab2" name="tab-control" />
                                <input type="radio" id="tab3" name="tab-control" />
                                <input type="radio" id="tab4" name="tab-control" />
                                <ul id="category">
                                    <li id="lblNecklace" title="Necklace">
                                        <label id="lblNecklacelbl" onClick={() => this.imageChangeWhenClick('necklacetabImg')} htmlFor="tab1" role="button">
                                            <img src={necklacePng} style={{ width: '30px' }} id="necklacetabImg" />
                                            <br />
                                            <span>Necklace</span>
                                        </label>
                                    </li>
                                    <li id="lblEaring" title="Earing">
                                        <label id="lblEaringlbl" onClick={() => this.imageChangeWhenClick('earingtabimg')} htmlFor="tab2" role="button">
                                            <img src={earingPng} style={{ width: '30px' }} id="earingtabimg" />
                                            <br />
                                            <span>Earing</span></label>
                                    </li>
                                    <li id="lblNoseRing" title="Nose Ring">
                                        <label id="lblNoseRinglbl" onClick={() => this.imageChangeWhenClick('nosetabimg')} htmlFor="tab3" role="button">
                                            <img src={noseringPng} style={{ width: '30px' }} id="nosetabimg" />

                                            <br /><span>Nose Ring</span>
                                        </label>
                                    </li>
                                    <li id="lblEyeWear" title="Eye-wear">
                                        <label id="lblEyeWearlbl" onClick={() => this.imageChangeWhenClick('eyetabimg')} htmlFor="tab4" role="button">
                                            <img src={eyewearPng} style={{ width: '30px' }} id="eyetabimg" />

                                            <br /><span>Eye-wear</span>
                                        </label>
                                    </li>
                                </ul>

                                <div className="slider">
                                    <div className="indicator"></div>
                                </div>
                                <div id="imageList" className="content">
                                    <section id="NecklaceImages">
                                        <div className="flexcolumndiv heightflexcolumndiv">
                                            <label id="lblNecklace1" className="titlecolor" style={{ opacity: '0' }}>
                                                Necklace:
                                            </label>
                                            <div className="heightneck" id="neck">
                                                <div className="flexdivnecklace marignbottomdiv" id="NecklaceImage"></div>
                                            </div>
                                        </div>
                                    </section>
                                    <section id="EaringImages">
                                        <div className="flexcolumndiv heightflexcolumndiv">
                                            <label id="lblEaring1" className="titlecolor" style={{ opacity: '0' }}>
                                                Earing:
                                            </label>
                                            <div className="heightneck" id="Earing">
                                                <div className="flexdivnecklace marignbottomdiv" id="EaringImage">
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section id="NoseRingImages">
                                        <div className="flexcolumndiv heightflexcolumndiv">
                                            <label id="lblNoseRing1" className="titlecolor" style={{ opacity: '0' }}>
                                                Nose Ring:
                                            </label>
                                            <div className="heightneck" id="nose">
                                                <div className="flexdivnecklace" id="NoseringImage"></div>
                                            </div>
                                        </div>
                                    </section>
                                    <section id="EyeWearImages">
                                        <div className="flexcolumndiv heightflexcolumndiv">
                                            <label id="lblEyeWear1" className="titlecolor" style={{ opacity: '0' }}>
                                                Eye-Wear:
                                            </label>
                                            <div className="heightneck" id="eyeWear">
                                                <div className="flexdivnecklace" id="EyewearImage"></div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-------------------------------- tab section end----------------------------------- -->
          <!-------------------------------- tab section start selfie gallery----------------------------------- --> */}

                    <div className="container-fluid" style={{ position: 'absolute', overflow: 'hidden' }}>
                        <div className="row">
                            <div className="imagePanel positionimagepanel" id="ImagePanelselfie">
                                <div className="flexcolumndiv">
                                    <label className="titlelabel">Selfie Gallery</label>
                                </div>
                                <div className="downloadposition">
                                    <button onClick={() => this.downloadAllPic()} className="downloadbtn">
                                        <img src={multipledownloadPng} style={{ width: '30px' }}
                                            id="downloadimg" />Download all
                                    </button>
                                    <button onClick={() => this.shareAllPic()} className="downloadbtn">
                                        <img src={whatsappBlack1Png} style={{ width: '30px' }}
                                            id="shareimg" />Share on
                                        Whatsapp
                                    </button>

                                </div>
                                <div className="flexcolumndiv flexdivnecklace">
                                    <div id="Selfie__Container" className="heightneck overflowcolumn flexmob"></div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- ----------------------logo section start------------------ --> */}
                        <div className="logo-clientlogo wow fadeInRight">
                            <img id="clientLogo" src={transparentPng} crossOrigin="anonymous" className="clientlogowidth" height="50" width="50" />
                        </div>

                        {/* <!-- ----------------------logo section end------------------ --> */}

                        {/* <!-- menu for tryon --> */}
                        <div className="mobdisplay">
                            <div className="menuoverlay menuactive" id="Menu_layer"></div>
                        </div>

                        {/* <video id="_webcam" style="display: none" playsinline className="videosection input_video"></video> */}
                        <audio controls id="audio-sound" src={demoAlertAud} autoPlay muted style={{ display: 'none' }}>
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>

                        <img id="NecklaceToTry" src={transparentPng} crossOrigin="anonymous" style={{ display: "none" }} />
                        <img id="EyewearToTry" src={transparentPng} crossOrigin="anonymous" style={{ display: "none" }} />
                        <img id="EaringToTry" onLoad={() => this.flipEarringImage()} src={transparentPng} crossOrigin="anonymous" style={{ display: "none" }} />
                        <img id="EaringFlipedToTry" src={transparentPng} crossOrigin="anonymous" style={{ display: "none" }} />
                        <img id="NoseringToTry" src={transparentPng} crossOrigin="anonymous" style={{ display: "none" }} />
                        <img id="TransparatImg" src={transparentPng} crossOrigin="anonymous" style={{ display: "none" }} />
                        <img id="faceData" src={this.state.imageUrl} crossOrigin="anonymous" style={{ display: "none" }} onLoad={() => this.handleOnLoad(this)} />

                        <img id="nofaceFoundmob" className="" height="100%" src="./image/newFaceMob.png" crossOrigin="anonymous" style={{ display: "none" }} />
                        {/* <!------------------- bottom menu section start--------------------------- --> */}
                        {/* <div className="captured_img_container fadeInLeft wow">
              <div id="captured__container"></div>
            </div> */}
                    </div>
                    <div className="sharedownload_btn_container">
                        <div className="" id="sharedownload_buttons" style={{ display: "none" }}>
                            <div id="sharedownload_container">
                                <img id="downloadpipc" style={{ padding: '5px' }} src={downloadPng} className="iconwidth mobdisplay" onClick={() => this.downloadURI()} />
                                <img id="whatsapppic" style={{ padding: '5px' }} src={whatsappPng} className="iconwidth mobdisplay" onClick={() => this.sharePic()} />
                            </div>
                        </div>
                    </div>
                    <div id="finalMenu" className="homebtnposition fadeInLeft wow">
                        <div id="toolTip1" className="tooltip1" style={{ display: "none" }}>
                            <span className="tooltiptext ishidden" id="menutext" style={{ top: '-150%' }}>Back to <br></br>Catalogue</span>
                            <span className="tooltiptext" id="selfietext">Selfie Gallery</span>

                            <div className="iconbg top" style={{ marginRight: '10px' }}>
                                <img id="menu" src={menuPng} className="iconwidth mobdisplay ishidden" onClick={() => this.Openmenu()} />
                                <div id='selfie-gallery' onClick={() => this.selfiegallery()}>
                                    <img id="selfie-gallery" src={selfie2Png} className="iconwidth mobdisplay" />
                                    <span id="countSelfie" className="bellnumbers"></span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="bottomiconposition fadeInRight wow">
                        <div id="btn-switch-camera" className="tooltip1" style={{ display: "flex" }} onClick={() => this.switchCamera()}>
                            <span className="tooltiptext">Switch Camera</span>
                            <div className="iconbg top" style={{ marginRight: '10px' }}>
                                <img src={cameraSwitchPng} className="iconwidth" />
                            </div>
                        </div>
                        <div id="btn-live-tryon" className="tooltip1" style={{ display: 'none' }} onClick={() => this.camerastart()}>
                            <span className="tooltiptext">Live Tryon</span>
                            <div className="iconbg top" style={{ marginRight: "10px" }}>
                                <img src={livetryonPng} className="iconwidth" />
                            </div>
                        </div>
                        <div id="uploadImage" className="tooltip1" onClick={() => this.uploadimage()}>
                            <span className="tooltiptext uploadtooltip">Upload your photo</span>
                            <div className="iconbg top" style={{ marginRight: "10px" }}>
                                <img src={uploadPhotoPng} className="iconwidth mobdisplay" />
                                <input type="file" id="file" style={{ display: 'none' }}
                                    onChange={(event) => { this.getoutput(event); }}
                                    name="image" accept="image/gif,image/jpeg,image/jpg,image/png" multiple="" data-original-title="upload photos" />
                            </div>
                        </div>
                        {this.state.isVideo === false && this.state.imageIndex !== '0' && false &&
                            <div id="clothChange" className="tooltip1" onClick={() => this.changeCloth()}>
                                <span className="tooltiptext">Model Cloth Change</span>
                                <div className="iconbg top" style={{ marginRight: "10px" }}>
                                    <img src={clothPng} className="iconwidth" />
                                </div>
                            </div>
                        }
                        <div id="tryItonModel" className="tooltip1" onClick={() => this.tryItonModel()}>
                            <span className="tooltiptext">Try it on Model</span>
                            <div className="iconbg top" style={{ marginRight: "10px" }}>
                                <img src={tryitonmodelPng} className="iconwidth" />
                            </div>
                        </div>
                        {this.state.isVideo &&
                            <div id="tackPicture" className="tooltip1" onClick={() => this.captureScreen()}>
                                <span className="tooltiptext">Take a Picture</span>
                                <div className="iconbg top" style={{ marginRight: "10px" }}>
                                    <img id="take-pic" src={takepicturePng} className="iconwidth mobdisplay" />
                                </div>
                            </div>
                        }
                        <div id="howtouse" className="tooltip1" onClick={() => this.getHelp()}>
                            <span className="tooltiptext">How to use</span>
                            <div className="iconbg top">
                                <img src={howtousePng} className="iconwidth" />
                            </div>
                        </div>

                    </div>

                    {/* <!------------------- bottom menu section end--------------------------- -->
            <!------------------- try it on model popup section start--------------------------- --> */}
                    {this.state.isModelPopUp &&
                        <div id="modelTryon" className="overlaytrymodel" onClick={() => this.closeModel()}>
                            <div id="closemodel" className="closemodel" onClick={() => this.closeModel()}>
                                <div className="close">X</div>
                            </div>
                            <div className="popuptrymodel popupwidth">
                                <div>
                                    <div style={{ padding: '10px' }}>
                                        <div className="imgcard">
                                            <img src={model1Png} onClick={() => this.imageClick(model1fullPng, '1')} />
                                            <img src={model2Png} onClick={() => this.imageClick(model2fullPng, '2')} />
                                            <img src={model3Png} onClick={() => this.imageClick(model3fullPng, '3')} />
                                            <img src={model4Png} onClick={() => this.imageClick(model4fullPng, '4')} />
                                            <img src={model5Png} onClick={() => this.imageClick(model5fullPng, '5')} />
                                            <img src={model6Png} onClick={() => this.imageClick(model6fullPng, '6')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.isClothPopup && this.state.imageIndex != '0' &&
                        <div id="modelTryon" className="overlaytrymodel" onClick={() => this.closeClothModel()}>
                            <div id="closemodel" className="closemodel" onClick={() => this.closeClothModel()}>
                                <div className="close">X</div>
                            </div>
                            <div className="popuptrymodel popupwidth">
                                <div>
                                    <div style={{ padding: '10px' }}>
                                        <div className="imgcard">
                                            <img src={`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth1small.png`} onClick={() => this.imageClick(`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth1.png`, this.state.imageIndex)} />
                                            <img src={`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth2small.png`} onClick={() => this.imageClick(`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth2.png`, this.state.imageIndex)} />
                                            <img src={`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth3small.png`} onClick={() => this.imageClick(`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth3.png`, this.state.imageIndex)} />
                                            <img src={`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth4small.png`} onClick={() => this.imageClick(`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth4.png`, this.state.imageIndex)} />
                                            <img src={`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth5small.png`} onClick={() => this.imageClick(`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model${this.state.imageIndex}cloth5.png`, this.state.imageIndex)} />
                                            <img src={`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model4cloth6small.png`} onClick={() => this.imageClick(`https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/melzo-noor/modal-tryon/model4cloth6.png`, this.state.imageIndex)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/* <!-- --------------NOTIFICATION POPUP------------------------- --> */}
                    <div className="notificationposition" id="notification-box" style={{ display: 'none' }}>
                        <div className="rectangle">
                            <div className="notification-text">
                                <i className="material-icons">info</i>
                                <span>&nbsp;&nbsp;Image Captured Successfully </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- --------------Password Protection-------------------------  --> */}
                {this.state.passwordProtected != "" &&
                    <div id="passwordProtection" className="wrapper1">
                        <div className="container">
                            <div className="popupform">
                                <h1 className="featurestitle ">
                                    Viewer Secure Password
                                </h1>
                                <div className="row" style={{ alignItems: 'center', marginBottom: '20px' }}>
                                    <div className="col-12 col-md-4 col-lg-4">
                                        <span className="form-field">Password</span>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-8">
                                        <input type="password" id="passwordValue" name="password" value={this.state.password} onChange={(e) => this.onChangeState(e)} className="form-input"></input>
                                    </div>
                                </div>
                                <button type="submit" className="submit-btnform" id="login-button" onClick={() => this.submitPassword()}>
                                    <span className="btntext">Login</span></button>
                            </div>
                        </div>
                    </div>
                }
                {/* <!-- --------------Password Protection End-------------------------  --> */}

                {/* <!-- --------------Inquiry Form-------------- --> */}
                {this.state.enquiryMail != "" &&
                    <div id="inquiryForm" className="wrapper1">
                        <div className="container">
                            <div className="popupform">

                                <h1 className="featurestitle ">Enquiry Form</h1>
                                <div className="row" style={{ alignItems: 'center', marginBottom: '20px' }}>
                                    <div className="col-12 col-md-4 col-lg-4 textstart">
                                        <span className="form-field">Name</span>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-8">
                                        <input type="text" name="nameInq" value={this.state.nameInq} onChange={(e) => this.onChangeState(e)} className="form-input"></input>
                                    </div>
                                </div>

                                <div className="row" style={{ alignItems: 'center', marginBottom: '20px' }}>
                                    <div className="col-12 col-md-4 col-lg-4 textstart">
                                        <span className="form-field">Email Address</span>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-8">
                                        <input type="email" name="emailInq" value={this.state.emailInq} onChange={(e) => this.onChangeState(e)} className="form-input"></input>
                                    </div>
                                </div>
                                <div className="row" style={{ alignItems: 'center', marginBottom: '20px' }}>
                                    <div className="col-12 col-md-4 col-lg-4 textstart">
                                        <span className="form-field">Contact No.</span>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-8">
                                        <input type="number" name="contactnoInq" value={this.state.contactnoInq} onChange={(e) => this.onChangeState(e)} className="form-input"></input>
                                    </div>
                                </div>
                                <div className="row" style={{ alignItems: 'center', marginBottom: '20px' }}>
                                    <div className="col-12 col-md-4 col-lg-4 textstart">
                                        <span className="form-field">Company Name</span>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-8">
                                        <input type="text" name="companynameInq" value={this.state.companynameInq} onChange={(e) => this.onChangeState(e)} className="form-input"></input>
                                    </div>
                                </div>
                                <div className="row" style={{ alignItems: 'center', marginBottom: '20px' }}>
                                    <div className="col-12 col-md-4 col-lg-4 textstart">
                                        <span className="form-field">City</span>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-8">
                                        <input type="text" name="cityInq" value={this.state.cityInq} onChange={(e) => this.onChangeState(e)} className="form-input"></input>
                                    </div>
                                </div>
                                <button type="submit" className="submit-btnform" id="login-button" onClick={() => this.submitDetails()}>
                                    <span className="btntext">Submit Details</span></button>
                            </div>
                        </div>
                    </div>
                }
                {/* <!-- --------------Inquiry Form End-------------- --> */}
            </React.Fragment>
        )
    }
}

export default FaceTryOn;