import React, { Component } from 'react'
import cancelimg from '../images/cancel.png'
import yesimg from '../images/right.png'
import btnimg from '../images/button.png';

export default class DeletePopoup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.projectData,
        }
    }

    handleCancle = () => {
        this.props.closePopup();
    }

    handleYes = () => {
        this.props.projectDelete(this.props.deleteProjectId)
        this.props.closePopup();
    }

    render() {
        return (
            <div>
                <div id="delete-popup">
                    <div className="overlay" onClick={this.handleCancle} />
                    <ul className="popupboxdelete toptobottom">
                        <li>
                            <div className="flex">
                                <h4 className="popuptitle">
                                    Delete Project
                            </h4>

                                <span className="closepopup" onClick={this.handleCancle} >&times;</span>
                            </div>
                        </li>
                        <li>
                            <p className="deletetext">
                                Are you sure you want to delete this Project?
                            </p>
                        </li>
                        <li >
                            <div className="flexbtn">
                                <button className="Btn marginrightcancel" onClick={this.handleCancle}>
                                    <img src={btnimg} className="btnicon" alt="" />
                                                        No
                                </button>
                                <button className="Btn"  onClick={this.handleYes} id="yes">
                                    <img src={btnimg} className="btnicon" alt="" />
                                            Yes
                                </button>
                              

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
