import { getUserToken } from "./aws-services";
import mrc from "./mrc";
import config from "./config";


/**
 * this function is use for create gltf model
 * 
 * @param {id of scene} sceneid 
 * @param {model details} gltfModelObj 
 * @param {id of project} projectid 
 */
export function createGltfModel(sceneid, gltfModelObj,projectid) {
    return new Promise((resolve, reject) => {
        getUserToken().then((token) => {
            createGltfModelNetwork({
                "projectid": projectid,
                "token": token,
                "sceneid": sceneid
            }, gltfModelObj).then(
                (responseNewGltfModel) => {
                    // if (createGltfModelLocal(sceneid, responseNewGltfModel.data)) {
                    //     resolve(responseNewGltfModel);
                    // } else {
                    //     reject();
                    // }
                    // deleteGltfModelLocal(sceneid, loadGltfModel._id);
                    resolve('Done');
                },
                (error) => {
                    reject(error);
                    //     message = "Error: " + error.status + " " + error.statusText;
                }
            );
        }).catch((err) => { 
            reject(err);
        });
    });
};

/**
 * this function is use for create gltf model network
 * 
 * @param {project details} obj 
 * @param {gltf model details} newGltfObj 
 */
function createGltfModelNetwork(obj, newGltfObj) {
    // console.warn('object is ',obj)
    // console.warn('new gltf obj is :: ',newGltfObj)
    return new Promise((resolve, reject) => {
        // console.warn('createfltf model network obj',obj);
        // console.warn('createfltf model network newgltfobj',newGltfObj);
        mrc
            .post(config.serverUrl + `projects/${obj.projectid}/scenes/${obj.sceneid}/gltfmodels/`, newGltfObj, {
                headers: {
                    'x-access-token': obj.token
                }
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}