/* eslint-disable */
import React, { Component } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Warning from '../Warning/Warning';
import ProjectDisplay from "../project-list-page/project-display";
import Viewer from "../project-list-page/3d-viewer";
import FaceTryOn from "../project-list-page/faceTryon";
import AppCanvas from '../glass/js/components/AppCanvas'
export default class RouterContainier extends Component {

  // redirectUrl = () => {
  //   window.location.href = "https://noor.melzo.com";
  // }

  render() {
    return (
      <div>
        <Router>
          <Switch location={this.props.location}>
            <Route exact path="/" component={this.redirectUrl} />
            <Route exact path="/face" component={FaceTryOn} />
            <Route exact path="/360/:projectid" component={ProjectDisplay} />
            <Route exact path="/3d/:projectid" component={Viewer} />
            <Route exact path="/glass" component={AppCanvas} />
            <Route exact path="/warning" component={Warning} />
          </Switch>
        </Router>
      </div>
    );
  }
}
