/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import "./project-list-page.css";
import CreateButtonPopup from "../popups/create-button-popup";
import ProjectListItem from "./project-list-item";
import Header from "../header/header";
import { createProjectLocal, guid } from "../functions/aws-services";
import "../popups/create-button-popup.css";
import { handleProjects } from "../functions/get-projects-service";
import firebaseApp from "../functions/firebase";
import { deleteProjectLocal } from "../functions/delete-project";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getFileExtension,
  uploadFiles,
  uploadGltfModel,
  uploadImageFile,
  uploadImageFileInFirebase,
} from "../functions/upload-services";
import { createGltfModel } from "../functions/model-upload-service";
import { error } from "spritespin/release/src/utils";
import Footer from "../header/footer";
import DeletePopoup from "../popups/delete-popup";
import EmbedPopup from "../popups/embed-popup";
import SignOutPopup from "../popups/sign-out-popup";
import createimg from "../images/create.png";
import btnimg from "../images/button.png";
import Logopartimg from "../images/Logopart.png";
import viewimg from "../images/degree.png";
import modelimg from "../images/3dmodel.png";
import Necklacemg from "../images/Necklace.png";
import Earingmg from "../images/Earing.png";
import Noseringmg from "../images/Nosering.png";
import homeimg from '../images/home.png';
import tryonmg from "../images/tryon.png";

import Loader from "../loader";
import Projecttype from "./project-type";
export var userid = "";

export default class Model3D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myUid: "",
      select360project: false,
      select3Dproject: false,
      selecttryonproject: false,
      userEmail: "",
      allProjects: [],
      imageProjects: [],
      necklaceProjects: [],
      earringProjects: [],
      noseringProjects: [],
      modelProjects: [],
      allProjectsFetchStatus: false,
      isPopupOpen: false,
      projectName: "",
      projectDescription: "",
      selectedProductImage: "",
      isViewer: false,
      imageFetchStatus: false,
      modelFetchStatus: false,
      necklaceFetchStatus: false,
      earringFetchStatus: false,
      noseringFetchStatus: false,
      isEditor: false,
      typevalue: "360-view",
      deletePopup: false,
      embedProjectpopup: false,
      projectToDelete: "",
      projectToEmbed: "",
      titleForDelete: "Are you sure to Delete?",
      isLogout: false,
      embedProjectType: "",
      isLoaderActive: true,
    };
  }

  componentDidMount() {
    this.getUserUid();
    this.setState({ allProjects: this.state.modelProjects });

    handleProjects(
      "sparkle-3d",
      this.state.modelProjects,
      this.state.modelFetchStatus,
      this.handleProjectListValuesforModel,
    );
    document.addEventListener("scroll", (e) => this.handleScroll(e));
  }



  /**
   * this function is use for handle project list values for model
   *
   * @param {array of model} modelArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforModel = (
    modelArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ modelProjects: modelArray });
    this.setState({ modelFetchStatus: fetch });

    // this.handleSelector();
    this.setState({ isLoaderActive: false })
    console.warn("model projects", modelArray.length);
    // console.warn('badhi vastu', modelArray)
  };


  /**
   * this function is use for handle project list of Necklace
   *
   * @param {image of Necklace} AllProjectArray
   * @param {fetch status} fetch
   */
  handleProjectListValuesforAllProjects = (
    AllProjectArray,
    totalLikes,
    totalViews,
    fetch,
  ) => {
    // this.setState({ imageProjects: imageArray })
    this.setState({ AllProjectArray: AllProjectArray });
    this.setState({ allProjectsFetchStatus: fetch });
    // this.handleSelector();
    this.setState({ isLoaderActive: false })
  };

  /**
   * this function is use for get user id
   */
  getUserUid = () => {
    return new Promise((resolve, reject) => {
      firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
          // user is signed in
          resolve(user.uid);
          localStorage.setItem("uid", user.uid);
          localStorage.setItem("emailId", user.email);
          // console.warn('user uid',user.uid)
          this.setState({ userEmail: user.email });
          this.setState({ myUid: user.uid });
        } else {
          window.location.href = "/";
          // anonymous user, proceed with token as null
          resolve(null);
        }
      });
    });
  };

  // refreshPage = () => {
  //     // this.componentDidMount();
  // }
  handleHome = () => {
    window.location.href = "/home";
  }
  handleProjectName = (e) => {
    e.preventDefault();
    this.setState({ projectName: e.target.value });
  };

  handleProjectDescription = (e) => {
    e.preventDefault();
    this.setState({ projectDescription: e.target.value });
  };

  handleCreateButton = () => {
    this.setState({ isPopupOpen: true });
    // console.warn('create button clicked ::')

    // document.getElementById('create-button-popup').style.display='block';
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  // handleCancle=()=>{
  //     document.getElementById('create-button-popup').style.display = 'none';
  // }

  /**
    * this function is use for create a new project
    *
    * @param {project name} name
    * @param {project description} description
    * @param {project type} typevalue
    * @param {model} model
    */
  CreateProject = (name, description, typevalue, selectedFile = null) => {
    switch (typevalue) {
      case "360-view":
        // code block
        createProjectLocal(name, description, typevalue).then((res) => {
          // const notificationMsg = "Project created successfully"
          // localStorage.setItem('notificationMsg', notificationMsg);
          let sceneData = res.data.scenes[0].sceneid;
          let uid = res.data.uid;
          userid = uid;
          // console.warn('response value is', res.data);
          localStorage.setItem("sceneId", sceneData);

          this.setState({ isEditor: true });
          this.props.history.push(`/upload/${res.data._id}`);
          // window.location.href = `/upload/${res.data._id}`
        });
        break;
      case "3D-model":
        // code block
        createProjectLocal(name, description, typevalue).then((res) => {
          let uid = res.data.uid;
          let sceneData = res.data.scenes[0].sceneid;
          let projectId = res.data._id;
          console.warn("Project created successfully", projectId);
          uploadGltfModel(
            selectedFile,
            "widgetgltfmodel",
            uid,
            sceneData,
            projectId,
          ).then((res) => {
            this.closePopup();
          });
        });
        break;
      case "necklace":

      case "earring":

      case "nosering":
        // code block
        createProjectLocal(name, description, typevalue).then((res) => {
          // const notificationMsg = "Project created successfully"
          // localStorage.setItem('notificationMsg', notificationMsg);
          let sceneData = res.data.scenes[0].sceneid;
          let projectId = res.data._id;
          let uid = res.data.uid;
          userid = uid;
          // console.warn('response value is', res.data);
          localStorage.setItem("sceneId", sceneData);

          uploadImageFileInFirebase(
            selectedFile,
            "widgetimage",
            uid,
            sceneData,
            projectId,
          );
          // .then(
          //     () => {
          //         alert("Uploaded Successfully")
          //         window.location.href = '/home'
          //     }
          // );
          // window.location.href = `/upload/${res.data._id}`
        });
        break;
      default:
        // code block
        createProjectLocal(name, description, typevalue).then((res) => {
          // const notificationMsg = "Project created successfully"
          // localStorage.setItem('notificationMsg', notificationMsg);
          let sceneData = res.data.scenes[0].sceneid;
          let uid = res.data.uid;
          userid = uid;
          // console.warn('response value is', res.data);
          localStorage.setItem("sceneId", sceneData);

          this.setState({ isEditor: true });
          this.props.history.push(`/upload/${res.data._id}`);
          // window.location.href = `/upload/${res.data._id}`
        });
        break;
    }
  };


  /**
   * this function is use for show project in 360
   *
   * @param {project id} projectId
   * @param {project type} type
   */
  ShowProduct = (projectId, type) => {
    // e.preventDefault()
    // this.setState({ selectedprojectId: projectId });
    localStorage.setItem("selectedprojectId", projectId);
    console.warn("selected product is ::");
    if (type === "model") {
      window.location.href = `/3d/${projectId}`;
      // window.location.href = '/3d/'+ projectId
    }

    // this.setState({ isViewer: true })
    // setTimeout(() => {
    // console.warn('isviewer value', this.state.isViewer)
    // }, 1);
  };

  /**
   * this function is use for delete project
   *
   * @param {project id of selected project for delete} projectIdToDelete
   */
  deleteProject = (projectIdToDelete) => {
    deleteProjectLocal({
      projectid: projectIdToDelete,
    }).then((response) => {
      this.setState({ allProjects: [] });
      this.setState({ imageProjects: [] });
      this.setState({ modelProjects: [] });
      this.setState({ earringProjects: [] });
      this.setState({ noseringProjects: [] });
      this.setState({ necklaceProjects: [] });
      setTimeout(() => {
        this.getUserUid();
        this.setState({ allProjects: this.state.imageProjects });

        handleProjects(
          "sparkle-3d",
          this.state.modelProjects,
          this.state.modelFetchStatus,
          this.handleProjectListValuesforModel,
        );

      }, 1);

      // this.componentDidMount();
    });
  };

  closeDeletePopup = () => {
    this.setState({ deletePopup: false });
  };
  closeEmbedPopup = () => {
    this.setState({ embedProjectpopup: false });
  };

  embedProject = (projectIdToEmbed) => {
    console.warn("embed ::", projectIdToEmbed);
  };

  /**
   * this function is use for delete or embade project
   *
   * @param {id of project} projectid
   * @param {work of function} actionToPerform
   * @param {project type} type
   */
  actionButton = (projectid, actionToPerform, type = "360") => {
    // deleteProjectLocal({
    //     projectid: projectIdToDelete
    // }).then((response) => {
    //     this.setState({ allProjects: [] });
    //     this.setState({ imageProjects: [] });
    //     this.setState({ modelProjects: [] });
    //     this.componentDidMount();
    // })
    if (actionToPerform === "delete") {
      this.setState({ projectToDelete: projectid });
      this.setState({ deletePopup: true });
    } else if (actionToPerform === "embed") {
      this.setState({ projectToEmbed: projectid });
      this.setState({ embedProjectType: type });
      this.setState({ embedProjectpopup: true });
    }
    // console.warn('project id is ::', projectid)
  };

  logOut = (msg) => {
    if (msg === "logOut") {
      // this.setState({isLogout:true})
      document.getElementById("popup-signout").style.display = "block";
    }
  };

  /**
   * this function is use for handle logout
   */
  handlelogout = () => {
    let logoutQuery = new Promise((resolve, reject) => {
      firebaseApp
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
          resolve("signout successfully");
        })
        .catch(function (error) {
          // An error happened.
          reject(error);
        });
    });
    logoutQuery
      .then((result) => {
        console.warn("logout successful", result);
        window.localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {
        console.warn(error);
      });
    this.setState({ isLogout: false });
  };

  closeLogout = () => {
    this.setState({ isLogout: false });
  };

  /**
   * this function is use for handle scroll
   *
   * @param {event object} e
   */
  handleScroll = (e) => {
    e.preventDefault();
    // console.warn('handle scroll', e.target.scrollingElement)
    const bottomScroll =
      document.documentElement.scrollTop + window.innerHeight ===
      document.documentElement.scrollHeight;
    if (bottomScroll) {
      // console.warn('Bottom reached');
      if (this.state.typevalue === "3D-model" && !this.state.modelFetchStatus) {
        handleProjects(
          "sparkle-3d",
          this.state.modelProjects,
          this.state.modelFetchStatus,
          this.handleProjectListValuesforModel,
        );
      }
    }
  };

  /**
   * this function is use for handle dropdown for select project type "3D-model" or "360-view"
   */
  handleSelector = () => {
    if (document.getElementById("selector").value === "3D-model") {
      document.getElementById("secondSelector").style.display = "none";
      // console.warn('Should display model', document.getElementById('selector').value);
      this.setState({ allProjects: [] });
      this.setState({ typevalue: document.getElementById("selector").value });

      let temp = this.state.modelProjects;
      setTimeout(() => {
        this.setState({ allProjects: temp });
      }, 1);
    } else if (document.getElementById("selector").value === "360-view") {
      document.getElementById("secondSelector").style.display = "none";
      this.setState({ typevalue: document.getElementById("selector").value });
      this.setState({ allProjects: [] });
      // console.warn('Should display images', this.state.imageProjects);
      let myTemp = this.state.imageProjects;
      setTimeout(() => {
        this.setState({ allProjects: myTemp });
      }, 1);
    } else if (document.getElementById("selector").value === "tryon") {
      document.getElementById("secondSelector").style.display = "block";

      if (document.getElementById("secondSelector").value === "necklace") {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.necklaceProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 1);
      } else if (
        document.getElementById("secondSelector").value === "earring"
      ) {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.earringProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 1);
      } else if (
        document.getElementById("secondSelector").value === "nosering"
      ) {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.noseringProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 10);

        // setTimeout(() => {
        //     console.warn('nosering project is ::', this.state.allProjects)
        // }, 1000);
      }
    }
  };

  handleSelectorForNewDropDown = () => {
    if (document.getElementById("selector").value === "tryon") {
      document.getElementById("secondSelector").style.display = "block";

      if (document.getElementById("secondSelector").value === "necklace") {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.necklaceProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 1);
      } else if (
        document.getElementById("secondSelector").value === "earring"
      ) {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.earringProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 1);
      } else if (
        document.getElementById("secondSelector").value === "nosering"
      ) {
        this.setState({
          typevalue: document.getElementById("secondSelector").value,
        });
        this.setState({ allProjects: [] });
        // console.warn('Should display images', this.state.imageProjects);
        let myTemp = this.state.noseringProjects;
        setTimeout(() => {
          this.setState({ allProjects: myTemp });
        }, 10);

        // setTimeout(() => {
        //     console.warn('nosering project is ::', this.state.allProjects)
        // }, 1000);
      }
    }
  };

  // checkconsole = () => {
  //     console.warn('image fetchstatus::', this.state.imageFetchStatus);
  //     console.warn('model fetchstatus::', this.state.modelFetchStatus)
  // }

  render() {
    return (
      <div>


        {this.state.isLoaderActive && <Loader />}
        {!this.state.isLoaderActive && (
          <div>
            <div className="project-list">
              <Header
                userEmail={this.state.userEmail}
                showHomeButton={this.state.isViewer || this.state.isEditor}
                confirmLogout={this.logOut}
              />
              <div className="container-fluid">
                {/* {this.state.isViewer === true &&
                                <ProjectDisplay selectedProductImagevalue={this.state.selectedProductImage} />
                            } */}
                {this.state.isViewer === false && (
                  <>
                    <div className="margintopfive">
                      {/* <div className="row margintopbottom">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h1 className="heading animationtext" id="ProjectList">My Projects</h1>

                                                </div>

                                            </div> */}
                      <div className="row margintopbottom columnrev">
                        <div
                          className="col-12 col-md-6 col-lg-6"
                          style={{ display: "none" }}
                        >
                          <div
                            className="flexdropdown "
                            style={{ display: "none" }}
                          >
                            <label
                              htmlFor="menu"
                              className="Choosetext animationdropdown"
                              style={{ display: "none" }}
                            >
                              Choose a project type:
                            </label>

                            <div
                              className="box animationdropdown"
                              style={{ display: "none" }}
                            >
                              <select
                                name="menu"
                                id="selector"
                                onChange={this.handleSelector}
                              >
                                <option id="dropDown-360" value="360-view">
                                  360 view
                                </option>
                                <option id="dropDown-3d" value="3D-model">
                                  3D Model
                                </option>
                                <option id="dropDown-tryon" value="tryon">
                                  Tryon
                                </option>
                              </select>
                            </div>
                            <div
                              className="box animationdropdown"
                              style={{ display: "none" }}
                            >
                              <select
                                name="menu"
                                style={{ display: "none" }}
                                id="secondSelector"
                                onChange={this.handleSelectorForNewDropDown}
                              >
                                <option id="dropDown-necklace" value="necklace">
                                  Necklace
                                </option>
                                <option id="dropDown-earring" value="earring">
                                  Earing
                                </option>
                                <option id="dropDown-nosering" value="nosering">
                                  Nose ring
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                          <div className="Btnposition">
                            <img src={homeimg} onClick={this.handleHome} className="iconwidth" data-tooltip="tooltip" data-placement="right" title="Go to Homescreen " />

                            <div className="vl"></div>
                            <button
                              className="Btn "
                              onClick={this.handleCreateButton}
                              data-tooltip="tooltip"
                              data-placement="right"
                              title="Create a New Project "
                            >
                              <img src={btnimg} className="btnicon" alt="" />
                            Create
                            </button>
                          </div>

                        </div>
                      </div>

                      {/* <button onClick={this.handleFile} id="selectBtn" style={{display:'none'}}>select file</button> */}
                      {/* <input type="file" style={{ display: 'none' }} onChange={this.handleSelect} accept=".glb" name="myfile" /> */}
                      <div className="row margintopbottom flexcolumn">
                        <div
                          className="col-12 col-md-7 col-lg-7 heightprojectlist"
                          id="productgrid"
                        >
                          <div className="gridtryon">
                            {this.state.modelProjects.map(
                              (projectItem, index) => {
                                return (
                                  <div key={index}>
                                    <ProjectListItem
                                      projectData={projectItem}
                                      ShowProduct={this.ShowProduct}
                                      // deleteProject={this.deleteProject}
                                      actionButton={this.actionButton}
                                    // showDeletePopup={this.showDeletePopup}
                                    />
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                        {/* shadow section */}
                        <div className="col-md-1 col-lg-1 hideshadow1">
                          <img
                            src={Logopartimg}
                            className="fullwidth hideshadow1 heightshadow rotateshadow"
                          />
                        </div>
                        {/* select project type section */}
                        <div
                          className="col-12 col-md-4 col-lg-4"

                        >
                          <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                              <p className="Choosetext" style={{ textAlign: 'center' }}>
                                Choose a Project Type:
                              </p>
                            </div>
                          </div>

                          <div className="row" style={{ justifyContent: 'center' }}>
                            <div className="col-4 col-md-4 col-lg-4">
                              <div
                                onClick={() => {
                                  this.props.changeStateFor360View('360-view');
                                }}
                              >
                                <div className="boxproject">
                                  <img src={viewimg} data-tooltip="tooltip"
                                    data-placement="right"
                                    title="Create and View 360° Projects" className="widthicon"></img>
                                  <p className="project-type">360 View</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-md-4 col-lg-4">
                              <div
                                onClick={() => {
                                  this.props.changeStateForTryon('tryon');
                                }}
                              >
                                <div className="boxproject">
                                  <img src={tryonmg} data-tooltip="tooltip"
                                    data-placement="right"
                                    title="Try on your Projects in AR" className="widthicon"></img>
                                  <p className="project-type">Tryon</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {this.state.isPopupOpen === true && (
              <CreateButtonPopup
                CreateProject={this.CreateProject}
                projectCreateFrom='3dModel'
                close={this.closePopup}
              />
            )}
            {this.state.deletePopup === true && (
              <DeletePopoup
                deleteProjectId={this.state.projectToDelete}
                projectDelete={this.deleteProject}
                closePopup={this.closeDeletePopup}
              />
            )}
            {this.state.embedProjectpopup === true && (
              <EmbedPopup
                embedProjectId={this.state.projectToEmbed}
                type={this.state.embedProjectType}
                projectEmbed={this.embedProject}
                closePopup={this.closeEmbedPopup}
              />
            )}
            <Footer />
          </div>
        )}
      </div>
    );
  }
}
