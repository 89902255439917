/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import * as THREE from "three";
// import GUI from 'react-dat-gui';
// import GUI from "react-gui-controller";
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import btnimg from '../images/button.png';
import homeimg from '../images/home.png';

import './project-list-page.css'
import mrc from '../functions/mrc';
import config from '../functions/config';
import QRpopup from '../popups/QR-popup';
import Loader from '../loader';
import Header from '../header/header';
import qrimg from '../images/qr_code.png';
import DeletProjectErrorPopup from '../popups/delete-project-error';
import Footer from '../header/footer';
import Headertryon from '../header/headertryon';
import { errorLog, userLog } from '../functions/useLogs';
import CreditWarning from "../CreditWarning"
import Verification from './verification';
import InquiryForm from './InquiryForm';
import axios from 'axios';
let Container, stats;
let params = {
    projection: 'normal',
    autoRotate: true,
    reflectivity: 1.0,
    background: false,
    exposure: 1.0,
    gemColor: 'White'
};

let camera, scene, renderer, objects = [];
let gemBackMaterial, gemFrontMaterial;
let hdrCubeRenderTarget;
let textureEquirec, textureCube;
let LOADER;


let uid = ""
let finalProjectId = ""
let projectName = ""

export default class Viewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modelPath: '',
            isProjectAvailable: true,
            isShowStore: false,

            projectName: "",

            isEnquriForm: false,
            adminEmail: "",

            isPasswordProtected: false,
            password: "",
        }
        this.animate = this.animate.bind(this);
    }
    
    postSession = (uid) =>  {
        axios.post("https://ptpvr.com/usage/session", {
                uid: uid,
                product: "noor",
            })
            .then((response) => {
            }).catch(err => {
                errorLog("PostSession", err, "");
            })
    }

    calculateUsage = (usage) => {
        axios.post("https://ptpvr.com/usage", {
                uid: uid,
                usage: usage,
                product:'noor'
            })
            .then((result) => {
            }).catch(err => {
                errorLog("calculateUsage", err, "");
            });
    }

    componentDidMount() {

        let currentProjectId = window.location.href;
        currentProjectId = currentProjectId.substring(currentProjectId.lastIndexOf('/') + 1)
        let userid = localStorage.getItem('uid')
        this.getProjectDetails(currentProjectId);
    }

    /**
     * this function is use for get project details
     * 
     * @param {project id} projectId 
     */
    getProjectDetails = (projectId) => {

        return mrc
            .get('projects/' + projectId, {
                headers: {
                    'x-access-token': 'null'
                },
                baseURL: "https://ptpvr.com"
            })
            .then(res => {
                let project = res.data
                this.setState({ modelPath: project.scenes[0].gltfmodels[0].src })
                LOADER = document.getElementById('js-loader');

                uid = project.uid;
                finalProjectId = project._id;
                projectName = project.projectname;
                axios.post('https://ptpvr.com/paidview/isviewvalid',{
                    product:'Noor',
                    uid:uid
                }).then(res=>{
                    if(res.data.isAllowed){
                        axios
                        .put(`https://ptpvr.com/projects/updateview/${project._id}`, {
                            views: project.views + 1,
                        })
                        .then((response) => {
                            if(res.data.isPayAsYouGo){
                            this.postSession(uid);

                            }
                        })
                        let cal_usage = 0;

                        project.scenes[0].gltfmodels.forEach((gltfmodel) => {
                         
                            cal_usage += gltfmodel.size;
                    
                          });
                          let final_usage = cal_usage / 1024
                          this.calculateUsage(final_usage);
                    
                        userLog('3D View', uid, finalProjectId, projectName);

                        mrc.get(`https://ptpvr.com/projects/${project.uid}/Noor`)
                        .then((res) => {
                            if (res.data.isEnabled === true) {
                                if (res.data.config && res.data.config.indexOf('Ecommerce') != -1) {
                                    this.setState({
                                        isShowStore: true
                                    })
                                    document.getElementById('visitOurStoreLink').href = 'https://shop.melzo.com/product/' + project.uid
                                }
                            }
                        }).catch((err) => {
                            errorLog(uid, finalProjectId, projectName, "/Noor Service Call ", err, "")
                        });

                    if (project.isPasswordProtected) {
                        this.setState({ isPasswordProtected: true, password: project.password })
                    }
                    if (project.isEnquiryEnabled) {
                        this.setState({ isEnquriForm: true, adminEmail: project.enquiryMail })
                    }

                    this.init();
                    this.animate();
                    }else{
                window.location.href = "/warning"

                    }
                   
                })

                // mrc.put(`https://ptpvr.com/projects/updateview/${project._id}`, {
                //     views: (project.views + 1)
                // }).then(response => {
                // }).catch(err => {
                //     errorLog(uid, finalProjectId, projectName, "update View service ", err, "")
                // });

            })
            .catch(err => {
                errorLog(uid, finalProjectId, projectName, "Get Project ", err, "")
                this.setState({ isProjectAvailable: false });
            });
    }

    /**
     * this function is use for load a model and show in 360
     */
    init = () => {
        let directIntensity = 0.8 * Math.PI; // TODO(#116)
        let directColor = 0xFFFFFF;
        Container = document.createElement('div');
        document.body.appendChild(Container);

        camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 1000);
        // camera.position.set(0.0, - 10, 20 * 3.5);

        const light1 = new THREE.AmbientLight(0xFFFFFF, 0.3);
        light1.name = 'ambient_light';
        camera.add(light1);

        const light2 = new THREE.DirectionalLight(directColor, directIntensity);
        light2.position.set(0.5, 0, 0.866); // ~60º
        light2.name = 'main_light';
        camera.add(light2);

        scene = new THREE.Scene();
        scene.background = new THREE.Color(0xFFFFFF);

        renderer = new THREE.WebGLRenderer({ antialias: true });
        let newColor = new THREE.Color(0xFFFFFF);
        gemBackMaterial = new THREE.MeshPhysicalMaterial({
            map: null,
            color: newColor,
            metalness: 1,
            roughness: 0,
            opacity: 0.8,
            side: THREE.BackSide,
            transparent: true,
            envMapIntensity: 5,
            premultipliedAlpha: true
            // TODO: Add custom blend mode that modulates background color by this materials color.
        });

        gemFrontMaterial = new THREE.MeshPhysicalMaterial({
            map: null,
            color: newColor,
            metalness: 0,
            roughness: 0,
            opacity: 0.25,
            side: THREE.FrontSide,
            transparent: true,
            envMapIntensity: 10,
            premultipliedAlpha: true
        });

        let manager = new THREE.LoadingManager();
        manager.onProgress = function (item, loaded, total) {


        };

        let path = "/envmap/";
        let urls = [
            path + "posx.jpg", path + "negx.jpg",
            path + "posy.jpg", path + "negy.jpg",
            path + "posz.jpg", path + "negz.jpg"
        ];

        textureCube = new THREE.CubeTextureLoader().load(urls);
        textureCube.encoding = THREE.sRGBEncoding;
        scene.environment = textureCube;

        let textureLoader = new THREE.TextureLoader();

        textureEquirec = textureLoader.load('https://threejs.org/examples/textures/2294472375_24a3b8ef46_o.jpg');
        textureEquirec.mapping = THREE.EquirectangularReflectionMapping;
        textureEquirec.encoding = THREE.sRGBEncoding;

        // glb model 
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/');
        loader.setDRACOLoader(dracoLoader);
        loader.load('https://pptvr-3d-models.s3.ap-south-1.amazonaws.com/' + this.state.modelPath, (gltf) => {
            let theModel;
            theModel = gltf.scene;
            var box = new THREE.Box3().setFromObject(theModel);

            if (box.getSize().x < 10) {
                gltf.scene.scale.set(1200, 1200, 1200);
            }

            gltf.scene.position.set(0, -5, 0);
            theModel.traverse((child) => {
                if (!child.isMesh) return;
                if (child.name.includes("Diamond")) {
                    child.material = gemBackMaterial
                }
                if (child.name.includes("Gem")) {
                    child.material.map = null
                    child.material.metalness = 1
                    child.material.roughness = 0
                    child.material.opacity = 1
                    child.material.side = THREE.BackSide
                    child.material.envMapIntensity = 2
                }
                else {
                    child.material.envMap = textureCube;
                    child.material.metalness = 1.0;
                    child.material.roughness = 0.0;
                }
            });
            scene.add(theModel);
            camera.position.set(0, box.getSize().y + 10, box.getSize().z + 50);
            LOADER.remove();
        });

        new RGBELoader()
            .setDataType(THREE.UnsignedByteType)
            .setPath('https://threejs.org/examples/textures/equirectangular/')
            .load('royal_esplanade_1k.hdr', function (hdrEquirect) {

                hdrCubeRenderTarget = pmremGenerator.fromEquirectangular(hdrEquirect);
                pmremGenerator.dispose();

                gemFrontMaterial.envMap = gemBackMaterial.envMap = hdrCubeRenderTarget.texture;
                gemFrontMaterial.needsUpdate = gemBackMaterial.needsUpdate = true;

                hdrEquirect.dispose();

            });

        let pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.shadowMap.enabled = true;
        renderer.physicallyCorrectLights = true;
        Container.appendChild(renderer.domElement);

        renderer.outputEncoding = THREE.sRGBEncoding;

        let controls = new OrbitControls(camera, renderer.domElement);
        controls.minDistance = 20;
        controls.maxDistance = 200;

        window.addEventListener('resize', this.onWindowResize(), false);

        let gui = new GUI();

        gui.add(params, 'autoRotate');
        gui.add(params, 'gemColor', ['Blue', 'Green', 'Red', 'White', 'Black']);
        gui.open();

    }

    onWindowResize = () => {
        let width = window.innerWidth;
        let height = window.innerHeight;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        renderer.setSize(width, height);
    }
    animate() {
        requestAnimationFrame(this.animate);
        this.onMaterialColor();
    }

    onMaterialColor = () => {
        if (gemBackMaterial !== undefined && gemFrontMaterial !== undefined) {

            gemFrontMaterial.reflectivity = gemBackMaterial.reflectivity = params.reflectivity;

            let newColor = gemBackMaterial.color;
            switch (params.gemColor) {

                case 'Blue': newColor = new THREE.Color(0x000088); break;
                case 'Red': newColor = new THREE.Color(0x880000); break;
                case 'Green': newColor = new THREE.Color(0x008800); break;
                case 'White': newColor = new THREE.Color(0x888888); break;
                case 'Black': newColor = new THREE.Color(0x0f0f0f); break;

            }
            gemBackMaterial.color = gemFrontMaterial.color = newColor;
        }

        renderer.toneMappingExposure = params.exposure;

        camera.lookAt(scene.position);

        if (params.autoRotate) {
            scene.rotation.y += 0.001;
        }

        renderer.render(scene, camera);
    }

    //Close Password protection PopUP
    closePassword = () => {
        this.setState({ isPasswordProtected: false })
    }

    //Close Enquire protection PopUP
    closeEnquire = () => {
        this.setState({ isEnquriForm: false })
    }

    render() {
        return (
            <div style={{ overflow: 'hidden' }}>
                {this.state.isPasswordProtected && <Verification password={this.state.password} successViewerPwdEntry={() => this.closePassword()} />}
                {this.state.isEnquriForm && <InquiryForm enquiryMail={this.state.adminEmail} projectname={this.state.projectName} successEnquiryFormEntry={() => this.closeEnquire()} />}
                {this.state.overlay && <CreditWarning />}
                <Headertryon isShowStore={this.state.isShowStore} />
                <div id="container"></div>

                <button className="Btn compdisplay qrbottonposition" onClick={this.openQr}  >
                    <img src={btnimg} className="btnicon" alt="" />
                    QR Code
                </button>
                <QRpopup />
                {/* <!-----------------LOADER-----------------> */}
                <div id="js-loader">
                    <Loader />
                </div>
                {this.state.isProjectAvailable === false &&
                    <DeletProjectErrorPopup cancel={this.handleCancel} />}
                {/* <img src={homeimg} onClick={this.handleHome} className="iconwidth homebtnposition" data-tooltip="tooltip" data-placement="right" title="Go to Homescreen " /> */}

                <Footer />
            </div>

        )
    }
    handleHome = () => {
        window.location.href = "/home";
    }
    handleCancel = () => {
        this.setState({ isProjectAvailable: true })
    }

    openQr = () => {
        document.getElementById('scannerPopupOverlay').style.display = 'block';
        document.getElementById('scannerPopup').style.display = 'block';
        document.getElementById('scannerPopup').classList.add("toptobottom");
        document.getElementById('scannerPopup').classList.remove("bottomtotop");

    }
}



